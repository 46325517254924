import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import DeleteConfirmationPopup from "../../common/delete-confirmation";
import PopupMessage from "../../common/popup-message";
import { baseUrl, headers } from "../../authentication/authorization";
import Processing from "../../common/processing";

function Category() {
  const [loading, setLoading] = useState(true);
  const [addCategory, setAddCategory] = useState({
    name: "",
    displayOrder: "",
  });
  const [displayOrderErr, setDisplayOrderErr] = useState("");
  const isAvailable = useRef();
  const [activeId, setActiveId] = useState("");
  const [error, setError] = useState("");

  const [Producttype, setProductType] = useState([]);
  const [saveLoader, setSaveLoader] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [slug, setSlug] = useState("");
  const [alert, setAlert] = useState("");

  async function getProductType() {
    await axios
      .get(`${baseUrl}/category`)
      .then((res) => {
        if (Array.isArray(res.data)) {
          setProductType(res.data);
        }
      })
      .catch(() => null);
  }

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    async function getProductType() {
      await fetch(`${baseUrl}/category`, {
        method: "GET",
        signal,
      })
        .then((response) => response.json())
        .then((data) => {
          if (Array.isArray(data)) {
            setProductType(data);
          }

          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
    getProductType();
    return () => controller.abort();
  }, []);

  const submitForm = (e) => {
    const { name, displayOrder } = addCategory;
    e.preventDefault();

    let goAhead = true;
    if (name.trim() === "") {
      setError("You must enter category name");
      goAhead = false;
    }
    if (String(displayOrder).trim() === "") {
      setDisplayOrderErr("You must enter display order");
      goAhead = false;
    }
    // eslint-disable-next-line
    if (+displayOrder !== +displayOrder) {
      setDisplayOrderErr("Display Order must be valid integer");
      goAhead = false;
    }
    if (!saveLoader && goAhead) {
      setSaveLoader(true);
      const formData = new FormData();
      formData.append("name", name);
      formData.append("is_displayed", isAvailable.current.checked);
      formData.append("order", displayOrder);
      if (activeId) {
        fetch(`${baseUrl}/category-edit/${activeId}/`, {
          method: "PUT",
          body: formData,
          headers: headers(),
        })
          .then((res) => {
            if (res.status === 401) {
              localStorage.clear();
              window.location = "/login";
            } else if (res.status < 300) {
              getProductType();
              setAddCategory({
                name: "",
                displayOrder: "",
              });
            }
            setSaveLoader(false);
            return res.json();
          })
          .then((dta) => {
            const { name } = dta;
            if (
              Array.isArray(name) &&
              name[0] === "category with this name already exists."
            ) {
              setSaveLoader(false);
              setAlert("Category already exists");
            }
          })
          .catch((error) => {
            setSaveLoader(false);
            setAlert("could not edit the category");
          });
        setActiveId("");
      } else {
        setSaveLoader(true);
        fetch(`${baseUrl}/category-add/`, {
          method: "POST",
          body: formData,
          headers: headers(),
        })
          .then((response) => response.json())
          .then((data) => {
            const { code, slug, detail, name } = data;
            if (
              code === "token_not_valid" ||
              detail === "Authentication credentials were not provided."
            ) {
              localStorage.clear();
              window.location = "/login";
            } else if (
              Array.isArray(name) &&
              name[0] === "category with this name already exists."
            ) {
              setSaveLoader(false);
              setAlert("Category already exists");
            } else if (slug) {
              setSaveLoader(false);
              getProductType();
              setAddCategory({
                name: "",
                displayOrder: "",
              });
            }
          })
          .catch(() => {
            setSaveLoader(false);
            setAlert("could not add the cateogory");
          });
      }
    }
  };

  const handleDeleteCategory = (action) => {
    if (action === "delete") {
      fetch(`${baseUrl}/category-edit/${slug}`, {
        method: "DELETE",
        headers: headers(),
      })
        .then((res) => {
          if (res.statusText === "OK") {
            getProductType();
          }
        })
        .catch(() => null);
    }

    setDeletePopup(false);
  };

  const handleEdit = (id, name, order, is_displayed) => {
    setAddCategory({
      name: name,
      displayOrder: order,
    });
    isAvailable.current.checked = is_displayed;
    setActiveId(id);
  };

  return (
    <>
      {deletePopup !== false && (
        <DeleteConfirmationPopup
          handleDelete={handleDeleteCategory}
          message="Are you sure that you want to delete this category ?"
        />
      )}
      {alert !== "" && (
        <PopupMessage message={alert} handleClose={() => setAlert("")} />
      )}
      <div className="flex p-8">
        <div className="w-full md:flex md:space-x-6 space-y-6 md:space-y-0">
          {/* right side  */}
          <div className=" w-full md:w-2/5 ">
            <div className="bg-white rounded-md shadow-md flex flex-col py-5 h-auto w-full">
              {/* header  */}
              <div className="px-10 border-b border-gray-300 pb-4 text-lg font-semibold uppercase  tracking-wide">
                ADD CATEGORY FORM
              </div>
              {/* form  */}
              <form className="flex flex-col px-10 py-4 space-y-4">
                <div className="flex flex-col space-y-1">
                  <label className="text-sm font-medium text-gray-700">
                    Category Name
                  </label>
                  <div>
                    <input
                      type="text"
                      className="border w-full px-5 border-gray-300 focus:border-indigo-500 py-1.5 text-gray-700 text-sm"
                      value={addCategory.name}
                      required
                      onChange={(e) => {
                        setError("");
                        setAddCategory({
                          ...addCategory,
                          name: e.target.value,
                        });
                      }}
                    />
                  </div>
                  {error && <div className="error">{error}</div>}
                </div>
                <div className="flex flex-col space-y-1">
                  <label className="text-sm font-medium text-gray-700">
                    Display Order{" "}
                    <span className="text-xs">(must be number)</span>
                  </label>
                  <div>
                    <input
                      type="text"
                      className="border w-full px-5 border-gray-300 focus:border-indigo-500 py-1.5 text-gray-700 text-sm"
                      value={addCategory.displayOrder}
                      required
                      onChange={(e) => {
                        setDisplayOrderErr("");
                        setAddCategory({
                          ...addCategory,
                          displayOrder: e.target.value,
                        });
                      }}
                    />
                  </div>
                  {displayOrderErr && (
                    <div className="error">{displayOrderErr}</div>
                  )}
                </div>
                {/* displayed  */}
                <div className="text-gray-700 space-x-1">
                  <input type="checkbox" ref={isAvailable} id="is_available" />{" "}
                  <label htmlFor="is_available">Display</label>
                </div>
                {/* submit  */}
                <div className="">
                  <button
                    className="flex items-center  space-x-2 bg-indigo-500 text-white px-5 py-2 rounded-md shadow-md transition-all duration-300 ease-in-out hover:bg-indigo-600"
                    type="submit"
                    onClick={submitForm}
                  >
                    <div>{saveLoader ? "Saving" : "Save"}</div>
                    {saveLoader && <Processing />}
                  </button>
                </div>
              </form>
            </div>
          </div>
          {/* left side  */}
          <div className="flex-1">
            <div className="flex flex-col">
              <div className="bg-white rounded-md shadow-md flex flex-col py-5 h-auto w-full">
                {/* header  */}
                <div className="px-10 border-b border-gray-300 pb-4 text-lg font-semibold uppercase  tracking-wide">
                  All CATEGORY
                </div>
                {/* category list  */}
                {loading && (
                  <div className="w-full flex justify-center">
                    <div className="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                )}
                {Producttype.length === 0 && !loading && (
                  <div className="w-full flex justify-center text-primary text-xl">
                    No Data
                  </div>
                )}
                {Producttype.length > 0 && (
                  <div className="grid grid-cols-1 sm:grid-cols-2  lg:grid-cols-2 xl:grid-cols-3 gap-x-2 gap-y-4 py-10">
                    {Producttype.map((product, index) => {
                      const { name, slug, order, is_displayed } = product;
                      return (
                        <div className="col-span-1 px-3" key={index}>
                          <div className="bg-gray-200 p-2 flex justify-between text-sm items-center">
                            <span>{name}</span>
                            <div className="flex items-center space-x-1">
                              <button
                                onClick={() =>
                                  handleEdit(slug, name, order, is_displayed)
                                }
                              >
                                <svg
                                  className="w-5 h-5 text-green-600"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                  ></path>
                                </svg>
                              </button>
                              <button
                                onClick={() => {
                                  setSlug(slug);
                                  setDeletePopup(true);
                                }}
                              >
                                <svg
                                  className="w-5 h-5 text-red-600"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Category;
