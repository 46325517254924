import { useEffect, useRef, useState } from "react";
import { baseUrl, headers } from "../../authentication/authorization";
import DeleteConfirmationPopup from "../../common/delete-confirmation";
import PopupMessage from "../../common/popup-message";
import Processing from "../../common/processing";

export default function Services() {
  const serviceImage = useRef();
  const formContainer = useRef();
  const [saveLoader, setSaveLoader] = useState(false);
  const [alert, setAlert] = useState("");
  const [id, setId] = useState("");
  const [deletePopup, setDeletePopup] = useState("");

  const [services, setServices] = useState([]);

  const [service, setService] = useState({
    id: "",
    title: "",
    description: "",
    image: "",
    errors: {
      title: "",
      description: "",
    },
  });

  const [serviceError, setServiceError] = useState("");
  const [serviceSuccess, setServiceSuccess] = useState("");

  const handleErrors = (property, value) => {
    let result;
    value = value === undefined ? service[property] : value;
    if (value.trim() === "") {
      errors[property] = `${property[0].toUpperCase()}${property.slice(
        1,
        property.length
      )} cannot be left empty`;
      result = false;
    } else {
      if (property === "title") {
        errors.title = "";
        result = true;
      } else if (property === "description") {
        errors.description = "";
        result = true;
      }
    }
    setService({
      ...service,
      errors,
    });
    return result;
  };

  const handleChange = ({ target: { value } }, property) => {
    handleErrors(property, value);
    setService({
      ...service,
      [property]: value,
    });
  };

  const getServices = async () => {
    await fetch(`${baseUrl}/site/services-list`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((dta) => {
        if (Array.isArray(dta)) {
          setServices(dta);
        }
      })
      .catch(() => null);
  };

  const handleServiceSubmit = (event) => {
    event.preventDefault();
    const { id, title, description, image } = service;
    setServiceSuccess("");
    if (!saveLoader) {
      const credentials = ["title", "description"];
      let goAhead;
      for (let i = 0; i < credentials.length; i++) {
        const result = handleErrors(credentials[i]);
        if (goAhead !== false) {
          goAhead = result;
        }
      }
      if (!image && !id) {
        setServiceError("Add Image");
        goAhead = false;
      }

      if (goAhead) {
        setSaveLoader(true);
        const formData = new FormData();
        formData.append("title", title);
        formData.append("description", description);
        formData.append("img", image);

        if (id === "") {
          fetch(`${baseUrl}/site/services-add/`, {
            method: "POST",
            body: formData,
            headers: headers(),
          })
            .then((response) => response.json())
            .then((dta) => {
              if (dta.id) {
                getServices();
                setAlert("Successfully added the service");
                setService({
                  id: "",
                  title: "",
                  image: "",
                  description: "",
                  errors: {
                    title: "",
                    description: "",
                  },
                });
                serviceImage.current.innerHTML = "Select a Image";
              } else {
                setAlert("Something went wrong, please try again");
              }
              setSaveLoader(false);
            })
            .catch(() => {
              setSaveLoader(false);
              setAlert("Something went wrong, please try again");
            });
        } else {
          fetch(`${baseUrl}/site/services-edit/${id}`, {
            method: "PATCH",
            body: formData,
            headers: headers(),
          })
            .then((response) => response.json())
            .then((dta) => {
              if (dta.id) {
                getServices();
                setAlert("Successfully edited the service");
                setService({
                  id: "",
                  title: "",
                  image: "",
                  description: "",
                  errors: {
                    title: "",
                    description: "",
                  },
                });
                serviceImage.current.innerHTML = "Select a Image";
              } else {
                setAlert("Something went wrong, please try again");
              }
              setSaveLoader(false);
            })
            .catch((error) => error);
        }
      }
    }
  };

  const handleServiceDelete = (action) => {
    if (action === "delete") {
      fetch(`${baseUrl}/site/services-edit/${id}`, {
        method: "DELETE",
        headers: headers(),
      })
        .then((response) => {
          if (response.ok) {
            setAlert("Successfully deleted the service");
            const services1 = [...services];
            services1.splice(deletePopup, 1);
            setServices(services1);
          } else {
            setAlert("Something went wrong, please try again");
          }
        })
        .catch(() => setAlert("Something went wrong, please try again"));
    }
    setDeletePopup("");
    setId("");
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const getServices = async () => {
      await fetch(`${baseUrl}/site/services-list`, {
        method: "GET",
        signal,
      })
        .then((response) => response.json())
        .then((dta) => {
          if (Array.isArray(dta)) {
            setServices(dta);
          }
        })
        .catch(() => null);
    };

    getServices();

    return () => controller.abort();
  }, []);

  const { title, description, errors } = service;
  const { title: titleErr, description: descriptionErr } = errors;

  return (
    <>
      {deletePopup !== "" && (
        <DeleteConfirmationPopup
          handleDelete={handleServiceDelete}
          message="Are you sure that you want to delete this service ?"
        />
      )}
      {alert && (
        <PopupMessage handleClose={() => setAlert("")} message={alert} />
      )}
      <div className="px-5 py-10 overflow-auto">
        <form
          ref={formContainer}
          className="max-w-xl flex flex-col space-y-4 mx-auto bg-white p-5 shadow rounded"
          onSubmit={handleServiceSubmit}
        >
          <div className="mx-auto text-xl font-semibold">Service Field</div>
          <div className="flex flex-col space-y-2">
            <label htmlFor="" className="text-gray-700 text-sm font-semibold">
              Title
            </label>
            <input
              type="text"
              value={title}
              className="border w-full p-3 focus:outline-none focus:border-indigo-500"
              onChange={(event) => {
                setServiceSuccess("");
                setServiceError("");
                handleChange(event, "title");
              }}
            />
            {titleErr && <div className="error">{titleErr}</div>}
          </div>
          <div className="flex flex-col space-y-2">
            <label htmlFor="" className="text-gray-700 text-sm font-semibold">
              Description
            </label>
            <textarea
              className="border w-full h-24 p-3 focus:outline-none focus:border-indigo-500"
              value={description}
              onChange={(event) => {
                setServiceSuccess("");
                setServiceError("");
                handleChange(event, "description");
              }}
            ></textarea>
            {descriptionErr && <div className="error">{descriptionErr}</div>}
          </div>
          <div className="flex flex-col space-y-2 relative ">
            <label className="text-gray-700 text-sm font-semibold">
              Service Image
            </label>

            <label
              htmlFor="input-file"
              className="bg-gray-50  p-2.5 w-full text-gray-500 text-sm overflow-hidden"
              ref={serviceImage}
            >
              Select Image
            </label>
            <input
              type="file"
              id="input-file"
              className="border w-full hidden"
              onChange={(event) => {
                const file = event.target.files[0];
                const fileName = file.name;
                const image = new Image();
                image.src = URL.createObjectURL(file);
                image.onload = function () {
                  let arr = fileName.split(".");
                  let extension = arr[arr.length - 1];
                  const extensions = ["png", "jpg", "jpeg", "webp"];
                  let bool = false;
                  for (let i = 0; i < extensions.length; i++) {
                    if (extensions[i] === extension.toLowerCase()) {
                      bool = true;
                      i = extensions.length;
                    }
                  }
                  if (bool) {
                    setService({
                      ...service,
                      image: file,
                    });
                    setServiceError("");
                  } else {
                    setService({
                      ...service,
                      image: "",
                    });
                    setServiceError("Invalid file");
                  }
                };

                image.onerror = function () {
                  setService({
                    ...service,
                    image: "",
                  });
                  setServiceError("Invalid file");
                };
                serviceImage.current.innerHTML = fileName;
              }}
            />
            <label
              htmlFor="input-file"
              className="bg-gray-200 py-2 px-4 absolute top-5 right-0 cursor-pointer"
            >
              Browse...
            </label>
            {serviceError && (
              <div className="text-red-500 text-sm">{serviceError}</div>
            )}
            {serviceSuccess && (
              <div className="text-green-500 text-sm">{serviceSuccess}</div>
            )}
          </div>
          {/* button  */}
          <div className="">
            <button className="bg-indigo-500 flex justify-center items-center space-x-2 px-5 py-2 w-full sm:w-auto rounded text-white hover:bg-indigo-600 focus:outline-none">
              <div>{saveLoader ? "Saving" : "Save"}</div>
              {saveLoader && <Processing />}
            </button>
          </div>
        </form>

        {/* Services list */}
        {services.length > 0 && (
          <div className="flex flex-col  mt-10 bg-white p-5">
            <div className=" text-2xl font-semibold pl-4">Review Services</div>
            <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6  p-4">
              {services.map((service, index) => {
                const { title, description, id } = service;
                return (
                  <div
                    className="col-span-1 bg-gray-100 shadow-md rounded-md p-5 space-y-2 relative group overflow-hidden cursor-pointer"
                    key={index}
                  >
                    <div className="font-semibold ">{title}</div>
                    <div className="text-sm text-gray-700">{description}</div>
                    <div className="absolute bg-black w-full h-full -top-2 right-0 opacity-0 group-hover:opacity-30 transition-all duration-300 ease-in-out"></div>
                    <div className="absolute top-0 right-0 space-x-4  w-full h-full flex justify-center items-center ">
                      <button
                        onClick={() => {
                          setService({
                            ...service,
                            errors: { title: "", description: "" },
                          });
                          formContainer.current.scrollIntoView();
                        }}
                        className="bg-green-500 text-white px-3 py-1 rounded-md shadow-md opacity-0 group-hover:opacity-95 transition-all duration-300 ease-in-out hover:bg-green-600 "
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => {
                          setDeletePopup(index);
                          setId(id);
                        }}
                        className="bg-red-600 text-white px-3 py-1 rounded-md shadow-md opacity-0 group-hover:opacity-95 transition-all duration-300 ease-in-out hover:bg-red-700 "
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
