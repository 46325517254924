import { useState, useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import App from "./App";
import DashboardManagement from "./components/dashboard/dashboard-management";
import NormalUser from "./components/normal-user-profile/normal-user";
import EmailVerify from "./components/authentication/email-verified";
import ResetPassword from "./components/authentication/reset-password";
import { baseUrl, headers } from "./components/authentication/authorization";

export default function UrlManagement() {
  const [loggedIn, setLoggedIn] = useState("");
  const [role, setRole] = useState("");
  const history = useHistory();

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    // handles the user according to the token
    function tokenManager() {
      const token = localStorage.getItem("access");
      let decoded;
      try {
        decoded = jwt_decode(token);
      } catch (e) {
        localStorage.clear();
      }
      let loggedin = false;
      if (decoded && decoded.exp) {
        fetch(`${baseUrl}/user/api/token/refresh/`, {
          method: "POST",
          headers: { ...headers(), "Content-Type": "application/json" },
          body: JSON.stringify({ refresh: localStorage.getItem("refresh") }),
          signal,
        })
          .then((response) => response.json())
          .then((dta) => {
            if (dta.access) {
              localStorage.setItem("access", dta.access);
            } else {
              localStorage.clear();
              window.location = "/login";
            }
          })
          .catch(() => null);
        loggedin = true;
      }
      setLoggedIn(loggedin);
      if (decoded && decoded.is_admin) {
        setRole("admin");
      }
    }
    tokenManager();

    history.listen(() => tokenManager());

    return () => controller.abort();
  }, [history, role]);

  let token1 = window.location.href.split(`/user/email-verify/?`);

  if (Array.isArray(token1) && token1.length === 2) {
    window.location = `/user/email-verify/${token1[1]}`;
    return;
  }

  if (loggedIn === "") {
    return <div>Loading...</div>;
  }

  return (
    <Switch>
      <Route
        path="/user/password-reset/:uid/:token"
        component={ResetPassword}
      />
      <Route path="/user/email-verify/:url" component={EmailVerify} />
      {loggedIn && role === "admin" && (
        <Route path="/profile" component={DashboardManagement} />
      )}
      {loggedIn && role !== "admin" && (
        <Route path="/profile" component={NormalUser} />
      )}
      <Route
        path="/"
        render={(props) => <App loggedIn={loggedIn} role={role} {...props} />}
      />
    </Switch>
  );
}
