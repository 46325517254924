import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import { handleLogout } from "../../authentication/authorization";
import logo from "../../../assets/images/dashboard/logo.jpeg";
import "../../../assets/css/dashboard.css";

function NavBar() {
  const [width, setWidth] = useState(window.innerWidth);
  const mobIpadSidebar = useRef();
  const coverAll = useRef();
  const asideLists = [
    {
      name: "My Orders",
      logo:
        "M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0",
      path: "/profile",
    },
    // {
    //   name: "Change Password",
    //   logo:
    //     "M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4",
    //   path: "/profile/change-password",
    // },
  ];
  const handleWidth = () => {
    const innerWidth = window.innerWidth;
    if (innerWidth > 1023 && width < 1024) {
      setWidth(innerWidth);
    } else if (innerWidth < 1024 && width > 1023) {
      setWidth(innerWidth);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", handleWidth);
    return () => {
      window.removeEventListener("resize", handleWidth);
    };
  });
  const toggleMobIpadSidebar = () => {
    const sidebar = mobIpadSidebar.current;
    const coverall = coverAll.current;
    if (sidebar.clientWidth > 0) {
      sidebar.style.width = "0";
      coverall.style.display = "none";
    } else {
      sidebar.style.width = "288px";
      coverall.style.display = "block";
    }
  };
  return (
    <div className=" bg-white z-10 w-full py-2  border-b transition-all duration-300 ease-in-out">
      {/* nav bar  */}
      <div className="w-full flex  items-center px-5">
        {/* search bar  */}
        <div className="flex-1 flex items-center justify-end h-10">
          <div
            className=" cursor-pointer text-gray-600 flex lg:hidden "
            onClick={toggleMobIpadSidebar}
          >
            <MenuIcon />
          </div>
        </div>
        <div className="lg:flex space-x-4 text-gray-600 items-center relative hidden">
          {/* user  */}
          <div className="user py-3 cursor-pointer">
            <svg
              className="w-6 h-6 "
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
          </div>
          <div className="absolute  top-6 -right-4 hide pt-5 z-20">
            <div
              onClick={handleLogout}
              className="py-2 bg-gray-100 px-3 transform transition-all duration-300 hover:-translate-y-1 hover:scale-110 cursor-pointer shadow hover:bg-gray-200"
            >
              Logout
            </div>
          </div>
        </div>
      </div>
      {/* aisde for mobile ipad  */}
      {width < 1024 && (
        <>
          <div
            className="h-screen w-screen fixed left-0 top-0 bg-black bg-opacity-50 hidden overflow-x-hidden"
            ref={coverAll}
            onClick={toggleMobIpadSidebar}
          ></div>
          <div
            className="bg-gray-50 fixed top-0 left-0 h-screen w-0 z-20 border overflow-auto
            transition-all duration-300"
            ref={mobIpadSidebar}
          >
            {/* header  */}
            <div className="flex flex-col py-4 space-y-2">
              {/* resturant logo  */}
              <Link to="/" className="w-20 h-20 mx-auto">
                <img
                  src={logo}
                  alt=""
                  className="w-full h-full rounded-full object-center object-cover"
                />
              </Link>
              {/* momo world  */}
              <div className="text-xl font-semibold text-center">
                The Momo World
              </div>
            </div>
            {/* content part  */}
            <div className="flex flex-col  mt-5" onClick={toggleMobIpadSidebar}>
              {asideLists.map((list, index) => {
                return (
                  <Link
                    to={list.path}
                    key={index}
                    className="text-gray-600 hover:bg-indigo-600 hover:text-white transition-all duration-300  flex pl-8 pr-2 items-center space-x-4 py-2"
                  >
                    <div>
                      <svg
                        className="w-5 h-5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d={list.logo}
                        />
                      </svg>
                    </div>
                    <span> {list.name}</span>
                  </Link>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default NavBar;
