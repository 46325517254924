import FrontPage from "../common/FrontPage";
import ContactComponent from "./ContactComponent";

export default function ContactUs() {
  return (
    <div>
      <FrontPage
        id="contact-us"
        header="Get in Touch"
        description="We will share daily updates about our restaurant to you"
      />
      <ContactComponent />
    </div>
  );
}
