import { useState } from "react";
import { Link } from "react-router-dom";
import { baseUrl } from "./authorization";
import Processing from "../common/processing";
import backgroundImage from "../../assets/images/authentication/login-background.jpg";
import logo from "../../assets/images/dashboard/logo.jpeg";

export default function Register() {
  const [saveLoader, setSaveLoader] = useState(false);
  const [data, setData] = useState({
    f_name: "",
    l_name: "",
    username: "",
    email: "",
    contact: "",
    password: "",
    confirmPassword: "",
    errors: {
      f_name: "",
      l_name: "",
      username: "",
      email: "",
      contact: "",
      password: "",
      confirmPassword: "",
    },
    confirmationEmail: "",
  });

  const handleErrors = (property, value) => {
    const { errors } = data;
    let result;
    value = value === undefined ? data[property] : value;
    let label;
    if (property === "contact") {
      label = "Phone number";
    } else {
      label = `${property[0].toUpperCase()}${property.slice(
        1,
        property.length
      )}`;
    }

    if (
      value.trim() === "" &&
      property !== "password" &&
      property !== "confirmPassword"
    ) {
      errors[property] = `${label} cannot be left empty`;
      result = false;
    } else {
      if (property === "email") {
        if (!value.match(/^\w+@\w+\.\w+(\.\w+)?$/gi)) {
          errors.email = "Invalid email";
          result = false;
        } else {
          errors.email = "";
          result = true;
        }
      } else if (property === "f_name") {
        if (value === "") {
          errors.f_name = "First name cannot be left empty";
          result = false;
        } else {
          errors.f_name = "";
          result = true;
        }
      } else if (property === "l_name") {
        if (value === "") {
          errors.l_name = "Last name cannot be left empty";
          result = false;
        } else {
          errors.l_name = "";
          result = true;
        }
      } else if (property === "username") {
        if (value === "") {
          errors.username = "Username cannot be left empty";
          result = false;
        } else {
          errors.username = "";
          result = true;
        }
      } else if (property === "password") {
        if (value.length < 8) {
          errors.password = "Password must be atleast 8 characters long";
          result = false;
        } else {
          errors.password = "";
          result = true;
        }
      } else if (property === "confirmPassword") {
        if (value !== data.password) {
          errors.confirmPassword = "Passwords do not match";
          result = false;
        } else {
          errors.confirmPassword = "";
          result = true;
        }
      } else if (property === "contact") {
        errors.contact = "";
        result = true;
      }
    }

    setData({
      ...data,
      errors,
    });
    return result;
  };

  const handleChange = ({ target: { value } }, property) => {
    handleErrors(property, value);
    setData({
      ...data,
      [property]: value,
      confirmationEmail: "",
    });
  };

  const handleRegisterSubmit = (event) => {
    event.preventDefault();
    if (!saveLoader) {
      const url = `${baseUrl}/user/register/`;
      const { f_name, l_name, username, email, password, contact, errors } =
        data;

      const credentials = [
        "f_name",
        "l_name",
        "username",
        "email",
        "contact",
        "password",
        "confirmPassword",
      ];
      let goAhead;
      for (let i = 0; i < credentials.length; i++) {
        const result = handleErrors(credentials[i]);
        if (goAhead !== false) {
          goAhead = result;
        }
      }

      if (goAhead) {
        setSaveLoader(true);
        setData({
          ...data,
          confirmationEmail: "",
        });
        const formData = new FormData();
        formData.append("f_name", f_name);
        formData.append("l_name", l_name);
        formData.append("username", username);
        formData.append("email", email);
        formData.append("contact", contact);
        formData.append("password", password);
        fetch(url, {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((dta) => {
            const { email, username, contact } = dta;
            if (
              Array.isArray(email) ||
              Array.isArray(username) ||
              Array.isArray(contact)
            ) {
              if (Array.isArray(email)) {
                errors.email = email[0];
              }
              if (Array.isArray(username)) {
                errors.username = username[0];
              }
              if (Array.isArray(contact)) {
                errors.contact = contact[0];
              }
              setData({
                ...data,
                errors,
                confirmationEmail: "",
              });
            } else {
              setData({
                ...data,
                confirmationEmail:
                  "Confirmation link has been sent to your email",
              });
            }
            setSaveLoader(false);
          })
          .catch(() => setSaveLoader(false));
      }
    }
  };

  const {
    f_name,
    l_name,
    username,
    email,
    contact,
    password,
    confirmPassword,
    errors,
    confirmationEmail,
  } = data;
  const {
    f_name: f_nameErr,
    l_name: l_nameErr,
    username: usernameErr,
    email: emailErr,
    contact: contactErr,
    password: passwordErr,
    confirmPassword: confirmPasswordErr,
  } = errors;

  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div className="w-full h-full grid place-items-center pt-20 min-h-screen">
        <form
          onSubmit={handleRegisterSubmit}
          className="bg-white px-10 py-5 mx-5 my-10 max-w-md w-full authentication-form"
          autoComplete="off"
        >
          <div className="flex flex-col pb-2 space-y-2">
            {/* resturant logo  */}
            <Link to="/" className="w-20 h-20 mx-auto">
              <img
                src={logo}
                alt=""
                className="w-full h-full rounded-full object-center object-cover"
              />
            </Link>
            {/* momo world  */}
            <div className="text-xl font-semibold text-center">
              Registration Form
            </div>
          </div>
          <div className="space-y-2">
            {/* First Name  */}
            <div className="space-y-1">
              <label htmlFor="username">First Name</label>
              <input
                type="text"
                id="f_name"
                value={f_name}
                onChange={(event) => handleChange(event, "f_name")}
              />
              {f_nameErr && <div className="error">{f_nameErr}</div>}
            </div>
            {/* Last Name  */}
            <div className="space-y-1">
              <label htmlFor="username">Last Name</label>
              <input
                type="text"
                id="l_name"
                value={l_name}
                onChange={(event) => handleChange(event, "l_name")}
              />
              {l_nameErr && <div className="error">{l_nameErr}</div>}
            </div>
            {/* username  */}
            <div className="space-y-1">
              <label htmlFor="username">Username</label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(event) => handleChange(event, "username")}
              />
              {usernameErr && <div className="error">{usernameErr}</div>}
            </div>
            {/* email address  */}
            <div className="space-y-1">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(event) => handleChange(event, "email")}
              />
              {emailErr && <div className="error">{emailErr}</div>}
            </div>
            {/* Phone number*/}
            <div className="space-y-1">
              <label htmlFor="contact">Phone Number</label>
              <input
                type="text"
                id="contact"
                value={contact}
                onChange={(event) => handleChange(event, "contact")}
              />
              {contactErr && <div className="error">{contactErr}</div>}
            </div>
            {/* password  */}
            <div className="space-y-1">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(event) => handleChange(event, "password")}
              />
              {passwordErr && <div className="error">{passwordErr}</div>}
            </div>
            {/* confirm password  */}
            <div className="space-y-1">
              <label htmlFor="confirm-password">Confirm Password</label>
              <input
                type="password"
                id="confirm-password"
                value={confirmPassword}
                onChange={(event) => handleChange(event, "confirmPassword")}
              />
              {confirmPasswordErr && (
                <div className="error text-sm">{confirmPasswordErr}</div>
              )}
            </div>

            {confirmationEmail && (
              <div className="text-green-600 text-sm">{confirmationEmail}</div>
            )}
            <div className="button-animation" style={{ display: "block" }}>
              <button className="animation-text text-center px-5 py-3 w-full">
                <div className="flex justify-center space-x-2 items-center">
                  <div>Register</div>
                  {saveLoader && <Processing />}
                </div>
              </button>
              <div className="animation-bg"></div>
            </div>
          </div>
          <div className="text-xs mt-2 text-center">
            Already have an account ?{" "}
            <Link
              to="/login"
              className="underline"
              onClick={() => window.scrollTo(0, 0)}
            >
              Login here
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
