import { React, useState, useEffect } from "react";
import { baseUrl, headers } from "../authentication/authorization";
import OrderDetailPopup from "./order-detail-popup";
import Processing from "../common/processing";
import OrderConfirmationPopup from "./order-confirmation-popup";
import PopupMessage from "../common/popup-message";
import axios from "axios";
function Order({ normal, width }) {
  const [popup, setPopup] = useState("");
  const [saveLoader, setSaveLoader] = useState(false);
  const [alert, setAlert] = useState("");
  const [orderUpdate, setOrderUpdate] = useState({
    index: "",
    action: "",
    confirmationPopup: "",
  });
  const [orders, setOrders] = useState([]);
  const [next, setNext] = useState("");
  const [orderSubLists, setOrderSubLists] = useState([
    { isTrue: false, name: "All" },
    { isTrue: true, name: "Pending" },
    { isTrue: false, name: "Delivered" },
    { isTrue: false, name: "Canceled" },
    // { isTrue: false, name: "Date" },
  ]);
  const subTitle = [
    { isTrue: false, name: "All" },
    { isTrue: false, name: "Pending" },
    { isTrue: false, name: "Delivered" },
    { isTrue: false, name: "Canceled" },
    // { isTrue: false, name: "Date" },
  ];
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    setLoading(true);
    fetch(`${baseUrl}/order-list/?search=pending`, {
      method: "GET",
      headers: headers(),
      signal,
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.clear();
          window.location = "/";
        }
        return response.json();
      })
      .then((dta) => {
        const { next, results } = dta;
        if (Array.isArray(results)) {
          setOrders(results);
          setNext(next);
          setLoading(false);
        }
      })
      .catch(() => setLoading(false));

    return () => controller.abort();
  }, []);

  const handleOrderStatusChange = (popupResponse) => {
    if (popupResponse === "yes") {
      const { action, index } = orderUpdate;
      const orders1 = [...orders];
      const order = orders1[index];
      order.action = action + "ed";

      setOrderUpdate({
        ...orderUpdate,
        confirmationPopup: "",
      });

      fetch(`${baseUrl}/order-edit/${order.id}`, {
        method: "PATCH",
        headers: { ...headers(), "Content-Type": "application/json" },
        body: JSON.stringify(order),
      })
        .then((response) => {
          if (response.ok) {
            setAlert(`Selected order is ${action}ed successfully`);
            orders1[index].status = action;
            if (order.action === "delivered") {
              orders1[index].customer.loyal = false;
            }
            setOrders(orders1);
          } else {
            setAlert(`We could not ${action} the selected order`);
          }
          setOrderUpdate({
            confirmationPopup: "",
            action: "",
            index: "",
          });
        })
        .catch(() => setAlert(`We could not ${action} the selected order`));
    } else {
      setOrderUpdate({
        index: "",
        action: "",
        confirmationPopup: "",
      });
    }
  };

  const loadMoreOrders = () => {
    if (!saveLoader) {
      setSaveLoader(true);
      fetch(next, {
        method: "GET",
        headers: headers(),
      })
        .then((response) => response.json())
        .then((dta) => {
          const { next, results } = dta;
          if (Array.isArray(results)) {
            setOrders([...orders, ...results]);
            setNext(next);
          }

          setSaveLoader(false);
        })
        .catch(() => setSaveLoader(false));
    }
  };

  const handleOrderData = (name, index) => {
    if (name === "All") {
      subTitle[index].isTrue = true;
      setOrderSubLists(subTitle);
      setLoading(true);
      setOrders([]);
      axios
        .get(`${baseUrl}/order-list`, {
          headers: headers(),
        })
        .then((res) => {
          setOrders(res.data.results);
          setLoading(false);
        })
        .catch((err) => {
          if (err.response.data.code === "token_not_valid") {
            localStorage.clear();
            window.location = "/";
          }
        });
    } else if (name === "Pending") {
      subTitle[index].isTrue = true;
      setOrderSubLists(subTitle);
      setLoading(true);
      setOrders([]);
      axios
        .get(`${baseUrl}/order-list/?search=pending`, {
          headers: headers(),
        })
        .then((res) => {
          setOrders(res.data.results);
          setLoading(false);
        })
        .catch((err) => {
          if (err.response.data.code === "token_not_valid") {
            localStorage.clear();
            window.location = "/";
          }
        });
    } else if (name === "Delivered") {
      subTitle[index].isTrue = true;
      setOrderSubLists(subTitle);
      setLoading(true);
      setOrders([]);
      axios
        .get(`${baseUrl}/order-list/?search=deliver`, {
          headers: headers(),
        })
        .then((res) => {
          setOrders(res.data.results);
          setLoading(false);
        })
        .catch((err) => {
          if (err.response.data.code === "token_not_valid") {
            localStorage.clear();
            window.location = "/";
          }
        });
    } else if (name === "Canceled") {
      subTitle[index].isTrue = true;
      setOrderSubLists(subTitle);
      setLoading(true);
      setOrders([]);
      axios
        .get(`${baseUrl}/order-list/?search=cancel`, {
          headers: headers(),
        })
        .then((res) => {
          setOrders(res.data.results);
          setLoading(false);
        })
        .catch((err) => {
          if (err.response.data.code === "token_not_valid") {
            localStorage.clear();
            window.location = "/";
          }
        });
    }
  };

  const { confirmationPopup } = orderUpdate;

  return (
    <>
      {confirmationPopup !== "" && (
        <OrderConfirmationPopup
          handleConfirmation={handleOrderStatusChange}
          message={confirmationPopup}
        />
      )}
      {popup !== "" && (
        <OrderDetailPopup
          data={orders[popup]}
          handleClose={() => setPopup("")}
        />
      )}
      {alert !== "" && (
        <PopupMessage message={alert} handleClose={() => setAlert("")} />
      )}
      <div className="flex  flex-col px-4">
        {/* sub header  */}
        <div className="flex text-gray-500  font-medium text-sm border-b px-5 lg:px-0 mt-20">
          {orderSubLists.map((list, index) => {
            const { name, isTrue } = list;
            return (
              <div
                key={index}
                className={`transition-all duration-300 ease-in-out cursor-pointer border-b border-white py-2 px-3 hover:bg-primary hover:text-white font-semibold 
              ${isTrue ? "bg-primary text-white" : ""}`}
                onClick={() => handleOrderData(name, index)}
              >
                {name}
              </div>
            );
          })}
        </div>
        {/* table  */}
        {/* for labtop  */}
        {width > 767 && (
          <div className="w-full  mt-4 bg-white p-5">
            {loading && (
              <div className="w-full flex justify-center">
                <div className="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}

            {orders.length === 0 && !loading && (
              <div className="w-full flex justify-center text-primary text-xl">
                No Data
              </div>
            )}
            {orders.length !== 0 && (
              <table className="table-auto w-full ">
                <thead className="">
                  <tr className="font-semibold text-gray-700 text-xs uppercase">
                    <th className="text-left border-b py-2 pl-2">Id</th>
                    <th className="text-left border-b py-2">name</th>
                    <th className="text-left border-b py-2">order at</th>
                    <th className="text-left border-b py-2">Amount</th>
                    <th className="text-left border-b py-2">status</th>
                    <th className="text-left border-b py-2">type</th>
                    <th className="text-left border-b py-2">
                      delivered/
                      <br />
                      canceled at
                    </th>
                    <th className="text-left border-b py-2">action</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order, index) => {
                    const {
                      id,
                      customer: { loyal, username, f_name, l_name },
                      status,
                      order_at,
                      update_at,
                      total_amount,
                      pick_up,
                    } = order;
                    let user;
                    if (f_name) {
                      user =
                        f_name[0].toUpperCase() +
                        (f_name[1] ? f_name.slice(1, f_name.length) : "") +
                        " " +
                        l_name[0].toUpperCase() +
                        (l_name[1] ? l_name.slice(1, l_name.length) : "");
                    } else {
                      user =
                        username[0].toUpperCase() +
                        (username[1] ? username.slice(1, username.length) : "");
                    }
                    return (
                      <tr
                        className="cursor-pointer hover:bg-gray-100 text-xs lg:text-sm text-gray-600  border-b transition-all duration-300"
                        key={index}
                      >
                        <td
                          className="py-2 text-left"
                          onClick={() => setPopup(index)}
                        >
                          {id}
                        </td>
                        <td
                          className="py-2 text-left relative overflow-hidden"
                          onClick={() => setPopup(index)}
                        >
                          {loyal && (
                            <span className="absolute top-1.5 -left-5 bg-green-600 text-white text-xs w-16 text-center transform -rotate-45">
                              Loyal
                            </span>
                          )}
                          <div className="pl-3">{user}</div>
                        </td>
                        <td
                          className="py-2 flex flex-col"
                          onClick={() => setPopup(index)}
                        >
                          <div className=" flex items-center font-semibold">
                            <span>
                              {" "}
                              {order_at.split("T")[1].split(":")[0]}:
                              {order_at.split("T")[1].split(":")[1]}
                            </span>
                            <span>
                              <svg
                                className="w-3 h-3 ml-1"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                            </span>
                          </div>
                          <span>{order_at.split("T")[0]} </span>
                        </td>
                        <td
                          className="py-2 text-left"
                          onClick={() => setPopup(index)}
                        >
                          $ {total_amount / 100}
                        </td>
                        <td
                          className="py-2 text-left"
                          onClick={() => setPopup(index)}
                        >
                          {status === "pending" && (
                            <span className="bg-green-500 px-3 py-1 rounded-full text-white">
                              Pending
                            </span>
                          )}
                          {(status === "delivered" || status === "deliver") && (
                            <span className="bg-indigo-500 px-3 py-1 rounded-full text-white">
                              Delivered
                            </span>
                          )}
                          {(status === "canceled" || status === "cancel") && (
                            <span className="bg-red-500 px-3 py-1 rounded-full text-white">
                              Canceled
                            </span>
                          )}
                        </td>
                        <td className="py-2 text-left">
                          {pick_up && "Pickup"}
                          {!pick_up && "Delivery"}
                        </td>
                        <td className="py-2 text-left">
                          {update_at &&
                            update_at.split(".")[0] !==
                              order_at.split(".")[0] &&
                            update_at.split("T")[0]}
                        </td>
                        <td className="py-2 text-left">
                          {status === "pending" && (
                            <div className="flex space-x-4 text-xs relative">
                              {!normal && (
                                <span
                                  className="bg-indigo-500 text-white px-3 py-1 relative left-0"
                                  onClick={() =>
                                    setOrderUpdate({
                                      index: index,
                                      action: "deliver",
                                      confirmationPopup:
                                        "Are you sure that this order is delivered ?",
                                    })
                                  }
                                >
                                  Delivered
                                </span>
                              )}

                              <span
                                className="bg-red-500 text-white px-3 py-1 relative right-0"
                                onClick={() =>
                                  setOrderUpdate({
                                    index: index,
                                    action: "cancel",
                                    confirmationPopup:
                                      "Are you sure that you want to cancel this order ?",
                                  })
                                }
                              >
                                Cancel
                              </span>
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
            {next && (
              <div className="mt-4">
                <button
                  className="flex items-center  space-x-2 bg-indigo-500 text-white px-5 py-2 rounded-md shadow-md transition-all duration-300 ease-in-out hover:bg-indigo-600"
                  onClick={loadMoreOrders}
                >
                  <div>{saveLoader ? "Loading" : "Load More"}</div>
                  {saveLoader && <Processing />}
                </button>
              </div>
            )}
          </div>
        )}
        {width < 768 && (
          <div className="w-full flex flex-col mt-4">
            {loading && (
              <div className="w-full flex justify-center">
                <div className="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}

            {orders.length === 0 && !loading && (
              <div className="w-full flex justify-center text-primary text-xl">
                No Data
              </div>
            )}
            {orders.length !== 0 && (
              <div className="w-full flex flex-col mt-4">
                {orders.map((order, index) => {
                  const {
                    customer: { loyal, username, f_name, l_name },
                    status,
                    order_at,
                    update_at,
                    total_amount,
                  } = order;
                  let user;
                  if (f_name) {
                    user =
                      f_name[0].toUpperCase() +
                      (f_name[1] ? f_name.slice(1, f_name.length) : "") +
                      " " +
                      l_name[0].toUpperCase() +
                      (l_name[1] ? l_name.slice(1, l_name.length) : "");
                  } else {
                    user =
                      username[0].toUpperCase() +
                      (username[1] ? username.slice(1, username.length) : "");
                  }
                  return (
                    <div
                      className="flex flex-col bg-gray-50 shadow mt-2 py-3 rounded px-5 text-sm space-y-1"
                      key={index}
                    >
                      {/* username  */}
                      <div
                        className="flex space-x-4 items-center"
                        onClick={() => setPopup(index)}
                      >
                        <div className="w-28 text-xs font-semibold uppercase">
                          username
                        </div>
                        <div className="  items-center text-gray-600 flex space-x-2">
                          <span>{user}</span>
                          {loyal && (
                            <span className=" bg-green-600 text-white text-xs w-16 text-center">
                              Loyal
                            </span>
                          )}
                        </div>
                      </div>
                      {/* orderAt  */}
                      <div
                        className="flex space-x-4 items-center"
                        onClick={() => setPopup(index)}
                      >
                        <div className="w-28 text-xs font-semibold uppercase">
                          orderAt
                        </div>
                        <div className=" items-center text-gray-600">
                          {order_at.split("T")[0]}
                        </div>
                      </div>
                      {/* Price  */}
                      <div
                        className="flex space-x-4 items-center"
                        onClick={() => setPopup(index)}
                      >
                        <div className="w-28 text-xs font-semibold uppercase">
                          Amount
                        </div>
                        <div className=" items-center text-gray-600">
                          {total_amount / 100}
                        </div>
                      </div>
                      {/* status  */}
                      <div
                        className="flex space-x-4 items-center"
                        onClick={() => setPopup(index)}
                      >
                        <div className="w-28 text-xs font-semibold uppercase">
                          status
                        </div>
                        <div className="  items-center text-gray-600">
                          {status === "pending" && (
                            <span className="text-green-500">Pending</span>
                          )}
                          {(status === "deliver" || status === "delivered") && (
                            <span className="text-indigo-500 ">Delivered</span>
                          )}
                          {(status === "cancel" || status === "canceled") && (
                            <span className="text-red-500 ">Canceled</span>
                          )}
                        </div>
                      </div>
                      {/* deliveredcancled  */}
                      <div
                        className="flex space-x-4 items-center"
                        onClick={() => setPopup(index)}
                      >
                        <div className="w-28 text-xs font-semibold uppercase">
                          delivered / canceled
                        </div>
                        <div className=" items-center text-gray-600">
                          {status !== "pending" && update_at.split("T")[0]}
                        </div>
                      </div>
                      {/* Action  */}
                      {status === "pending" && (
                        <div className="flex space-x-4 items-center">
                          <div className="w-28 text-xs font-semibold uppercase">
                            Action
                          </div>

                          <div className=" items-center text-gray-600">
                            <div className="flex space-x-4 text-xs">
                              {!normal && (
                                <span
                                  className="bg-indigo-500 text-white px-3 py-1"
                                  onClick={() =>
                                    setOrderUpdate({
                                      index: index,
                                      action: "deliver",
                                      confirmationPopup:
                                        "Are you sure that this order is deliverd ?",
                                    })
                                  }
                                >
                                  Delivered
                                </span>
                              )}

                              <span
                                className="bg-red-500 text-white px-3 py-1"
                                onClick={() =>
                                  setOrderUpdate({
                                    index: index,
                                    action: "cancel",
                                    confirmationPopup:
                                      "Are you sure that you want to cancel this order ?",
                                  })
                                }
                              >
                                Cancel
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}

            {next && (
              <div className="mt-4">
                <button
                  className="flex items-center  space-x-2 bg-indigo-500 text-white px-5 py-2 rounded-md shadow-md transition-all duration-300 ease-in-out hover:bg-indigo-600"
                  onClick={loadMoreOrders}
                >
                  <div>{saveLoader ? "Loading" : "Load More"}</div>
                  {saveLoader && <Processing />}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default Order;
