import { useState } from "react";

export default function Cookies() {
  const [accepted, setAccepted] = useState(
    document.cookie.includes("cookieAccepted=true")
  );

  const handleCookiesAccepted = () => {
    const date = +new Date();
    document.cookie =
      "cookieAccepted=true;expires=" +
      new Date(date + 10 * 365 * 86400 * 1000).toUTCString();
    setAccepted(true);
  };

  if (accepted) {
    return null;
  }

  return (
    <div className="fixed bottom-0 bg-white w-full left-0 py-8 text-gray-900 z-50">
      <div className="max-w-7xl mx-auto px-4 md:px-14 lg:px-20">
        By using this site you agree to our Terms and Conditions. Please{" "}
        <span
          className="underline cursor-pointer"
          onClick={handleCookiesAccepted}
        >
          Accept
        </span>{" "}
        these before using this site
      </div>
    </div>
  );
}
