import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { baseUrl, headers } from "../../authentication/authorization";
import axios from "axios";
import exportFromJSON from "export-from-json";
function DonutChart() {
  const [totalData, setTotalData] = useState([]);
  const [data, setData] = useState([]);

  const state = {
    options: {
      labels: ["Delivered", "Cancelled"],
      legend: {
        position: "bottom",
      },
    },
    series: data,
  };
  const handleDownload = () => {
    exportFromJSON({
      data: totalData,
      fileName: "download",
      exportType: exportFromJSON.types.xls,
    });
  };
  const handleSalesReport = (data) => {
    if (data === "all") {
      // setTotalData([]);
      axios
        .get(`${baseUrl}/sales-report/`, {
          headers: headers(),
        })
        .then((res) => {
          setTotalData(res.data);
          let data = res.data;
          let dData = [];
          let cData = [];
          // let TotalData = [];
          for (let i = 0; i < data.length; i++) {
            if (data[i].status === "deliver") {
              dData.push(data[i]);
            } else {
              cData.push(data[i]);
            }
          }
          let val = [dData.length, cData.length];
          let array = [...data, val];
          array.shift();
          setData(array);
        })
        .catch(() => null);
    } else if (data === "daily") {
      axios
        .get(`${baseUrl}/get-daily-data/`, {
          headers: headers(),
        })
        .then((res) => {
          setTotalData(res.data);
          let data = res.data;
          let dData = [];
          let cData = [];
          // let TotalData = [];
          for (let i = 0; i < data.length; i++) {
            if (data[i].status === "deliver") {
              dData.push(data[i]);
            } else {
              cData.push(data[i]);
            }
          }
          let val = [dData.length, cData.length];
          let array = [...data, val];
          array.shift();
          setData(array);
        })
        .catch(() => null);
    } else if (data === "weekly") {
      // setTotalData([]);
      axios
        .get(`${baseUrl}/get-weekly-data/`, {
          headers: headers(),
        })
        .then((res) => {
          setTotalData(res.data);
          let data = res.data;
          let dData = [];
          let cData = [];
          // let TotalData = [];
          for (let i = 0; i < data.length; i++) {
            if (data[i].status === "deliver") {
              dData.push(data[i]);
            } else {
              cData.push(data[i]);
            }
          }
          let val = [dData.length, cData.length];
          let array = [...data, val];
          array.shift();
          setData(array);
        })
        .catch(() => null);
    } else if (data === "monthly") {
      axios
        .get(`${baseUrl}/get-monthly-data/`, {
          headers: headers(),
        })
        .then((res) => {
          setTotalData(res.data);
          let data = res.data;
          let dData = [];
          let cData = [];
          // let TotalData = [];
          for (let i = 0; i < data.length; i++) {
            if (data[i].status === "deliver") {
              dData.push(data[i]);
            } else {
              cData.push(data[i]);
            }
          }
          let val = [dData.length, cData.length];
          let array = [...data, val];
          array.shift();
          setData(array);
        })
        .catch(() => null);
    } else if (data === "yearly") {
      axios
        .get(`${baseUrl}/get-yearly-data/`, {
          headers: headers(),
        })
        .then((res) => {
          setTotalData(res.data);
          let data = res.data;
          let dData = [];
          let cData = [];
          // let TotalData = [];
          for (let i = 0; i < data.length; i++) {
            if (data[i].status === "deliver") {
              dData.push(data[i]);
            } else {
              cData.push(data[i]);
            }
          }
          let val = [dData.length, cData.length];
          let array = [...data, val];
          array.shift();
          setData(array);
        })
        .catch(() => null);
    }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    setTotalData([]);
    function getSalesReport() {
      axios
        .get(`${baseUrl}/sales-report/`, {
          headers: headers(),
          signal,
        })
        .then((res) => {
          setTotalData(res.data);
          let data = res.data;
          let dData = [];
          let cData = [];
          // let TotalData = [];
          for (let i = 0; i < data.length; i++) {
            if (data[i].status === "deliver") {
              dData.push(data[i]);
            } else {
              cData.push(data[i]);
            }
          }
          setData([dData.length, cData.length]);
        })
        .catch(() => null);
    }
    getSalesReport();

    return () => controller.abort();
  }, []);
  return (
    <div className="app">
      <div className="flex justify-between">
        <div className=" ">
          <div className="relative">
            <select
              name=""
              id=""
              className="bg-white border border-gray-300 px-3 py-2 focus:outline-none focus:border-primary rounded-lg appearance-none cursor-pointer w-28"
              onChange={(e) => {
                handleSalesReport(e.target.value);
              }}
            >
              <option value="all">All</option>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="yearly">Yearly</option>
            </select>
            <svg
              className="w-6 h-6 absolute top-2 right-1 text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
        <div className="">
          <button
            className=" bg-white border border-gray-300 px-3 py-2 focus:outline-none focus:border-primary rounded-lg cursor-pointer"
            onClick={handleDownload}
          >
            Download
          </button>
        </div>
      </div>

      <div className="row">
        <div className="mixed-chart">
          <Chart
            options={state.options}
            series={state.series}
            type="donut"
            height="300"
            width="100%"
          />
        </div>
      </div>
    </div>
  );
}

export default DonutChart;
