import Chart from "react-apexcharts";

export default function LineChart({ data, categories }) {
  const state = {
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: categories,
      },
      fill: {
        opacity: 0.5,
      },
    },
    series: [
      {
        name: "Orders",
        data: data,
      },
    ],
  };

  return (
    <div className="app">
      <div className="text-center">Number of orders per day</div>
      <div className="row">
        <div className="mixed-chart">
          <Chart
            options={state.options}
            series={state.series}
            type="line"
            height="300"
            width="100%"
          />
        </div>
      </div>
    </div>
  );
}
