import React from "react";

const HalalFood = () => {
  return (
    <div className="fixed top-60 right-0 z-40 offer-icon transition-all duration-150 ease-linear bg-primary text-white p-3 rounded-l-full cursor-pointer">
        <div className="font-semibold">We Serve Halal Meat</div>
    </div>
  );
};

export default HalalFood;