import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import DeleteConfirmationPopup from "../../common/delete-confirmation";
import PopupMessage from "../../common/popup-message";
import { baseUrl, headers } from "../../authentication/authorization";
import Processing from "../../common/processing";

export default function DeliveryRange() {
  const [delivery, setDelivery] = useState({
    ranges: "",
    price: "",
  });
  const isAvailable = useRef();
  const [activeId, setActiveId] = useState("");
  const [error, setError] = useState("");
  const [deliveries, setDeliveries] = useState([]);
  const [saveLoader, setSaveLoader] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [alert, setAlert] = useState("");

  async function getDeliveries() {
    await axios
      .get(`${baseUrl}/delivery-range`)
      .then((res) => {
        if (Array.isArray(res.data)) {
          setDeliveries(res.data);
        }
      })
      .catch(() => null);
  }

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    async function getDeliveries() {
      await fetch(`${baseUrl}/delivery-range`, {
        method: "GET",
        signal,
      })
        .then((response) => response.json())
        .then((data) => {
          if (Array.isArray(data)) {
            setDeliveries(data);
          }
        })
        .catch(() => null);
    }
    getDeliveries();
    return () => controller.abort();
  }, []);

  const submitForm = (e) => {
    e.preventDefault();
    const { ranges, price } = delivery;
    let rangeArr = ranges.split("-");

    if (String(price).trim() === "" || ranges.trim() === "") {
      setError("Both fields are required");
    } else if (rangeArr.length !== 2) {
      setError("Invalid range");
    } else if (
      typeof +rangeArr[0] !== "number" ||
      typeof +rangeArr[1] !== "number"
    ) {
      setError("Invalid range");
    }
    // eslint-disable-next-line
    else if (+price !== +price) {
      setError("Price must be valid number");
    } else if (!saveLoader) {
      setSaveLoader(true);
      const formData = new FormData();
      formData.append("ranges", ranges);
      formData.append("price", price);
      formData.append("is_displayed", isAvailable.current.checked);
      if (activeId) {
        fetch(`${baseUrl}/delivery-range-edit/${activeId}`, {
          method: "PUT",
          body: formData,
          headers: headers(),
        })
          .then((res) => {
            setSaveLoader(false);
            return res.json();
          })
          .then((dta) => {
            setSaveLoader(false);
            const { id } = dta;
            if (id) {
              setDelivery({
                ranges: "",
                price: "",
              });
              setActiveId("");
              getDeliveries();
            }
          })
          .catch((error) => {
            setSaveLoader(false);
            setAlert("could not edit the product");
          });
        setActiveId("");
      } else {
        setSaveLoader(true);
        fetch(`${baseUrl}/delivery-range-add/`, {
          method: "POST",
          body: formData,
          headers: headers(),
        })
          .then((response) => response.json())
          .then((data) => {
            setSaveLoader(false);
            const { id } = data;
            if (id) {
              setDelivery({
                ranges: "",
                price: "",
              });
              getDeliveries();
            } else {
              setAlert("Could not add the delivery range");
            }
          })
          .catch((error) => {
            setSaveLoader(false);
            setAlert("could not add the product");
          });
      }
    }
  };

  const handleDeliveryRangeDelete = (action) => {
    if (action === "delete") {
      fetch(`${baseUrl}/delivery-range-edit/${deleteId}`, {
        method: "DELETE",
        headers: headers(),
      })
        .then((res) => {
          if (res.statusText === "No Content") {
            getDeliveries();
          }
        })
        .catch(() => null);
    }

    setDeletePopup(false);
  };

  const handleEdit = (id, ranges, price, is_displayed) => {
    setDelivery({
      ranges,
      price,
    });
    isAvailable.current.checked = is_displayed;
    setActiveId(id);
  };

  const { ranges, price } = delivery;

  return (
    <>
      {deletePopup !== false && (
        <DeleteConfirmationPopup
          handleDelete={handleDeliveryRangeDelete}
          message="Are you sure that you want to delete selected delivery range ?"
        />
      )}
      {alert !== "" && (
        <PopupMessage message={alert} handleClose={() => setAlert("")} />
      )}
      <div className="flex p-8">
        <div className="w-full md:flex md:space-x-6 space-y-6 md:space-y-0">
          {/* right side  */}
          <div className=" w-full md:w-2/5 ">
            <div className="bg-white rounded-md shadow-md flex flex-col py-5 h-auto w-full">
              {/* header  */}
              <div className="px-10 border-b border-gray-300 pb-4 text-lg font-semibold uppercase  tracking-wide">
                Delivery Range
              </div>
              {/* form  */}
              <form className="flex flex-col px-10 py-4 space-y-4">
                {/* product type name  */}
                <div className="flex flex-col space-y-1">
                  <label className="text-sm font-medium text-gray-700">
                    Range in mile <span className="text-xs">Eg: 2-3</span>
                  </label>
                  <div>
                    <input
                      type="text"
                      className="border w-full px-5 border-gray-300 focus:border-indigo-500 py-1.5 text-gray-700 text-sm"
                      value={ranges}
                      onChange={(e) => {
                        setError("");
                        setDelivery({
                          ...delivery,
                          ranges: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-col space-y-1">
                  <label className="text-sm font-medium text-gray-700">
                    Price in dollar <span className="text-xs">Eg: 5</span>
                  </label>
                  <div>
                    <input
                      type="text"
                      className="border w-full px-5 border-gray-300 focus:border-indigo-500 py-1.5 text-gray-700 text-sm"
                      value={price}
                      onChange={(e) => {
                        setError("");
                        setDelivery({
                          ...delivery,
                          price: e.target.value,
                        });
                      }}
                    />
                  </div>
                  {error && <div className="error">{error}</div>}
                </div>
                {/* displayed  */}
                <div className="text-gray-700 space-x-1">
                  <input type="checkbox" ref={isAvailable} id="is_available" />{" "}
                  <label htmlFor="is_available">Display</label>
                </div>
                {/* submit  */}
                <div className="">
                  <button
                    className="flex items-center  space-x-2 bg-indigo-500 text-white px-5 py-2 rounded-md shadow-md transition-all duration-300 ease-in-out hover:bg-indigo-600"
                    type="submit"
                    onClick={submitForm}
                  >
                    <div>{saveLoader ? "Saving" : "Save"}</div>
                    {saveLoader && <Processing />}
                  </button>
                </div>
              </form>
            </div>
          </div>
          {/* left side  */}
          <div className="flex-1">
            <div className="flex flex-col">
              <div className="bg-white rounded-md shadow-md flex flex-col py-5 h-auto w-full">
                {/* header  */}
                <div className="px-10 border-b border-gray-300 pb-4 text-lg font-semibold uppercase  tracking-wide">
                  All Ranges (<span className="text-base">in mile</span>)
                </div>
                {/* caretgory items list  */}
                <div className="grid grid-cols-1 sm:grid-cols-2  lg:grid-cols-2 xl:grid-cols-3 gap-x-2 gap-y-4 py-10">
                  {deliveries.map((product, index) => {
                    const { ranges, price, id, is_displayed } = product;
                    return (
                      <div className="col-span-1 px-3" key={index}>
                        <div className="bg-gray-200 p-2 flex justify-between text-sm items-center">
                          <span>
                            {ranges} &nbsp;&nbsp;&nbsp;&nbsp;${price}
                          </span>
                          <div className="flex items-center space-x-1">
                            <button
                              onClick={() =>
                                handleEdit(id, ranges, price, is_displayed)
                              }
                            >
                              <svg
                                className="w-5 h-5 text-green-600"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                ></path>
                              </svg>
                            </button>
                            <button
                              onClick={() => {
                                setDeleteId(id);
                                setDeletePopup(true);
                              }}
                            >
                              <svg
                                className="w-5 h-5 text-red-600"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
