import { Link } from "react-router-dom";

function FrontPage({ id, title, header, description, button, url }) {
  return (
    <div
      className="bg-fixed bg-no-repeat bg-center h-80 md:h-96 lg:h-102 bg-cover font-text relative"
      id={id}
    >
      <main className="w-full h-full flex flex-col justify-center items-center absolute top-0 space-y-5 px-6 pt-10">
        {title && (
          <div className="text-primary bg-black bg-opacity-60 text-center text-lg md:text-2xl lg:text-3xl tracking-wider mt-5">
            {title}
          </div>
        )}

        {header && (
          <div className="text-white bg-black bg-opacity-60 text-4xl md:text-5xl font-header lg:text-7xl text-center">
            {header}
          </div>
        )}

        {description && (
          <div className="text-center font-text bg-black bg-opacity-60 text-white font-medium mt-5 text-sm lg:text-lg max-w-2xl">
            {description}
          </div>
        )}

        {button && (
          <div>
            <div className="button-animation">
              <Link
                to={url}
                onClick={() => window.scrollTo(0, 0)}
                className="animation-text px-5 py-3"
              >
                {button}
              </Link>
              <div className="animation-bg"></div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
}

export default FrontPage;
