import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { baseUrl, headers } from "../authentication/authorization";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import CheckIcon from "@material-ui/icons/Check";
import bg from "../../assets/images/menu-items/resturant.jpg";
import PopupForm from "./popup-form";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import deliveryMan from "../../assets/images/delivery-ban.png";
const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

export default function Cart({ history, setTotalItems, tax }) {
  const [popupForm, setPopupForm] = useState(false);
  const [menuLists, setMenuLists] = useState([]);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [nonDeliverable, setNonDeliverable] = useState([]);
  const checkTotalPrice = () => {
    let total = 0;
    for (let i = 0; i < menuLists.length; i++) {
      const { value, price } = menuLists[i];
      total += value * price;
    }
    setTotal(total);
  };
  let animation = [];
  for (let i = 0; i < 2; i++) {
    animation.push(
      <div className="animate-pulse flex space-x-4" key={i}>
        {/* image part */}
        <div className="bg-yellow-200 h-40 w-40"></div>
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-yellow-200 rounded w-3/4"></div>
          <div className="h-4 flex justify-between ">
            <div className="bg-yellow-200 w-20 rounded"></div>
            <div className="bg-yellow-200 w-10 rounded"></div>
          </div>
          <div className="space-y-2">
            <div className="h-16 bg-yellow-200 rounded"></div>
            <div className="h-4 flex justify-between ">
              <div className="bg-yellow-200 w-20 rounded"></div>
              <div className="bg-yellow-200 w-10 rounded"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    setLoading(true);
    setMenuLists([]);
    const getMenuLists = async () => {
      let menuLists;
      let newMenuList = [];
      const url = `${baseUrl}/menu-list`;
      await fetch(url, {
        method: "GET",
        signal,
      })
        .then((response) => response.json())
        .then((dta) => {
          menuLists = dta;
        })
        .catch(() => null);

      if (Array.isArray(menuLists)) {
        let cart = localStorage.getItem("cart");
        if (
          cart !== undefined &&
          typeof JSON.parse(cart) === "object" &&
          JSON.parse(cart) !== null
        ) {
          cart = JSON.parse(cart);
        } else {
          cart = {
            items: [],
            total: 0,
          };
        }
        const { items } = cart;
        for (let i = 0; i < menuLists.length; i++) {
          let value = 0;
          if (Array.isArray(items) && items.length > 0) {
            for (let j = 0; j < items.length; j++) {
              if (items[j].id === menuLists[i].id) {
                value = items[j].value;
                newMenuList.push(menuLists[i]);
              }
            }
          }

          menuLists[i].value = value;
          menuLists[i].added = false;
        }
        setLoading(false);
        setMenuLists(newMenuList);
        const non_deleverable = newMenuList.filter(
          (data) => data.is_deleverable === false
        );
        setNonDeliverable(non_deleverable);
      }
    };
    getMenuLists();

    return () => controller.abort();
  }, []);
  const handleAddedOrRemovedMessage = (id) => {
    setTimeout(() => {
      const carts = localStorage.getItem("cart");
      let items, cart;
      try {
        cart = JSON.parse(carts);
        items = cart.items;
      } catch (error) {
        cart = {};
        items = [];
      }
      const result = items.filter((item) => item.id === id);
      if (result.length > 0) {
        let newMenuLists = [...menuLists];
        newMenuLists.forEach((menu) => {
          if (menu.id === id) {
            menu.added = false;
          }
        });

        setMenuLists(newMenuLists);
        setLoading(false);
      }
    }, 3000);
  };
  const checkTotalItems = (arr) =>
    arr.reduce((total, current) => total + current.value, 0);

  const addToCart = (id) => {
    const newMenuLists = [...menuLists];
    let cart = localStorage.getItem("cart");
    if (
      cart !== undefined &&
      typeof JSON.parse(cart) === "object" &&
      JSON.parse(cart) !== null
    ) {
      cart = JSON.parse(cart);
    } else {
      cart = {
        items: [],
        total: 0,
      };
    }

    const { items } = cart;
    for (let i = 0; i < newMenuLists.length; i++) {
      const { id: menuId, value } = newMenuLists[i];
      let bool = false;
      if (id === menuId) {
        for (let j = 0; j < items.length; j++) {
          if (items[j].id === id) {
            if (value > 0) {
              items[j].value = value;
              bool = true;
              j = items.length;
              newMenuLists[i].added = true;
              handleAddedOrRemovedMessage(id);
            } else if (value < 1) {
              items.splice(j, 1);
              bool = true;
              j = items.length;
              newMenuLists[i].added = true;
              handleAddedOrRemovedMessage(id);
            }
          }
        }
        if (!bool) {
          items.push({
            id,
            value,
          });
          newMenuLists[i].added = true;
          handleAddedOrRemovedMessage(id);
        }
        cart.total = checkTotalItems(items);
        setTotalItems(cart.total);
        localStorage.setItem("cart", JSON.stringify({ ...cart, items }));
        i = menuLists.length;
      }
    }
    setMenuLists(newMenuLists);
  };
  const handleValueNumberChange = (id, event, operator) => {
    const newMenuLists = [...menuLists];
    newMenuLists.forEach((menu) => {
      if (menu.id === id) {
        if (event) {
          const value = +event.target.value;
          // eslint-disable-next-line
          if (+value === value && value > -1 && value < 100) {
            menu.value = value;
          }
        } else {
          const value = +menu.value;
          if (operator === "add" && value < 99) {
            menu.value = value + 1;
          } else if (operator === "subtract" && value > 0) {
            menu.value = value - 1;
          }
        }
      }
    });
    setMenuLists(newMenuLists);
    addToCart(id);
  };
  useEffect(() => checkTotalPrice());

  const handleClick = async (data) => {
    const items = [];
    for (let i = 0; i < menuLists.length; i++) {
      let obj = {};
      obj.id = menuLists[i].id;

      obj.name = menuLists[i].item;
      obj.price = menuLists[i].price;
      obj.quantity = +menuLists[i].value;
      items.push(obj);
    }

    let res;

    // Get Stripe.js instance

    const stripe = await stripePromise;
    // Call your backend to create the Checkout Session
    let accessToken;
    try {
      accessToken = localStorage.getItem("access");
    } catch {
      localStorage.clear();
      window.location = "/";
    }

    localStorage.setItem("payment-access", accessToken);
    fetch(`${baseUrl}/create-checkout-session/`, {
      method: "POST",
      headers: {
        ...headers(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cartItems: items,
        ...data,
      }),
    })
      .then((response) => response.json())
      .then((session) => {
        if (session && session.response && session["cart-session"]) {
          // When the customer clicks on the button, redirect them to Checkout.
          localStorage.setItem("sessionId", session.response);
          localStorage.setItem("cart-session", session["cart-session"]);
          stripe.redirectToCheckout({
            sessionId: session.response,
          });

          res = true;
        } else {
          res = false;
          setError(new Date());
        }
      })
      .catch(() => {
        res = false;
        setError(new Date());
      });

    return res;
  };
  const removeItemFromCart = (id) => {
    const carts = localStorage.getItem("cart");
    let items, cart, filteredCart;
    try {
      cart = JSON.parse(carts);
      items = cart.items;
    } catch (error) {
      cart = {};
      items = [];
    }

    const filtered = items.filter((item) => item.id !== id);
    if (filtered.length === 0) {
      localStorage.removeItem("cart");
      setTotalItems(0);
      setMenuLists([]);
    } else {
      filteredCart = {
        items: filtered,
        total: checkTotalItems(filtered),
      };
      setTotalItems(filteredCart.total);
      const menu1 = menuLists.filter((menu) => menu.id !== id);
      localStorage.setItem("cart", JSON.stringify(filteredCart));
      setMenuLists(menu1);
      const non_deleverable = menu1.filter(
        (data) => data.is_deleverable === false
      );
      setNonDeliverable(non_deleverable);
    }
  };
  return (
    <>
      <div>
        <div
          className="w-full  bg-no-repeat bg-center bg-cover bg-fixed "
          style={{
            backgroundImage: `url(${bg})`,
          }}
        >
          <div className="px-5 md:px-20 pt-32 pb-20 bg-black bg-opacity-50">
            {popupForm && (
              <PopupForm
                handleClick={handleClick}
                handleClose={() => setPopupForm(false)}
                error={error}
                subtotal={total}
                tax={tax}
                nonDeliverable={nonDeliverable}
              />
            )}
            {!popupForm && (
              <div className="w-full bg-white p-5">
                <div className="text-right mb-5">
                  <div
                    className="px-5 py-2 text-white focus:outline-none cursor-pointer bg-primary hover:bg-secondary inline-block"
                    onClick={() => {
                      history.push({
                        pathname: "/menu-list",
                        state: { goToMenu: true },
                      });
                    }}
                  >
                    Go to menu
                  </div>
                </div>
                <div className="w-full  border border-dashed border-primary  p-5 ">
                  {/* card  */}
                  {menuLists.length > 0 && (
                    <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6">
                      {menuLists.map((menu, index) => {
                        const {
                          id,
                          item,
                          description,
                          price,
                          img,
                          value,
                          added,
                          is_deleverable,
                        } = menu;

                        return (
                          <div key={index} className="col-span-1  md:p-5 ">
                            <div className="w-full justify-start lg:flex lg:space-x-4">
                              {/* for image  */}
                              <div className="lg:w-40 ">
                                <img
                                  src={img}
                                  className="w-full h-full object-cover"
                                  alt=""
                                />
                              </div>
                              {/* info area  */}
                              <div className="flex-1 flex flex-col space-y-1 mt-2 lg:mt-0 pb-3 sm:pb-0">
                                {/* food name  */}
                                <div className=" text-lg sm:text-xl xl:text-2xl font-semibold font-header">
                                  {item}
                                </div>
                                {price && (
                                  <div className="flex justify-between border-b border-dashed border-gray-300 sm:text-sm lg:text-base items-center">
                                    <div className="text-gray-600 text-sm xl:text-base">
                                      Price
                                    </div>
                                    <div className="text-primary text-sm xl:text-base">
                                      $ {price}
                                    </div>
                                  </div>
                                )}
                                <div className="text-gray-600 sm:text-sm xl:text-base">
                                  {description && description.slice(0, 30)}
                                  {description &&
                                    description.length > 30 &&
                                    "..."}
                                </div>
                                <div className="xl:flex justify-between space-y-2 xl:space-y-0">
                                  <div className="flex space-x-0.5">
                                    <button
                                      className="bg-gray-200 px-3 hover:bg-gray-300 focus:outline-none"
                                      onClick={() =>
                                        handleValueNumberChange(
                                          id,
                                          null,
                                          "subtract"
                                        )
                                      }
                                    >
                                      <RemoveIcon className="text-gray-700" />
                                    </button>
                                    <div>
                                      <input
                                        type="text"
                                        className="w-16 text-center border border-gray-300 py-0.5 text-sm"
                                        value={value}
                                        onChange={(event) =>
                                          handleValueNumberChange(id, event)
                                        }
                                      />
                                    </div>
                                    <button
                                      className="bg-gray-200 px-3 hover:bg-gray-300 focus:outline-none"
                                      onClick={() =>
                                        handleValueNumberChange(id, null, "add")
                                      }
                                    >
                                      <AddIcon className="text-gray-700" />
                                    </button>
                                    {added && <CheckIcon />}
                                  </div>
                                  <button
                                    className="text-xs bg-primary py-1 hover:bg-secondary px-3 text-white"
                                    onClick={() => removeItemFromCart(id)}
                                  >
                                    REMOVE ITEM
                                  </button>
                                </div>
                                <div>
                                  {!is_deleverable && (
                                    <div className="flex justify-end">
                                      <img
                                        src={deliveryMan}
                                        alt=""
                                        className="w-10"
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}

                  {/* loading animation  */}
                  {loading && (
                    <div className=" w-full grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6">
                      {animation}
                    </div>
                  )}

                  {menuLists.length === 0 && !loading && (
                    <div className="text-center">No items found</div>
                  )}

                  {menuLists.length > 0 && (
                    <div className="flex justify-center space-x-3 items-center">
                      <div>
                        <button
                          className="px-5 py-2 text-white focus:outline-none cursor-pointer bg-primary hover:bg-secondary"
                          onClick={() => {
                            setError("");
                            setPopupForm(true);
                          }}
                        >
                          Checkout
                        </button>
                      </div>
                    </div>
                  )}
                  {nonDeliverable.length > 0 && (
                    <div className="flex text-primary items-center space-x-1 justify-center w-full mt-5">
                      <div className="animate-bounce">
                        <ErrorOutlineOutlinedIcon />
                      </div>
                      <div>
                        You have added {nonDeliverable.length} not deliverable
                        items in cart!
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
