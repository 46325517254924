import { useState, useEffect, useRef } from "react";
import { baseUrl } from "../authentication/authorization";
import Processing from "../common/processing";

const PopupForm = ({
  error,
  handleClose,
  handleClick,
  subtotal,
  tax,
  nonDeliverable,
}) => {
  const taxAmount = (tax * subtotal) / 100;
  const [saveLoader, setSaveLoader] = useState(false);
  const [data, setData] = useState({
    pick_up: "",
    delivery_range: "",
    additional_note: "",
    spicy: "",
    deliveryCharge: "",
    sauce: "",
  });
  const [pickupDeliveryErr, setPickupDeliveryErr] = useState("");
  const [deliveries, setDeliveries] = useState([]);
  const [spicyLevels, setSpicyLevels] = useState([]);
  const [sauces, setSauces] = useState([]);
  const sauceRef = useRef();
  const [addressObj, setAddressObj] = useState({
    address: "",
    billingAddress: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    errors: {
      address: "",
      billingAddress: "",
      street: "",
      city: "",
      state: "",
      zip: "",
    },
  });
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    async function getDeliveries() {
      await fetch(`${baseUrl}/delivery-range`, {
        method: "GET",
        signal,
      })
        .then((response) => response.json())
        .then((data) => {
          if (Array.isArray(data) && data.length > 0) {
            data = data.filter((d) => d.is_displayed);
            setDeliveries(data);
            setData((d) => ({
              ...d,
              delivery_range: data[0].id,
              deliveryCharge: data[0].price,
            }));
          }
        })
        .catch(() => null);
    }
    async function getSpicyLevels() {
      await fetch(`${baseUrl}/spicylevel/`, {
        method: "GET",
        signal,
      })
        .then((response) => response.json())
        .then((data) => {
          if (Array.isArray(data) && data.length > 0) {
            setSpicyLevels(data);
            setData((d) => ({
              ...d,
              spicy: data[0].slug,
            }));
          }
        })
        .catch(() => null);
    }
    async function getSauces() {
      await fetch(`${baseUrl}/sauce/`, {
        method: "GET",
        signal,
      })
        .then((response) => response.json())
        .then((data) => {
          if (Array.isArray(data) && data.length > 0) {
            data = data.filter((d) => d.is_displayed);
            setSauces(data);
          }
        })
        .catch(() => null);
    }

    getSpicyLevels();
    getDeliveries();
    getSauces();
    return () => controller.abort();
  }, []);
  const handleErrors = (property, value) => {
    const { errors } = addressObj;
    value = value === undefined ? addressObj[property] : value;
    let result;
    if (value.trim() === "") {
      errors[property] = `${property[0].toUpperCase()}${property.slice(
        1,
        property.length
      )} cannot be left empty`;
      result = false;
    } else {
      errors[property] = "";
      result = true;
    }

    setAddressObj({
      ...addressObj,
      errors,
    });
    return result;
  };
  const handleChange = ({ target: { value } }, property) => {
    handleErrors(property, value);
    setAddressObj({
      ...addressObj,
      [property]: value,
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!saveLoader) {
      const addressArr = [
        "address",
        "billingAddress",
        "street",
        "city",
        "state",
        "zip",
      ];
      let goAhead;
      for (let i = 0; i < addressArr.length; i++) {
        const result = handleErrors(addressArr[i]);
        if (goAhead !== false) {
          goAhead = result;
        }
      }
      if (pick_up === "") {
        setPickupDeliveryErr("You must choose either pickup or delivery");
        goAhead = false;
      } else {
        setPickupDeliveryErr("");
      }

      if (goAhead) {
        setSaveLoader(true);

        const selectedSauces = [];
        const checkboxFields = sauceRef.current.getElementsByTagName("input");
        for (let i = 0; i < sauces.length; i++) {
          let checked = checkboxFields[i].checked;
          if (checked) {
            selectedSauces.push(sauces[i].name);
          }
        }

        const { spicy, pick_up, delivery_range, additional_note } = data;

        const address1 = { ...addressObj };
        delete address1.errors;

        const sendingData = {
          address: address1,
          spicy,
          pick_up,
          delivery_range: pick_up ? "" : delivery_range,
          additional_note,
          sauce: selectedSauces,
        };
        const result = await handleClick(sendingData);
        if (result === false) {
          setSaveLoader(false);
        }
      }
    }
  };
  useEffect(() => {
    if (error) {
      setSaveLoader(false);
    }
  }, [error]);
  const { address, billingAddress, street, city, state, zip, errors } =
    addressObj;

  const { pick_up, delivery_range, deliveryCharge, spicy, additional_note } =
    data;

  const {
    address: addressErr,
    billingAddress: billingAddressErr,
    street: streetErr,
    city: cityErr,
    state: stateErr,
    zip: zipErr,
  } = errors;

  const commonClass =
    "text-sm border border-gray-400 focus:border-gray-500 focus:outline-none block w-full p-2 w-80";
  return (
    <div>
      <div className=" max-w-3xl mx-auto bg-white px-5 sm:px-10 md:px-8 py-10 relative overflow-x-hidden overflow-y-auto">
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1  sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* header  */}
            <div className="sm:col-span-2 lg:col-span-3">
              <div className="mx-auto text-3xl font-semibold text-center">
                Customer Checkout Form
              </div>
            </div>
            {/* address  */}
            <div className="w-full space-y-1">
              <label htmlFor="" className="text-gray-600 text-sm">
                Address
              </label>
              <input
                type="text"
                className={commonClass}
                value={address}
                onChange={(event) => handleChange(event, "address")}
              />
              {addressErr && <div className="error">{addressErr}</div>}
            </div>
            {/* billing address  */}
            <div className="space-y-1">
              <label htmlFor="" className="text-gray-600 text-sm">
                Billing Address
              </label>
              <input
                type="text"
                className={commonClass}
                value={billingAddress}
                onChange={(event) => handleChange(event, "billingAddress")}
              />
              {billingAddressErr && (
                <div className="error">{billingAddressErr}</div>
              )}
            </div>
            {/* street  */}
            <div className="space-y-1">
              <label htmlFor="" className="text-gray-600 text-sm">
                Street/Apartment
              </label>
              <input
                type="text"
                className={commonClass}
                value={street}
                onChange={(event) => handleChange(event, "street")}
              />
              {streetErr && <div className="error">{streetErr}</div>}
            </div>
            {/* city  */}
            <div className="space-y-1">
              <label htmlFor="" className="text-gray-600 text-sm">
                City
              </label>
              <input
                type="text"
                className={commonClass}
                value={city}
                onChange={(event) => handleChange(event, "city")}
              />
              {cityErr && <div className="error">{cityErr}</div>}
            </div>
            {/* state  */}
            <div className="space-y-1">
              <label htmlFor="" className="text-gray-600 text-sm">
                State
              </label>
              <input
                type="text"
                className={commonClass}
                value={state}
                onChange={(event) => handleChange(event, "state")}
              />
              {stateErr && <div className="error">{stateErr}</div>}
            </div>
            {/* zipcode  */}
            <div className="space-y-1">
              <label htmlFor="" className="text-gray-600 text-sm">
                ZIP code
              </label>
              <input
                type="text"
                className={commonClass}
                value={zip}
                onChange={(event) => handleChange(event, "zip")}
              />
              {zipErr && <div className="error">{zipErr}</div>}
            </div>

            {/* spicy level  */}
            <div className="space-y-1">
              <label htmlFor="" className="text-gray-600 text-sm">
                Spicy level
              </label>
              <select
                value={spicy}
                className={commonClass}
                onChange={(event) =>
                  setData({
                    ...data,
                    spicy: event.target.value,
                  })
                }
              >
                {spicyLevels.map((spicy, index) => (
                  <option key={index} value={spicy.name}>
                    {spicy.name}
                  </option>
                ))}
              </select>
            </div>
            {/* Sauce  */}
            <div className="space-y-1">
              <label htmlFor="" className="text-gray-600 text-sm">
                Sauce
              </label>
              <div className="h-40 overflow-y-auto border border-gray-400">
                <div
                  className="focus:outline-none block w-full overflow-y-auto my-1"
                  ref={sauceRef}
                >
                  {sauces.map((sauce, index) => {
                    const { name, slug } = sauce;
                    return (
                      <div
                        key={index}
                        className="space-x-2 items-start flex px-2 text-sm"
                      >
                        <div>
                          <input id={slug} type="checkbox" />
                        </div>
                        <label htmlFor={slug}>{name}</label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            {/* pick up  */}
            {pick_up !== "" && !pick_up && (
              <div className="space-y-1">
                <label htmlFor="" className="text-gray-600 text-sm">
                  Delivery Range (in miles)
                </label>
                <select
                  value={delivery_range}
                  className={commonClass}
                  onChange={(event) => {
                    let charge;
                    for (let i = 0; i < deliveries.length; i++) {
                      if (deliveries[i].id === +event.target.value) {
                        charge = deliveries[i].price;
                        i = deliveries.length;
                      }
                    }
                    if (charge !== undefined) {
                      setData({
                        ...data,
                        delivery_range: +event.target.value,
                        deliveryCharge: charge,
                      });
                    }
                  }}
                >
                  {deliveries.map((range, index) => {
                    const { ranges, id } = range;
                    return (
                      <option key={index} value={id}>
                        {ranges}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
          </div>

          {/* pick_up */}
          <div className="flex flex-col space-y-1 mt-2">
            <div className="flex space-x-5">
              <div className="flex items-center">
                <input
                  type="radio"
                  id="pick_up"
                  checked={pick_up}
                  onChange={(event) => {
                    setPickupDeliveryErr("");
                    setData({
                      ...data,
                      pick_up: event.target.checked,
                    });
                  }}
                />
                <label htmlFor="pick_up" className="pl-3">
                  Pickup
                </label>
              </div>
              {nonDeliverable.length === 0 ? (
                <div className="flex items-center space-x-3">
                  <input
                    type="radio"
                    id="delivery"
                    checked={pick_up !== "" ? !pick_up : false}
                    onChange={(event) => {
                      setPickupDeliveryErr("");
                      setData({
                        ...data,
                        pick_up: !event.target.checked,
                      });
                    }}
                  />
                  <label htmlFor="delivery">Delivery</label>
                </div>
              ) : (
                <div className="text-primary">
                  You have added {nonDeliverable.length} not deliverable items
                  in cart!
                </div>
              )}
            </div>
            {pickupDeliveryErr && (
              <div className="error">{pickupDeliveryErr}</div>
            )}
          </div>

          <div className="space-y-2 pt-4">
            <div className="w-full space-y-1">
              <label htmlFor="" className="text-gray-600 text-sm">
                Additional note:
              </label>
              <textarea
                rows="4"
                placeholder="Want to say something ?"
                value={additional_note}
                className={commonClass}
                onChange={(event) =>
                  setData({
                    ...data,
                    additional_note: event.target.value,
                  })
                }
              ></textarea>
            </div>
          </div>
          {error && (
            <div className="error mb-2">
              Something went wrong please try again, later
            </div>
          )}
          <div className="my-5  max-w-sm  w-full ml-auto text-black">
            <div>
              <div className="flex justify-between">
                <div className="text-gray-700">Subtotal</div>
                <div>$ {subtotal.toFixed(2)}</div>
              </div>
              <div className="flex justify-between">
                <div className="text-gray-700">Tax ({tax}%)</div>
                <div>$ {taxAmount.toFixed(2)}</div>
              </div>
              <div className="flex justify-between ">
                <div className="text-gray-700">Total</div>
                <div>$ {(taxAmount + subtotal).toFixed(2)}</div>
              </div>
              {!pick_up && pick_up !== "" && (
                <div className="flex justify-between">
                  <div className="text-gray-700">Delivery Charge</div>
                  <div>$ {deliveryCharge}</div>
                </div>
              )}

              <div className="flex justify-between font-semibold">
                <div className="text-gray-700">Grand Total</div>
                <div>
                  ${" "}
                  {(
                    +taxAmount.toFixed(2) +
                    +subtotal.toFixed(2) +
                    +(!pick_up && pick_up !== "" ? +deliveryCharge : 0).toFixed(
                      2
                    )
                  ).toFixed(2)}
                </div>
              </div>
            </div>
          </div>

          <div className="flex space-x-4 my-5  max-w-sm  w-full ml-auto">
            <div
              onClick={handleClose}
              className="rounded-full px-5 py-2 text-white focus:outline-none bg-red-600 text-center cursor-pointer"
            >
              Cancel
            </div>
            <button
              className={`flex space-x-2 items-center justify-center rounded-full px-5 py-2 text-white focus:outline-none ${
                saveLoader
                  ? "bg-green-600 hover:bg-none cursor-default"
                  : "cursor-pointer bg-green-600 hover:bg-green-700"
              }`}
            >
              <div>Continue</div>
              {saveLoader && <Processing />}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PopupForm;
