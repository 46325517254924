import React from "react";

function FoodItemDetails({ item, description, price, image }) {
  return (
    <div className="flex flex-col  text-center">
      <div className="flex justify-center pb-2">
        <img
          src={image}
          alt=""
          className="h-28 w-28 object-center object-cover rounded-full"
        />
      </div>
      <div className=" font-bold py-1 text-xl">{item}</div>
      {description && (
        <div className="text-sm text-gray-700">
          {description.slice(0, 20)}
          {description.length > 30 && "..."}
        </div>
      )}
      <div className="flex space-x-4 justify-center items-center">
        <div className=" text-sm text-gray-600"> Price</div>
        <div className="font-semibold text-sm">$ {price}</div>
      </div>
    </div>
  );
}

export default FoodItemDetails;
