import { useEffect, useState } from "react";
import { baseUrl } from "../authentication/authorization";
import Slider from "./slider";

function BannerButton() {
  const [isClicked, setIsclicked] = useState(false);
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    fetch(`${baseUrl}/site/front-banner-list/`, {
      method: "GET",
      signal,
    })
      .then((response) => response.json())
      .then((dta) => {
        if (Array.isArray(dta)) {
          dta = dta.filter((d) => d.status);
          setSlides(dta);
        }
      })
      .catch(() => null);

    return () => controller.abort();
  }, []);

  return (
    <div
      className={`${
        !isClicked
          ? "fixed top-1/2 right-0 z-40"
          : "fixed w-full h-screen top-0 z-40 bg-black bg-opacity-40"
      }`}
    >
      <div className="w-full h-full flex flex-col justify-center items-center relative mt-6">
        {isClicked && slides.length > 0 && (
          <Slider images={slides} setIsclicked={setIsclicked} />
        )}
        {!isClicked && Array.isArray(slides) && slides.length > 0 && (
          <div
            className="absolute transition-all duration-150 ease-linear bg-primary right-0 text-white p-3 rounded-l-full cursor-pointer "
            onClick={() => {
              setIsclicked(!isClicked);
            }}
          >
            <div className="flex space-x-2 items-center offer-icon pl-2">
              <div className="font-semibold">Offer</div>
              <svg
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 433.961 433.961"
                style={{ enableBackground: "new 0 0 433.961 433.961" }}
                className="w-8 h-8"
                fill="white"
              >
                <g>
                  <path
                    d="M166.362,164.256c-6.652-5.561-16.232-4.853-21.848,1.012c-1.426,0.898-2.81,1.911-3.934,3.273
		c-5.876,7.121-4.098,18.236,2.754,24.053C161.142,207.712,184.21,179.175,166.362,164.256z"
                  />
                  <path
                    d="M271.025,230.208c-1.112,0.154-2.158,0.503-3.204,0.849c-0.345,0.061-0.678,0.178-1.023,0.271
		c-0.027,0.012-0.058,0.019-0.085,0.03c-3.777,1.057-7.353,3.951-9.358,7.973c-0.009,0.015-0.004,0.031-0.014,0.046
		c-2.994,4.971-3.666,11.197-0.396,16.869c0.174,0.303,0.436,0.512,0.628,0.8c2.211,4.721,5.922,8.759,11.006,10.641
		c11.87,4.391,25.442-3.255,26.639-16.189C296.44,238.324,283.46,228.489,271.025,230.208z"
                  />
                  <path
                    d="M430.9,186.517c2.399-3.358,0.387-7.897-2.609-9.952c-11.774-8.072-29.615-17.682-45.254-20.936
		c10.21-10.754,18.758-24.241,27.518-35.667c2.901-3.783-1.039-9.529-5.374-9.361c-7.976,0.308-15.957,0.555-23.928,0.966
		c-7.531,0.388-15.91,1.977-23.346,0.517c-0.565-0.111-1.076-0.044-1.604-0.034c4.512-11.851,8.505-23.941,12.606-35.932
		c2.102-6.15,5.733-15.511-2.468-19.109c-7.698-3.38-22.116,3.728-29.471,5.893c-6.136,1.807-12.277,3.854-18.369,6.014
		c-1.033,0.27-2.062,0.541-3.093,0.811c1.507-15.139,2.098-30.348,1.211-45.567c-0.25-4.286-5.269-8.345-9.387-5.388
		c-13.89,9.982-27.862,20.2-41.029,31.194c-4.819-15.143-10.132-30.177-15.387-45.132c-1.409-4.009-7.516-6.98-10.724-2.832
		c-7.972,10.301-18.38,21.214-25.433,32.914c-8.332-11.934-19.085-22.988-28.485-32.77c-3.634-3.779-9.89-1.778-11.345,2.994
		c-5.059,16.608-11.918,32.259-18.984,48.036c-3.485-2.927-7.035-5.804-10.306-8.796c-8.032-7.345-15.794-14.976-23.454-22.706
		c-2.621-2.644-8.326-2.535-10.008,1.289c-4.672,10.64-3.177,23.02-2.341,34.378c0.316,4.304,0.347,10.025,1.039,15.513
		c-12.926-4.998-26.006-9.678-38.888-14.562c-5.05-1.913-9.367,3.704-8.273,8.271c3.805,15.862,7.198,32.156,12.651,47.613
		c-15.207-1.118-30.225-2.25-45.341-5.328c-5.315-1.082-10.795,4.547-7.478,9.724c7.921,12.349,16.566,25.532,26.627,36.982
		c-4.399,1.662-8.693,3.745-12.882,5.568c-10.779,4.691-21.31,9.882-31.66,15.454c-5.179,2.789-4.494,9.961,0,12.908
		c11.405,7.479,22.445,15.473,33.52,23.415c-11.231,8.982-22.236,18.229-33.836,26.758c-4.641,3.413-4.228,10.421,1.608,12.314
		c9.106,2.954,18.157,6.066,27.114,9.445c5.038,1.9,10.075,4.268,15.183,6.299c-9.091,12.469-16.989,25.883-25.675,38.614
		c-3.046,4.461,1.014,10.471,6.011,10.477c15.011,0.019,29.991,0.519,44.978,1.016c-3.381,13.788-6.416,27.669-9.892,41.437
		c-1.206,4.765,3.391,10.561,8.597,8.6c9.866-3.718,19.732-7.434,29.6-11.152c2.839-1.07,6.697-2.025,10.368-3.314
		c-2.31,16.337-1.896,33.52-3.342,49.779c-0.392,4.427,4.543,7.384,8.401,6.399c9.607-2.456,16.264-9.595,24.233-15.133
		c5.307-3.686,11.561-6.567,17.194-10.063c3.578,14.977,9.709,29.851,13.639,44.552c1.682,6.304,9.598,6.486,12.984,1.697
		c9.118-12.901,19.155-26.031,27.128-39.795c2.138,3.431,4.443,6.775,6.61,10.11c5.64,8.676,11.302,17.356,17.198,25.86
		c2.721,3.927,9.602,5.099,12.045,0c6.074-12.683,11.712-25.714,16.999-38.841c3.311,3.661,6.732,7.138,10.06,10.1
		c7.567,6.736,20.669,19.405,31.482,16.334c1.927-0.546,2.986-2.036,3.629-3.699c0.714-0.788,1.269-1.675,1.433-2.687
		c2.867-17.58,5.188-35.734,5.017-53.647c5.187,2.663,10.764,4.804,16.012,6.875c9.934,3.921,19.915,7.722,29.874,11.575
		c4.601,1.781,10.114-3.71,8.32-8.32c-3.65-9.371-7.337-18.677-10.317-28.292c-1.653-5.33-2.926-10.856-4.501-16.253l-0.34-4.626
		c15.323,1.623,31.489,0.63,46.574,0.85c5.202,0.076,9.073-6.145,6.123-10.67c-7.596-11.662-14.967-25.865-24.271-37.151
		c4.303-1.296,8.542-3.035,12.66-4.434c8.951-3.043,17.819-6.288,26.692-9.547c5.838-2.145,7.604-10.158,1.778-13.607
		c-11.776-6.974-22.718-14.758-33.598-22.785C406.765,209.243,421.629,199.489,430.9,186.517z M139.49,223.502
		c-3.415-1.653-6.861-3.819-10.361-6.933c-25.321-22.546-22.803-67.639,11.554-79.399c31.94-10.934,68.499,10.058,61.131,47.067
		C195.664,215.121,166.665,224.006,139.49,223.502z M182.891,293.879c-7.434-0.236-14.949,0.287-22.27,0.892
		c-4.311,0.356-8.894,0.662-13.415,1.258c40.837-51.728,72.055-108.062,105.152-164.243c6.096-0.35,12.175-0.884,18.276-1.585
		C234.957,179.681,205.364,238.656,182.891,293.879z M273.054,294.853c-10.969,0.817-20.664-0.961-28.362-5.711
		c-18.271-18.604-22.789-48.865-3.368-70.01c21.879-23.824,61.167-18.279,75.303,10.661
		C331.913,261.085,304.676,292.496,273.054,294.853z"
                  />
                  <path
                    style={{
                      stroke: "#E71D73",
                      strokWidth: 0,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                    }}
                    d="M269.77,372.764
		c0.058,0,0.058-0.091,0-0.091"
                  />
                </g>
              </svg>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default BannerButton;
