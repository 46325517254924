import MenuIcon from "@material-ui/icons/Menu";
import "../../../assets/css/dashboard.css";
import { handleLogout } from "../../authentication/authorization";

function NavBar({ width, toggled, setToggled }) {
  return (
    <div className=" bg-white z-30 w-full py-2  border-b transition-all duration-300 ease-in-out sticky top-0">
      {toggled && width < 1023 && (
        <div
          className="fixed bg-black bg-opacity-50 h-screen w-full left-0 top-0"
          onClick={setToggled}
        ></div>
      )}

      <div className="w-full flex  items-center px-5">
        <div className="flex-1 flex items-center justify-end h-10">
          <div
            className=" cursor-pointer text-gray-600 flex lg:hidden "
            onClick={setToggled}
          >
            <MenuIcon />
          </div>
        </div>
        {/* notification sessions  */}
        <div className="lg:flex space-x-4 text-gray-600 items-center relative hidden">
          {/* user  */}
          <div className="user py-3 cursor-pointer">
            <svg
              className="w-6 h-6 "
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
          </div>
          <div className="absolute  top-6 -right-4 hide pt-5 z-20">
            <div
              onClick={handleLogout}
              className="py-2 bg-gray-100 px-3 transform transition-all duration-300 hover:-translate-y-1 hover:scale-110 cursor-pointer shadow hover:bg-gray-200"
            >
              Logout
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
