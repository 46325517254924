import { useState } from "react";
import { baseUrl } from "./authorization";
import Processing from "../common/processing";
import backgroundImage from "../../assets/images/authentication/login-background.jpg";

export default function ForgotPassword() {
  const [saveLoader, setSaveLoader] = useState(false);

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleErrors = (event) => {
    let value = event ? event.target.value : email;
    setEmail(value);
    if (value === "") {
      setError("Email cannot be left empty");
      return false;
    } else {
      if (!value.match(/^\w+@\w+\.\w+(\.\w+)?$/gi)) {
        setError("Invalid email");
        return false;
      } else {
        setError("");
        return true;
      }
    }
  };

  const handleResetPassword = (event) => {
    event.preventDefault();
    if (!saveLoader) {
      if (handleErrors()) {
        setSaveLoader(true);
        const formData = new FormData();
        formData.append("email", email);

        fetch(`${baseUrl}/user/password-reset/`, {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            const { success, error } = data;
            if (error) {
              setError(error);
              setSuccess("");
            } else if (success) {
              setError("");
              setSuccess(success);
            }
            setSaveLoader(false);
          })
          .catch(() => {
            setError("We could not connect to the server");
            setSuccess("");
            setSaveLoader(false);
          });
      }
    }
  };

  return (
    <div style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="w-full h-full grid place-items-center min-h-screen py-16">
        <form
          onSubmit={handleResetPassword}
          className="bg-white px-10 py-8 mx-5 max-w-sm w-full authentication-form"
          autoComplete="off"
        >
          <div className="space-y-5">
            <div className="space-y-1">
              <label htmlFor="email">Email address</label>
              <input
                type="text"
                id="email"
                value={email}
                onChange={(event) => handleErrors(event)}
              />
              {error && <div className="error">{error}</div>}
              {success && (
                <div className="text-green-600 text-sm">
                  We have sent you a mail about changing your password
                </div>
              )}
            </div>
            <div className="button-animation" style={{ display: "block" }}>
              <button className="animation-text text-center px-5 py-3 w-full">
                <div className="flex justify-center space-x-2 items-center">
                  <div>
                    {saveLoader ? "Sending" : "Send"} verification email
                  </div>
                  {saveLoader && <Processing />}
                </div>
              </button>
              <div className="animation-bg"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
