import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { baseUrl, headers } from "../../authentication/authorization";
import PopupMessage from "../../common/popup-message";
import Processing from "../../common/processing";

function AddProduct() {
  const selectedImageName = useRef();
  let slug = window.location.pathname.split("/")[3];
  let history = useHistory();
  const [saveLoader, setSaveLoader] = useState(false);
  const [alert, setAlert] = useState({
    popup: false,
    message: "",
  });
  const isAvailable = useRef();
  const isStock = useRef();
  const [error, setError] = useState("");
  const [Producttype, setProductType] = useState([]);
  const [productImage, setProductImage] = useState("");

  const [singleProductInfo, setSingleProductInfo] = useState({
    item: "",
    category: "",
    description: "",
    img: "",
    price: "",
    prepare_time: "",
    is_deleverable: true,
    special: false,
  });

  const [productInfo, setProductInfo] = useState({
    item: "",
    category: "",
    description: "",
    img: "",
    price: "",
    prepare_time: "",
    is_deleverable: true,
    special: false,
  });

  const handleProductInfoChange = ({ target: { name, value } }) => {
    setProductInfo({
      ...productInfo,
      [name]: value,
    });
  };

  const handleProductAdd = (event) => {
    event.preventDefault();
    // update
    if (slug) {
      setSaveLoader(true);
      const newProductInfo = { ...productInfo };
      let { item, category, description, price, prepare_time, is_deleverable } =
        newProductInfo;
      const formData = new FormData();
      productImage && formData.append("img", productImage);
      item !== singleProductInfo.item && formData.append("item", item);
      description !== singleProductInfo.description &&
        formData.append("description", description);
      category !== singleProductInfo.category &&
        formData.append("category", category);
      price !== singleProductInfo.price && formData.append("price", price);
      prepare_time !== singleProductInfo.prepare_time &&
        formData.append("prepare_time", prepare_time);
      is_deleverable !== singleProductInfo.is_deleverable &&
        formData.append("is_deleverable", is_deleverable);
      formData.append("special", special);
      formData.append("is_displayed", isAvailable.current.checked);
      formData.append("out_of_stock", isStock.current.checked);
      fetch(`${baseUrl}/menu-update/${slug}`, {
        method: "PATCH",
        body: formData,
        headers: headers(),
      })
        .then((response) => {
          if (response.ok) {
            setSaveLoader(false);
          }
          return response.json();
        })
        .then((data) => {
          const { code } = data;
          if (code === "token_not_valid") {
            localStorage.clear();
            window.location = "/";
          } else {
            setAlert({
              popup: true,
              message: "Successfully edited the product",
            });
            selectedImageName.current.innerHTML = "Select Image";
          }
          setSaveLoader(false);
        })
        .catch(() => setSaveLoader(false));
    }
    // add
    else {
      setSaveLoader(true);
      const newProductInfo = { ...productInfo };
      let {
        item,
        category,
        description,
        price,
        prepare_time,
        is_deleverable,
        special,
      } = newProductInfo;

      const formData = new FormData();
      formData.append("img", productImage);
      formData.append("item", item);
      formData.append("description", description);
      formData.append("category", category);
      formData.append("price", price);
      formData.append("prepare_time", prepare_time);
      formData.append("is_deleverable", is_deleverable);
      formData.append("special", special);
      formData.append("is_displayed", isAvailable.current.checked);
      formData.append("out_of_stock", isStock.current.checked);
      formData.append("is_displayed", isAvailable.current.checked);
      formData.append("out_of_stock", isStock.current.checked);
      fetch(`${baseUrl}/menu-add`, {
        method: "POST",
        body: formData,
        headers: headers(),
      })
        .then((res) => {
          if (res.ok) {
            setSaveLoader(false);
            setAlert({
              popup: true,
              message: "Successfully added the product",
            });
            setProductInfo({
              item: "",
              category: "",
              description: "",
              img: "",
              price: "",
              prepare_time: "",
              is_deleverable: false,
              special: false,
            });
            setProductImage("");

            selectedImageName.current.innerHTML = "Select Image";
          } else if (res.staus === 401) {
            localStorage.clear();
            window.location = "/login";
          }
          return res.json();
        })
        .then((data) => {
          if (data.message === "product already exist") {
            setAlert({
              popup: true,
              message: "Product already exists",
            });
          }
        })
        .catch(() => null);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    // fetch category
    async function getProductType() {
      await fetch(`${baseUrl}/category`, {
        method: "GET",
        signal,
      })
        .then((response) => response.json())
        .then((data) => {
          setProductType(data);
        })
        .catch(() => null);
    }
    getProductType();

    // fetch product list acc to slug
    async function getProductInfo() {
      await fetch(`${baseUrl}/menu-detail/${slug}`, {
        method: "GET",
        signal,
      })
        .then((response) => response.json())
        .then((data) => {
          isAvailable.current.checked = data.is_displayed;
          isStock.current.checked = data.out_of_stock;
          setProductInfo(data);
          setSingleProductInfo(data);
        })
        .catch(() => null);
    }
    slug && getProductInfo();

    return () => {
      controller.abort();
    };
  }, [slug]);

  const {
    item,
    category,
    description,
    price,
    prepare_time,
    is_deleverable,
    special,
  } = productInfo;
  const { popup, message } = alert;

  return (
    <>
      {popup !== false && (
        <PopupMessage
          message={message}
          handleClose={() => {
            setAlert({
              popup: false,
              message: "",
            });
            if (slug) {
              history.push("/profile/allproduct");
            }
          }}
        />
      )}
      <div className="flex p-8">
        <div className="w-full lg:flex space-x-6 space-y-6 lg:space-y-0">
          {/* right side  */}
          <div className=" w-full max-w-lg mx-auto">
            <div className="bg-white rounded-md shadow-md flex flex-col py-5 h-auto w-full">
              {/* header  */}
              <div className="px-10 border-b border-gray-300 pb-4 text-lg font-semibold uppercase  tracking-wide">
                ADD PRODUCT FORM
              </div>
              {/* form  */}
              <form
                className="flex flex-col px-10 py-4 space-y-4"
                onSubmit={handleProductAdd}
                encType="multipart/form-data"
              >
                {/* product name  */}
                <div className="flex flex-col space-y-1">
                  <label className="text-sm font-medium text-gray-700">
                    Product Name
                  </label>
                  <div>
                    <input
                      type="text"
                      value={item}
                      name="item"
                      onChange={handleProductInfoChange}
                      className="border w-full px-5 border-gray-300 focus:border-indigo-500 py-1.5 text-gray-700 text-sm"
                      required
                    />
                  </div>
                </div>
                {/* category  */}
                <div className="flex flex-col space-y-1">
                  <label className="text-sm font-medium text-gray-700">
                    Category
                  </label>
                  <div className="relative">
                    <select
                      name="category"
                      value={category}
                      onChange={handleProductInfoChange}
                      className="appearance-none bg-white border text-gray-700 w-full px-3 py-2 border-gray-300 focus:border-indigo-500 focus:outline-none text-sm "
                      required
                    >
                      <option value="" disabled>
                        ---Select Category---
                      </option>
                      {Producttype.map((product, index) => {
                        return (
                          <option value={product.id} key={index}>
                            {product.name}
                          </option>
                        );
                      })}
                    </select>
                    <svg
                      className="w-4 h-4  top-3 right-2 absolute text-gray-600"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                </div>
                {/* Price in Rs   */}
                <div className="flex space-x-4">
                  {/* full price  */}
                  <div className="flex-1 flex flex-col space-y-1">
                    <label className="text-sm font-medium text-gray-700">
                      Price (in dollar):
                    </label>
                    <div>
                      <input
                        type="number"
                        name="price"
                        value={price}
                        onChange={handleProductInfoChange}
                        className="border w-full px-5 border-gray-300 focus:border-indigo-500 py-1.5 text-gray-700 text-sm"
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* prepare_time in minute   */}
                <div className="flex space-x-4">
                  {/* prepare_time  */}
                  <div className="flex-1 flex flex-col space-y-1">
                    <label className="text-sm font-medium text-gray-700">
                      Preparation time (in minutes):
                    </label>
                    <div>
                      <input
                        type="number"
                        name="prepare_time"
                        value={prepare_time}
                        onChange={handleProductInfoChange}
                        className="border w-full px-5 border-gray-300 focus:border-indigo-500 py-1.5 text-gray-700 text-sm"
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* Discription  */}
                <div className="flex flex-col space-y-1">
                  <label className="text-sm font-medium text-gray-700">
                    Description
                  </label>
                  <div>
                    <textarea
                      value={description}
                      onChange={handleProductInfoChange}
                      name="description"
                      className="border w-full px-5 border-gray-300 focus:border-indigo-500 py-1.5 text-gray-700 text-sm h-20"
                    />
                  </div>
                </div>
                {/* image  */}
                <div className="flex flex-col space-y-2 relative ">
                  <label className="text-sm font-medium text-gray-700">
                    Select Product Image
                  </label>

                  <label
                    htmlFor="input-file"
                    className="bg-gray-50 p-2.5 w-full text-gray-500 text-sm overflow-hidden"
                    ref={selectedImageName}
                  >
                    Select Image
                  </label>
                  <input
                    type="file"
                    id="input-file"
                    className="hidden"
                    onChange={(event) => {
                      const file = event.target.files[0];
                      const fileName = file.name;
                      const image = new Image();
                      image.src = URL.createObjectURL(file);
                      image.onload = function () {
                        let arr = fileName.split(".");
                        let extension = arr[arr.length - 1];
                        const extensions = ["png", "jpg", "jpeg", "webp"];
                        let bool = false;
                        for (let i = 0; i < extensions.length; i++) {
                          if (extensions[i] === extension.toLowerCase()) {
                            bool = true;
                            i = extensions.length;
                          }
                        }
                        if (bool) {
                          setProductImage(file);
                          setError("");
                        } else {
                          setProductImage("");
                          setError("Invalid file");
                        }
                      };

                      image.onerror = function () {
                        setProductImage("");
                        setError("Invalid file");
                      };
                      selectedImageName.current.innerHTML = fileName;
                    }}
                  />
                  <label
                    htmlFor="input-file"
                    className="bg-gray-200 text-gray-600 py-2 px-4 absolute top-5 right-0 cursor-pointer"
                  >
                    Browse...
                  </label>
                  {error && <div className="text-red-500 text-sm">{error}</div>}
                </div>

                <div className="flex space-x-6">
                  {/* is deleverable */}
                  <div className="flex items-center space-x-3">
                    <input
                      type="checkbox"
                      id="is_deleverable"
                      checked={is_deleverable}
                      onChange={(event) =>
                        setProductInfo({
                          ...productInfo,
                          is_deleverable: event.target.checked,
                        })
                      }
                    />
                    <label htmlFor="is_deleverable">Deleverable</label>
                  </div>
                  {/* Special */}
                  <div className="flex items-center space-x-3">
                    <input
                      type="checkbox"
                      id="special"
                      checked={special}
                      onChange={(event) =>
                        setProductInfo({
                          ...productInfo,
                          special: event.target.checked,
                        })
                      }
                    />
                    <label htmlFor="special">Special</label>
                  </div>
                </div>
                <div className="flex space-x-6">
                  {/* displayed  */}
                  <div className="text-gray-700 space-x-1">
                    <input
                      type="checkbox"
                      ref={isAvailable}
                      id="is_available"
                    />{" "}
                    <label htmlFor="is_available">Display</label>
                  </div>
                  {/* Out of Stock ?  */}
                  <div className="text-gray-700 space-x-1">
                    <input type="checkbox" ref={isStock} id="is_available" />
                    <label htmlFor="is_available">Out of Stock</label>
                  </div>
                </div>
                {/* submit  */}
                <div className="">
                  <button
                    className="flex items-center  space-x-2 bg-indigo-500 text-white px-5 py-2 rounded-md shadow-md transition-all duration-300 ease-in-out hover:bg-indigo-600"
                    type="submit"
                  >
                    <div>
                      {slug && "Update"}
                      {!slug && (saveLoader ? "Saving" : "Save")}
                    </div>
                    {saveLoader && <Processing />}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddProduct;
