import Chart from "react-apexcharts";

export default function BarChart({ data, categories }) {
  const state = {
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories,
      },
    },
    series: [
      {
        name: "Product",
        data,
      },
    ],
  };

  return (
    <div className="app">
      <div className="text-center">Number of products ordered</div>
      <div className="row">
        <div className="mixed-chart">
          <Chart
            options={state.options}
            series={state.series}
            type="bar"
            width="90%"
            height="350"
          />
        </div>
      </div>
    </div>
  );
}
