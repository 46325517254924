import ProgressImage from "../../assets/images/about-us/breakfast-in-the-table.jpg";

export default function OurProgress({ ...all }) {
  const { number_of_chefs, happy_clients, total_clients, total_menu } = all;
  const progress = [
    {
      title: "Clients Served",
      number: total_clients || 0,
    },
    {
      title: "Available Menus",
      number: total_menu || 0,
    },
    {
      title: "Chefs",
      number: number_of_chefs || 0,
    },
    {
      title: "Happy clients",
      number: happy_clients || 0,
    },
  ];
  return (
    <div
      className="bg-fixed bg-cover bg-center"
      style={{ backgroundImage: `url(${ProgressImage})` }}
    >
      <div className="text-white">
        <div className="py-10 md:py-20 lg:py-32 px-4 md:px-10 lg:px-16 text-center max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-16">
          {/*{progress.map((p, index) => {
            const { title, number } = p;
            return (
              <div key={index}>
                <div className="text-3xl md:text-4xl lg:text-5xl mb-4 font-header">
                  <span className="bg-black bg-opacity-60 px-4">{number}</span>
                </div>
                <div className="text-xl text-gray-200">
                  <span className="bg-black bg-opacity-60 px-4">{title}</span>
                </div>
              </div>
            );
          })}*/}
        </div>
      </div>
    </div>
  );
}
