import { useState, useEffect } from "react";
import { baseUrl, headers } from "./authorization";
import Processing from "../common/processing";
import PopupMessage from "../common/popup-message";
import backgroundImage from "../../assets/images/authentication/login-background.jpg";
import logo from "../../assets/images/dashboard/logo.jpeg";

export default function PaymentSuccess(props) {
  const [alert, setAlert] = useState("");
  const [saveLoader, setSaveLoader] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    let sessionId, cartSession;
    try {
      sessionId = localStorage.getItem("sessionId");
      cartSession = localStorage.getItem("cart-session");
    } catch {
      localStorage.clear();
      window.location = "/";
    }

    function verifyEmail() {
      fetch(`${baseUrl}/complete-payment/`, {
        method: "POST",
        headers: {
          ...headers(),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sessionId,
          "cart-session": cartSession,
        }),
        signal,
      })
        .then((response) => {
          if (response.ok) {
            setSuccess("true");
            setError("");
            setAlert(
              "Successfully paid, We are now delivering your food items"
            );
          } else {
            setError("We could not connect to the server");
            setSuccess("");
            setSaveLoader(false);
          }
          return response.json();
        })
        .then((dta) => console.log(dta))
        .catch(() => {
          setError("We could not connect to the server");
          setSuccess("");
          setSaveLoader(false);
        });
    }
    verifyEmail();
    return () => controller.abort();
  }, []);

  return (
    <>
      {alert && (
        <PopupMessage
          message={alert}
          handleClose={() => {
            setAlert("");
            let refresh = localStorage.getItem("refresh");
            const access = localStorage.getItem("access");
            localStorage.clear();
            localStorage.setItem("refresh", refresh);
            localStorage.setItem("access", access);
            window.location = "/";
          }}
        />
      )}

      <div style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="bg-black bg-opacity-70 w-full h-full grid place-items-center min-h-screen py-16">
          <div className="bg-white px-10 py-8 mx-5 max-w-sm w-full authentication-form">
            <div className="flex flex-col pb-2 space-y-2">
              {/* resturant logo  */}
              <div className="w-20 h-20 mx-auto">
                <img
                  src={logo}
                  alt=""
                  className="w-full h-full rounded-full object-center object-cover"
                />
              </div>
              <div
                className={`${error && "bg-red-500"}  ${
                  !error && "bg-primary"
                } flex justify-center items-center space-x-2 px-5 py-2 w-full sm:w-auto rounded text-white`}
              >
                <div>
                  {error && error}

                  {!error && "Completing Payment"}
                </div>
                {saveLoader && <Processing />}
              </div>
              {!error && !success && (
                <div className="text-center">Please wait few seconds</div>
              )}
              {error && (
                <div>
                  Please contact the Momo World or try refreshing the page
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
