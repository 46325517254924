import React, { useState, useEffect } from "react";
import { baseUrl } from "../authentication/authorization";
import leaf from "../../assets/images/homepage/leaf.png";

function AboutComponent({ title1, story1, img1, id1 }) {
  const [aboutUs, setAboutUs] = useState({
    id: id1 || "",
    title: title1 || "",
    story: story1 || "",
    img: img1 || "",
  });

  const { id, title, story, img } = aboutUs;

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (!(id && id !== 0) || title || story || img) {
      fetch(`${baseUrl}/site/aboutus-list/`, {
        method: "GET",
        signal,
      })
        .then((response) => response.json())
        .then((dta) => {
          if (dta && Array.isArray(dta) && dta[0]) {
            setAboutUs({
              id: dta[0].id,
              title: dta[0].title,
              story: dta[0].story,
              img: dta[0].img,
            });
          }
        })
        .catch(() => null);
    }

    return () => controller.abort();
  }, [id, title, story, img]);

  if (id && id > 0) {
    return (
      <div className="space-y-4 md:space-y-0 md:space-x-4 py-10 md:py-16 lg:py-20 px-4 md:px-10 lg:px-16 text-center max-w-7xl mx-auto flex flex-col md:flex-row">
        <div className="flex-1">
          <div className="mb-5 lg:mb-10">
            <div className="text-xl md:text-2xl text-primary lg:mb-2">
              The Momo World
            </div>
            <div className="text-3xl md:text-4xl lg:text-5xl font-semibold">
              {title1 || title}
            </div>
            <div className="mt-4">
              <img src={leaf} alt="" className="mx-auto" />
            </div>
          </div>
          <div className="text-center text-gray-500">{story1 || story}</div>
        </div>
        <div className="flex-1">
          <img
            src={img1 || img}
            alt=""
            className="object-cover w-full h-full"
          />
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default AboutComponent;
