import React, { useState, useRef, useEffect } from "react";
import { baseUrl, headers } from "../../authentication/authorization";
import Processing from "../../common/processing";
import PopupMessage from "../../common/popup-message";
import DeleteConfirmationPopup from "../../common/delete-confirmation";

function Testimonials() {
  const testimonialImage = useRef();
  const testimonialForm = useRef();
  const [editId, setEditId] = useState("");
  const [alert, setAlert] = useState("");
  const [deletePopup, setDeletePopup] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [testimonial, setTestimonial] = useState({
    description: "",
    image: "",
    name: "",
    location: "",
  });
  const [testimonials, setTestimonials] = useState([]);

  const [errors, setErrors] = useState({
    name: "",
    description: "",
    image: "",
  });

  const handleErrors = (property, value) => {
    let result;
    value = value === undefined ? testimonial[property] : value;
    if (value.trim() === "") {
      errors[property] = `${property[0].toUpperCase()}${property.slice(
        1,
        property.length
      )} cannot be left empty`;
      result = false;
    } else {
      if (property === "name") {
        errors.name = "";
        result = true;
      } else if (property === "description") {
        errors.description = "";
        result = true;
      }
    }

    setErrors(errors);
    return result;
  };

  const handleChange = ({ target: { value } }, property) => {
    if (property !== "location") {
      handleErrors(property, value);
    }
    setTestimonial({
      ...testimonial,
      [property]: value,
    });
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (!saveLoader) {
      const { description, image, name, location } = testimonial;
      const credentials = ["name", "description"];
      let goAhead;
      for (let i = 0; i < credentials.length; i++) {
        const result = handleErrors(credentials[i]);
        if (goAhead !== false) {
          goAhead = result;
        }
      }

      if (goAhead) {
        setSaveLoader(true);
        const formData = new FormData();
        formData.append("description", description);
        formData.append("name", name);
        formData.append("location", location);
        image && formData.append("img", image);
        if (editId) {
          fetch(`${baseUrl}/site/testimonial-edit/${editId}`, {
            method: "PATCH",
            body: formData,
            headers: headers(),
          })
            .then((response) => {
              if (response.ok) {
                getTestimonials();
                setAlert("Successfully edited the testimonial");
              }
              setSaveLoader(false);
            })
            .catch(() => saveLoader(false));
        } else {
          fetch(`${baseUrl}/site/testimonial-add/`, {
            method: "POST",
            body: formData,
            headers: headers(),
          })
            .then((response) => {
              setSaveLoader(false);
              if (response.ok) {
                setAlert("Successfully added the testimonial");
                getTestimonials();
              } else {
                setAlert("We could not add the testimonial");
              }
            })
            .catch(() => setSaveLoader(false));
        }
      }
    }
  };

  const getTestimonials = async () => {
    await fetch(`${baseUrl}/site/testimonial-list/`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((dta) => {
        setTestimonial({
          description: "",
          image: "",
          name: "",
          location: "",
        });
        testimonialImage.current.innerHTML = "Select Image";
        setTestimonials(dta);
      });
  };

  const handleTestimonialDelete = (action) => {
    if (action === "delete") {
      fetch(`${baseUrl}/site/testimonial-edit/${deletePopup}`, {
        method: "DELETE",
        headers: headers(),
      })
        .then((response) => {
          if (response.ok) {
            getTestimonials();
          }
        })
        .catch(() => null);
    }
    setDeletePopup(false);
  };

  const handleEdit = (id, name, description, location, img) => {
    testimonialForm.current.scrollIntoView();
    setEditId(id);
    testimonialImage.current.innerHTML = img || "Select Image";
    setTestimonial({
      img: "",
      description: description,
      name,
      location,
    });
    setErrors({
      name: "",
      description: "",
      image: "",
    });
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const getTestimonials = async () => {
      await fetch(`${baseUrl}/site/testimonial-list/`, {
        method: "GET",
        signal,
      })
        .then((response) => response.json())
        .then((dta) => {
          setTestimonial({
            description: "",
            image: "",
            name: "",
            location: "",
          });
          testimonialImage.current.innerHTML = "Select Image";
          setTestimonials(dta);
        })
        .catch(() => null);
    };
    getTestimonials();

    return () => controller.abort();
  }, []);

  const { name, location, description } = testimonial;
  const {
    name: nameErr,
    description: descriptionErr,
    image: imageErr,
  } = errors;

  return (
    <>
      {deletePopup !== false && (
        <DeleteConfirmationPopup
          handleDelete={handleTestimonialDelete}
          message="Are you sure that you want to delete this testimonial ?"
        />
      )}
      {alert !== "" && (
        <PopupMessage message={alert} handleClose={() => setAlert("")} />
      )}
      <div className="px-5 py-10 w-full h-full overflow-auto">
        <form
          className="max-w-xl flex flex-col space-y-4 mx-auto bg-white p-5 shadow rounded"
          ref={testimonialForm}
        >
          <div className="mx-auto text-xl font-semibold">
            User Reviews Input Field
          </div>
          <div className="flex flex-col space-y-2">
            <label htmlFor="" className="text-gray-700 text-sm font-semibold">
              Name
            </label>
            <input
              type="text"
              className="border w-full  px-6 py-2 focus:outline-none focus:border-indigo-500"
              value={name}
              onChange={(event) => handleChange(event, "name")}
            />
            {nameErr && <div className="error">{nameErr}</div>}
          </div>
          <div className="flex flex-col space-y-2">
            <label htmlFor="" className="text-gray-700 text-sm font-semibold">
              Location
            </label>
            <input
              type="text"
              className="border w-full  px-6 py-2 focus:outline-none focus:border-indigo-500"
              value={location}
              onChange={(event) => handleChange(event, "location")}
            />
          </div>
          <div className="flex flex-col space-y-2">
            <label htmlFor="" className="text-gray-700 text-sm font-semibold">
              Description
            </label>
            <textarea
              className="border w-full h-24 p-3 focus:outline-none focus:border-indigo-500"
              value={description}
              onChange={(event) => handleChange(event, "description")}
            ></textarea>
            {descriptionErr && <div className="error">{descriptionErr}</div>}
          </div>
          <div className="flex flex-col space-y-2 relative ">
            <label className="text-gray-700 text-sm font-semibold">
              Reviewer Image
            </label>

            <label
              htmlFor="input-file"
              className="bg-gray-50  p-2.5 w-full text-gray-500 text-sm"
              ref={testimonialImage}
            >
              Select Image
            </label>
            <input
              type="file"
              id="input-file"
              className="border w-full hidden"
              onChange={(event) => {
                const file = event.target.files[0];
                const fileName = file.name;
                const image = new Image();
                image.src = URL.createObjectURL(file);
                image.onload = function () {
                  let arr = fileName.split(".");
                  let extension = arr[arr.length - 1];
                  const extensions = ["png", "jpg", "jpeg", "webp"];
                  let bool = false;
                  for (let i = 0; i < extensions.length; i++) {
                    if (extensions[i] === extension.toLowerCase()) {
                      bool = true;
                      i = extensions.length;
                    }
                  }
                  if (bool) {
                    setTestimonial({
                      ...testimonial,
                      image: file,
                    });
                    setErrors({
                      ...errors,
                      image: "",
                    });
                  } else {
                    setTestimonial({
                      ...testimonial,
                      image: "",
                    });
                    setErrors({
                      ...errors,
                      image: "Invalid file",
                    });
                  }
                };

                image.onerror = function () {
                  setTestimonial({
                    ...testimonial,
                    image: "",
                  });
                  setErrors({
                    ...errors,
                    image: "Invalid file",
                  });
                };
                testimonialImage.current.innerHTML = fileName;
              }}
            />
            <label
              htmlFor="input-file"
              className="bg-gray-200 py-2 px-4 absolute top-5 right-0 cursor-pointer"
            >
              Browse...
            </label>
            {imageErr && <div className="error">{imageErr}</div>}
          </div>
          {/* button  */}
          <div className="">
            <button
              className="bg-indigo-500 flex justify-center items-center space-x-2 px-5 py-2 w-full sm:w-auto rounded text-white hover:bg-indigo-600 focus:outline-none"
              onClick={submitForm}
            >
              <div>{saveLoader ? "Saving" : "Save"}</div>
              {saveLoader && <Processing />}
            </button>
          </div>
        </form>
        {/* current aboutus  */}
        {testimonials.length > 0 && (
          <div className="flex flex-col  mt-10 bg-white">
            <div className="flex flex-col py-10 md:py-16 lg:py-20 px-4 md:px-10 lg:px-16 max-w-7xl mx-auto">
              {/* header  */}
              <div className=" text-2xl font-semibold pl-4">User Reviews</div>
              <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 pt-16  pb-4">
                {testimonials.map((test, index) => {
                  const { id, name, description, location, img } = test;
                  return (
                    <div className="col-span-1" key={index}>
                      <div className="bg-gray-100 rounded-mg shadow-md px-5 py-6 relative w-full flex flex-col">
                        <div className="bg-white position p-2 rounded-full">
                          <img
                            src={img}
                            className="w-16 h-16 object-cover rounded-full"
                            alt=""
                          />
                        </div>
                        {/* edit delete  */}
                        <div className="flex space-x-1 absolute top-2 right-3">
                          {/* edit  */}
                          <button
                            onClick={() =>
                              handleEdit(id, name, description, location, img)
                            }
                            className="text-green-500 hover:bg-green-500 p-2 rounded-full transform transition-all duration-300 hover:scale-110  hover:text-white"
                          >
                            <svg
                              className="w-6 h-6"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                              <path
                                fillRule="evenodd"
                                d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>
                          {/* delete  */}
                          <button
                            onClick={() => setDeletePopup(id)}
                            className="text-red-600 hover:bg-red-600 p-2 rounded-full transform transition-all duration-300 hover:scale-110  hover:text-white"
                          >
                            <svg
                              className="w-6 h-6"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>
                        </div>
                        <div className="mt-12 text-center pb-5 text-gray-700">
                          {description}
                        </div>
                        <div className="flex space-x-2  items-center">
                          <div className="text-lg font-bold font-header text-primary w-1/2 flex justify-end">
                            {name}
                          </div>
                          <div className="text-gray-700 text-sm">
                            {location}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Testimonials;
