import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { baseUrl } from "./authorization";
import Processing from "../common/processing";
import PopupMessage from "../common/popup-message";
import backgroundImage from "../../assets/images/authentication/login-background.jpg";

export default function ResetPassword(props) {
  const [saveLoader, setSaveLoader] = useState(false);
  const [complete, setComplete] = useState(false);
  const [show, setShow] = useState(false);
  const uid = props.match.params.uid;
  const token = props.match.params.token;
  const [alert, setAlert] = useState("");
  const [data, setData] = useState({
    newPassword: "",
    confirmNewPassword: "",
    errors: {
      newPassword: "",
      confirmNewPassword: "",
    },
  });

  const handleErrors = (property, value) => {
    const { errors } = data;
    value = value === undefined ? data[property] : value;
    let result;
    let propertyName;
    if (property === "newPassword") {
      propertyName = "New Password";
    } else {
      propertyName = "Confirmation Password";
    }

    if (value.length < 8) {
      errors[property] = `${propertyName} must be at least 8 characters long`;
      result = false;
    } else {
      if (property === "newPassword") {
        errors.newPassword = "";
        result = true;
      } else if (property === "confirmNewPassword") {
        if (value !== data.newPassword) {
          errors.confirmNewPassword = "Passwords do not match";
          result = false;
        } else {
          errors.confirmNewPassword = "";
          result = true;
        }
      }
    }

    setData({
      ...data,
      errors,
    });
    return result;
  };

  const handleChange = ({ target: { value } }, property) => {
    handleErrors(property, value);
    setData({
      ...data,
      [property]: value,
    });
  };

  const handleResetPasswordSubmit = (event) => {
    event.preventDefault();
    const { newPassword } = data;
    if (!saveLoader) {
      const properties = ["newPassword", "confirmNewPassword"];
      let goAhead;
      for (let i = 0; i < properties.length; i++) {
        const result = handleErrors(properties[i]);
        if (goAhead !== false) {
          goAhead = result;
        }
      }
      if (goAhead) {
        setSaveLoader(true);
        const formData = new FormData();
        formData.append("password", newPassword);
        formData.append("uidb64", uid);
        formData.append("token", token);

        fetch(`${baseUrl}/user/password-reset-complete/`, {
          method: "PATCH",
          body: formData,
        })
          .then((response) => {
            if (response.ok) {
              setSaveLoader(false);
              setComplete(true);
            } else {
              const { errors } = data;
              errors.confirmNewPassword = "Something went wrong";
              setData({
                ...data,
                errors,
              });
            }
          })
          .catch(() => setSaveLoader(false));
      }
    }
  };

  useEffect(() => {
    if ((uid, token)) {
      fetch(`${baseUrl}/user/password-reset/${uid}/${token}`, {
        method: "GET",
      })
        .then((response) => {
          if (response.ok) {
            setShow(true);
          } else {
            window.location = "/";
          }
        })
        .catch(() => {
          window.location = "/";
        });
    } else {
      window.location = "/";
    }
  }, [uid, token]);

  const { newPassword, confirmNewPassword, errors } = data;
  const {
    newPassword: newPasswordErr,
    confirmNewPassword: confirmNewPasswordErr,
  } = errors;

  if (!show) {
    return null;
  }
  return (
    <>
      {alert !== "" && (
        <PopupMessage message={alert} handleClose={() => setAlert("")} />
      )}
      <div style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div
          className="flex justify-center h-screen items-center p-8 bg-black bg-opacity-40"
          style={{ minHeight: "320px" }}
        >
          <div className="w-full md:w-2/5">
            <div className="bg-white rounded-md shadow-md flex flex-col py-5 h-auto w-full">
              <div className="px-10 border-b border-gray-300 pb-4 text-lg font-semibold uppercase  tracking-wide">
                Reset Password
              </div>
              <form
                className="flex flex-col px-10 py-4 space-y-4"
                onSubmit={handleResetPasswordSubmit}
              >
                <div className="flex flex-col space-y-1">
                  <label className="text-sm font-medium text-gray-700">
                    New Password
                  </label>
                  <div>
                    <input
                      type="password"
                      className="border w-full px-5 border-gray-300 focus:border-indigo-500 py-1.5 text-gray-700 text-sm"
                      value={newPassword}
                      onChange={(event) => handleChange(event, "newPassword")}
                    />
                  </div>
                  {newPasswordErr && (
                    <div className="error">{newPasswordErr}</div>
                  )}
                </div>
                <div className="flex flex-col space-y-1">
                  <label className="text-sm font-medium text-gray-700">
                    Confirm New Password
                  </label>
                  <div>
                    <input
                      type="password"
                      className="border w-full px-5 border-gray-300 focus:border-indigo-500 py-1.5 text-gray-700 text-sm"
                      value={confirmNewPassword}
                      onChange={(event) =>
                        handleChange(event, "confirmNewPassword")
                      }
                    />
                    {confirmNewPasswordErr && (
                      <div className="error">{confirmNewPasswordErr}</div>
                    )}
                  </div>
                  {complete && (
                    <div className="text-green-500">
                      Successfully changed the password
                    </div>
                  )}
                </div>
                <div className="">
                  <button className="flex items-center  space-x-2 bg-primary text-white px-5 py-2 rounded-md shadow-md transition-all duration-300 ease-in-out">
                    <div>{saveLoader ? "Changing" : "Change"} Password</div>
                    {saveLoader && <Processing />}
                  </button>
                </div>
                {complete && (
                  <Link to="/login" className="text-center text-sm underline">
                    Go login page
                  </Link>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
