import { useState, useEffect } from "react";
import ReactToExcel from "react-html-table-to-excel";
import { baseUrl, headers } from "../../authentication/authorization";
import Processing from "../../common/processing";

function Users() {
  const [users, setUsers] = useState([]);
  const [saveLoader, setSaveLoader] = useState(false);
  const [next, setNext] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    setLoading(true);
    const fetchuser = async () => {
      await fetch(`${baseUrl}/user/profile`, {
        method: "GET",
        headers: headers(),
        signal,
      })
        .then((response) => response.json())
        .then((dta) => {
          const { next, results } = dta;
          if (Array.isArray(results)) {
            results.forEach((r) => {
              r.saving = false;
              r.activeSaving = false;
            });
            setUsers(results);
            setNext(next);
            setLoading(false);
          }
        })
        .catch(() => null);
    };
    fetchuser();

    return () => controller.abort();
  }, []);

  const loadMoreUsers = () => {
    if (!saveLoader) {
      setSaveLoader(true);
      fetch(next, {
        method: "GET",
        headers: headers(),
      })
        .then((response) => response.json())
        .then((dta) => {
          const { next, results } = dta;
          if (Array.isArray(results)) {
            results.forEach((r) => {
              r.saving = false;
              r.activeSaving = false;
            });
            setUsers([...users, ...results]);
            setNext(next);
          }
          setSaveLoader(false);
        })
        .catch(() => setSaveLoader(false));
    }
  };

  const makeUserLoyal = (id, index) => {
    const results1 = [...users];
    results1[index].saving = true;
    setUsers(results1);
    const formData = new FormData();
    formData.append("id", id);
    fetch(`${baseUrl}/user/loyal/`, {
      method: "POST",
      headers: headers(),
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          const res = [...users];
          res[index].saving = false;
          res[index].loyal = true;
          setUsers(res);
        } else {
          results1[index].saving = false;
          setUsers(results1);
        }
      })
      .catch(() => {
        results1[index].saving = false;
        setUsers(results1);
      });
  };

  const enableUser = (id, index) => {
    const results1 = [...users];
    results1[index].activeSaving = true;
    setUsers(results1);
    const formData = new FormData();
    formData.append("id", id);
    fetch(`${baseUrl}/user/user-active/`, {
      method: "POST",
      headers: headers(),
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          const res = [...users];
          res[index].activeSaving = false;
          res[index].is_active = true;
          setUsers(res);
        } else {
          results1[index].activeSaving = false;
          setUsers(results1);
        }
      })
      .catch(() => {
        results1[index].activeSaving = false;
        setUsers(results1);
      });
  };

  const disableUser = (id, index) => {
    const results1 = [...users];
    results1[index].activeSaving = true;
    setUsers(results1);
    const formData = new FormData();
    formData.append("id", id);
    fetch(`${baseUrl}/user/user-deactive/`, {
      method: "POST",
      headers: headers(),
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          const res = [...users];
          res[index].activeSaving = false;
          res[index].is_active = false;
          setUsers(res);
        } else {
          results1[index].activeSaving = false;
          setUsers(results1);
        }
      })
      .catch(() => {
        results1[index].activeSaving = false;
        setUsers(results1);
      });
  };

  return (
    <div className="flex p-5 md:p-10">
      <div className="flex flex-col bg-white w-full rounded-md shadow-md py-5 overflow-x-auto">
        {/* header  */}
        <div className="px-10 border-b border-gray-300 pb-4 text-xl font-semibold uppercase tracking-wide ">
          User Lists
        </div>
        {loading && (
          <div className="w-full flex justify-center">
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        {users.length === 0 && !loading && (
          <div className="w-full flex justify-center text-primary text-xl">
            No Data
          </div>
        )}
        {!loading && (
          <div className="text-right mt-4 mr-10">
            <ReactToExcel
              className="bg-primary text-white px-5 py-2 rounded-md shadow-md transition-all duration-300 ease-in-out hover:bg-primary"
              table="user-list"
              buttonText="Download User List"
              sheet="Sheet 1"
              filename="Users list"
            />
          </div>
        )}
        {!loading && (
          <div className="p-10 pt-4">
            <table
              className="table-auto w-full overflow-x-scroll px-2"
              id="user-list"
            >
              <thead className="border-b border-gray-300">
                <tr>
                  <th className="text-center py-2 text-xs md:text-sm">S.N.</th>
                  <th className="text-center py-2 text-xs md:text-sm">
                    USERNAME
                  </th>
                  <th className="text-center py-2 text-xs md:text-sm">EMAIL</th>
                  <th className="text-center py-2 text-xs md:text-sm">
                    Contact
                  </th>
                  <th className="text-center py-2 text-xs md:text-sm">
                    NUMBER OF ORDERS
                  </th>
                  <th className="text-center py-2 text-xs md:text-sm">LOYAL</th>
                  <th className="text-center py-2 text-xs md:text-sm">
                    CHANGE STATUS
                  </th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(users) &&
                  users.map((user, index) => {
                    const {
                      id,
                      username,
                      email,
                      user_order,
                      contact,
                      loyal,
                      saving,
                      activeSaving,
                      is_active,
                    } = user;
                    return (
                      <tr className="border-b border-gray-300" key={index}>
                        <td className="text-gray-700 text-xs md:text-sm py-3 relative pl-4 overflow-hidden">
                          {index + 1}
                        </td>

                        <td className="text-gray-700 text-xs md:text-sm py-3">
                          {username}
                        </td>
                        <td className="text-gray-700 text-xs md:text-sm py-3">
                          {email}
                        </td>
                        <td className="text-gray-700 text-xs md:text-sm py-3">
                          {contact ? contact : "N/A"}
                        </td>
                        <td className="text-gray-700 text-xs md:text-sm py-3 text-center">
                          {user_order.length}
                        </td>
                        <td className="text-gray-700 text-xs md:text-sm py-3 text-center">
                          {!loyal && (
                            <button
                              className="flex items-center space-x-2 bg-primary text-white px-5 py-2 rounded-md shadow-md transition-all duration-300 ease-in-out hover:bg-primary"
                              onClick={() => makeUserLoyal(id, index)}
                            >
                              <div>{!saving ? "Send Mail" : "Sending..."}</div>
                            </button>
                          )}
                          {loyal && <div>Mail Sent</div>}
                        </td>
                        <td className="text-gray-700 text-xs md:text-sm py-3 text-center">
                          {!is_active && (
                            <button
                              className="flex items-center space-x-2 bg-green-500 text-white px-5 py-2 rounded-md shadow-md transition-all duration-300 ease-in-out hover:bg-green-600"
                              onClick={() => enableUser(id, index)}
                            >
                              <div>
                                {activeSaving ? "Enabling..." : "Enable"}
                              </div>
                            </button>
                          )}
                          {is_active && (
                            <div>
                              <button
                                className="flex items-center space-x-2 bg-red-500 text-white px-5 py-2 rounded-md shadow-md transition-all duration-300 ease-in-out hover:bg-red-600"
                                onClick={() => disableUser(id, index)}
                              >
                                <div>
                                  {activeSaving ? "Disabling..." : "Disable"}
                                </div>
                              </button>
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {next && (
              <div className="mt-4">
                <button
                  className="flex items-center space-x-2 bg-primary text-white px-5 py-2 rounded-md shadow-md transition-all duration-300 ease-in-out hover:bg-primary"
                  onClick={loadMoreUsers}
                >
                  <div>{saveLoader ? "Loading" : "Load More"}</div>
                  {saveLoader && <Processing />}
                </button>
              </div>
            )}
          </div>
        )}

        {/* pagination  */}
      </div>
    </div>
  );
}

export default Users;
