import { Link } from "react-router-dom";
import FrontPage from "../common/FrontPage";
import AboutComponent from "./aboutComponent";

// images
import ContactUsBgImage from "../../assets/images/about-us/contact-us.jpg";
import OurProgress from "../common/our-progress";
import Faq from "./faq";

export default function AboutUs({ ...all }) {
  return (
    <div>
      <FrontPage
        id="about-us"
        header="About us"
        description="The Momo World is a restaurant, Himalayan street food in chicago"
      />
      <AboutComponent />

      {/* Our progress */}
      <OurProgress {...all} />

      {/* faq */}
      <Faq />

      {/* Send us a message  --  start */}
      <div
        style={{ backgroundImage: `url(${ContactUsBgImage})` }}
        className="bg-cover bg-center bg-fixed"
      >
        <div>
          <div className="py-10 md:py-20 lg:py-32 px-4 md:px-10 lg:px-16 max-w-7xl mx-auto space-y-5 text-center">
            <div className="text-3xl md:text-4xl lg:text-5xl text-secondary font-semibold">
              <span className="bg-black bg-opacity-60">SEND US A MESSAGE</span>
            </div>
            <div className="button-animation">
              <Link
                to="/contact-us"
                onClick={() => window.scrollTo(0, 0)}
                className="animation-text px-5 py-2"
              >
                Contact Us
              </Link>
              <div className="animation-bg"></div>
            </div>
          </div>
        </div>
      </div>
      {/* Send us a message  --  end */}
    </div>
  );
}
