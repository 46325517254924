import { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl, headers } from "../../authentication/authorization";
import Processing from "../../common/processing";
import PopupMessage from "../../common/popup-message";

function SocialSite() {
  const [saveLoader, setSaveLoader] = useState(false);
  const [alert, setAlert] = useState("");
  const [socialLinks, setSocialLinks] = useState([]);
  const [socials, setSocials] = useState({
    facebook: "",
    instagram: "",
    linkedin: "",
    twitter: "",
  });

  async function getSocialLinks() {
    await fetch(`${baseUrl}/site/social-list`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          if (data.length === 0) {
            setSocials({
              facebook: "",
              instagram: "",
              linkedin: "",
              twitter: "",
            });
          } else {
            setSocials(data[0]);
            setSocialLinks(data);
          }
        }
      })
      .catch(() => null);
  }

  const submitForm = (e) => {
    e.preventDefault();
    setSaveLoader(true);
    if (Array.isArray(socialLinks) && socialLinks.length > 0) {
      fetch(`${baseUrl}/site/social-edit/${socialLinks[0].id}`, {
        method: "PATCH",
        body: JSON.stringify(socials),
        headers: { ...headers(), "Content-Type": "application/json" },
      })
        .then((response) => {
          if (response.ok) {
            setAlert("Successfully edited social sites");
          }
          setSaveLoader(false);
        })
        .catch(() => setSaveLoader(false));
    } else {
      axios
        .post(`${baseUrl}/site/social-add/`, socials, { headers: headers() })
        .then((response) => {
          if (response.ok) {
            setAlert("Successfully added social sites");
            getSocialLinks();
          }
          setSaveLoader(false);
        })
        .catch(() => setSaveLoader(false));
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    async function getSocialLinks() {
      await fetch(`${baseUrl}/site/social-list`, {
        method: "GET",
        signal,
      })
        .then((response) => response.json())
        .then((data) => {
          if (Array.isArray(data)) {
            if (data.length === 0) {
              setSocials({
                facebook: "",
                instagram: "",
                linkedin: "",
                twitter: "",
              });
            } else {
              setSocials(data[0]);
              setSocialLinks(data);
            }
          }
        })
        .catch(() => null);
    }
    getSocialLinks();

    return () => controller.abort();
  }, []);

  const { facebook, instagram, linkedin, twitter } = socials;

  return (
    <>
      {alert !== "" && (
        <PopupMessage message={alert} handleClose={() => setAlert("")} />
      )}
      <div className="flex p-8">
        <div className="w-full md:flex md:space-x-6 space-y-6 md:space-y-0">
          {/* right side  */}
          <div className=" w-full max-w-lg mx-auto ">
            <div className="bg-white rounded-md shadow-md flex flex-col py-5 h-auto w-full">
              {/* header  */}
              <div className="px-10 border-b border-gray-300 pb-4 text-lg font-semibold uppercase tracking-wide">
                ADD SOCIAL LINK FORM
              </div>
              {/* form  */}
              <form className="flex flex-col px-10 py-4 space-y-4">
                {/* facebook*/}
                <div className="flex flex-col space-y-1">
                  <label className="text-sm font-medium text-gray-700">
                    Facebook
                  </label>
                  <div>
                    <input
                      type="url"
                      className="border w-full px-5 border-gray-300 focus:border-indigo-500 py-1.5 text-gray-700 text-sm"
                      value={facebook}
                      onChange={(e) => {
                        setSocials({ ...socials, facebook: e.target.value });
                      }}
                    />
                  </div>
                </div>
                {/* Instagram*/}
                <div className="flex flex-col space-y-1">
                  <label className="text-sm font-medium text-gray-700">
                    Instagram
                  </label>
                  <div>
                    <input
                      type="url"
                      className="border w-full px-5 border-gray-300 focus:border-indigo-500 py-1.5 text-gray-700 text-sm"
                      value={instagram}
                      onChange={(e) => {
                        setSocials({ ...socials, instagram: e.target.value });
                      }}
                    />
                  </div>
                </div>
                {/* Twitter*/}
                <div className="flex flex-col space-y-1">
                  <label className="text-sm font-medium text-gray-700">
                    Twitter
                  </label>
                  <div>
                    <input
                      type="url"
                      className="border w-full px-5 border-gray-300 focus:border-indigo-500 py-1.5 text-gray-700 text-sm"
                      value={twitter}
                      onChange={(e) => {
                        setSocials({ ...socials, twitter: e.target.value });
                      }}
                    />
                  </div>
                </div>
                {/* Linkdin*/}
                <div className="flex flex-col space-y-1">
                  <label className="text-sm font-medium text-gray-700">
                    Linkdin
                  </label>
                  <div>
                    <input
                      type="url"
                      className="border w-full px-5 border-gray-300 focus:border-indigo-500 py-1.5 text-gray-700 text-sm"
                      value={linkedin}
                      onChange={(e) => {
                        setSocials({ ...socials, linkedin: e.target.value });
                      }}
                    />
                  </div>
                </div>
                {/* submit  */}
                <div className="px-10">
                  <button
                    className="flex justify-center items-center w-full  space-x-2 bg-indigo-500 text-white px-5 py-2 rounded-md shadow-md transition-all duration-300 ease-in-out hover:bg-indigo-600"
                    type="submit"
                    onClick={submitForm}
                  >
                    <div>{saveLoader ? "Saving" : "Save"}</div>
                    {saveLoader && <Processing />}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SocialSite;
