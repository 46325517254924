import React from "react";
import Fresh from "../../assets/images/homepage/fresh.svg";
import Momo from "../../assets/images/homepage/momo1.svg";
import Dinner from "../../assets/images/homepage/dinner.svg";
import FreshFoodBackground from "../../assets/images/homepage/momo.jpg";

function FreshFood() {
  const lists = [
    {
      title: "ALWAYS FRESH",
      dis: "Our Foods are prepared from the finest of ingredients and guaranteed to be fresh daily.",
      logo: Fresh,
    },
    {
      title: "TASTY MOMOS",
      dis: "Made with Chefs Special Himalayan Spices",
      logo: Momo,
    },
    {
      title: "LUNCH AND DINNER",
      dis: "Join us for lunch or dinner and enjoy a mouth watering MoMos from our award winning MoMo list.",
      logo: Dinner,
    },
  ];
  return (
    <div
      className="bg-center bg-cover bg-fixed"
      style={{ backgroundImage: `url(${FreshFoodBackground})` }}
    >
      <div className="">
        <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-3 gap-16 py-16 lg:py-20 px-4 md:px-14 lg:px-20  ">
          {lists.map((list, index) => {
            return (
              <div
                key={index}
                className="mx-auto flex flex-col justify-start items-center space-x-4 space-y-3 text-center"
              >
                <div className="w-20 h-20 flex-shrink-0">
                  {/* logo */}
                  <img src={list.logo} alt="" className="w-full h-full" />
                </div>
                {/* title */}
                <div className="text-xl text-white bg-black bg-opacity-60">
                  {list.title}
                </div>
                {/* description */}
                <div className="text-gray-300 bg-black bg-opacity-60">
                  {list.dis}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default FreshFood;
