import { useState, useEffect, useRef } from "react";
import { baseUrl, headers } from "../../authentication/authorization";
import DeleteConfirmationPopup from "../../common/delete-confirmation";
import PopupMessage from "../../common/popup-message";
import Processing from "../../common/processing";

export default function FrequentlyAskedQuestions() {
  const formContainer = useRef();
  const [saveLoader, setSaveLoader] = useState(false);
  const [faq, setFaq] = useState({
    question: "",
    answer: "",
    errors: {
      question: "",
      answer: "",
    },
  });
  const [deletePopup, setDeletePopup] = useState(false);
  const [alert, setAlert] = useState("");
  const [id, setId] = useState("");
  const [faqs, setFaqs] = useState([]);

  const handleErrors = (property, value) => {
    let result;
    value = value === undefined ? faq[property] : value;
    if (value.trim() === "") {
      errors[property] = `${property[0].toUpperCase()}${property.slice(
        1,
        property.length
      )} cannot be left empty`;

      result = false;
    } else {
      if (property === "question") {
        errors.question = "";
        result = true;
      } else if (property === "answer") {
        errors.answer = "";
        result = true;
      }
    }

    setFaq({
      ...faq,
      errors,
    });
    return result;
  };

  const handleChange = ({ target: { value } }, property) => {
    handleErrors(property, value);
    setFaq({
      ...faq,
      [property]: value,
    });
  };

  const getFaqs = async () => {
    await fetch(`${baseUrl}/site/faq-list/`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((dta) => {
        if (Array.isArray(dta)) {
          setFaqs(dta);
        }
      })
      .catch(() => null);
  };

  const submitForm = (event) => {
    event.preventDefault();
    if (!saveLoader) {
      const { question, answer, id } = faq;
      const credentials = ["question", "answer"];
      let goAhead;
      for (let i = 0; i < credentials.length; i++) {
        const result = handleErrors(credentials[i]);
        if (goAhead !== false) {
          goAhead = result;
        }
      }
      if (goAhead) {
        setSaveLoader(true);
        const formData = new FormData();
        formData.append("question", question);
        formData.append("answer", answer);

        fetch(`${baseUrl}/site/faq-${id ? `edit/${id}` : "add/"}`, {
          method: id ? "PUT" : "POST",
          body: formData,
          headers: headers(),
        })
          .then((response) => response.json())
          .then((dta) => {
            const { id: id1 } = dta;

            if (id1) {
              getFaqs();
              setAlert(
                id
                  ? "Successfully edited the faq"
                  : "Successfully added the faq"
              );
              setFaq({
                ...faq,
                answer: "",
                question: "",
              });
            }
            setSaveLoader(false);

            setId("");
          })
          .catch(() => setSaveLoader(false));
      }
    }
  };

  const handleFaqDelete = (action) => {
    if (action === "delete") {
      fetch(`${baseUrl}/site/faq-edit/${id}`, {
        method: "DELETE",
        headers: headers(),
      }).then((response) => {
        if (response.status === 204) {
          setAlert("Successfully deleted the faq");
          const faqs1 = [...faqs];
          faqs1.splice(deletePopup, 1);
          setFaqs(faqs1);
        }
      });
    }
    setDeletePopup(false);
    setId("");
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    async function getFaqs() {
      await fetch(`${baseUrl}/site/faq-list/`, {
        method: "GET",
        signal,
      })
        .then((response) => response.json())
        .then((dta) => {
          if (Array.isArray(dta)) {
            setFaqs(dta);
          }
        })
        .catch(() => null);
    }
    getFaqs();

    return () => controller.abort();
  }, []);

  const { question, answer, errors } = faq;
  const { question: questionErr, answer: answerErr } = errors;

  return (
    <>
      {deletePopup !== false && (
        <DeleteConfirmationPopup
          handleDelete={handleFaqDelete}
          message="Are you sure that you want to delete this faq ?"
        />
      )}
      {alert !== "" && (
        <PopupMessage message={alert} handleClose={() => setAlert("")} />
      )}
      <div className="px-5 py-10 overflow-auto">
        <form
          className="max-w-xl flex flex-col space-y-4 mx-auto bg-white p-5 shadow rounded"
          ref={formContainer}
          onSubmit={submitForm}
        >
          <div className="mx-auto text-xl font-semibold">FAQ Input Filed</div>
          {/* question  */}
          <div className="flex flex-col space-y-2">
            <label htmlFor="" className="text-gray-700 text-sm font-semibold">
              Question
            </label>
            <input
              type="text"
              className="input-style2 w-full"
              value={question}
              onChange={(event) => handleChange(event, "question")}
            ></input>
            {questionErr && <div className="error">{questionErr}</div>}
          </div>
          {/* answer  */}
          <div className="flex flex-col space-y-2">
            <label htmlFor="" className="text-gray-700 text-sm font-semibold">
              Answer
            </label>
            <textarea
              className="border w-full h-24 p-3 focus:outline-none focus:border-indigo-500"
              value={answer}
              onChange={(event) => handleChange(event, "answer")}
            ></textarea>
            {answerErr && <div className="error">{answerErr}</div>}
          </div>
          {/* button  */}
          <div className="">
            <button className="bg-indigo-500 flex items-center justify-center space-x-2 px-5 py-2 w-full sm:w-auto rounded text-white hover:bg-indigo-600 focus:outline-none">
              <div>{saveLoader ? "Saving" : "Save"}</div>
              {saveLoader && <Processing />}
            </button>
          </div>
        </form>

        {/* current frequently asked questions  */}
        {faqs.length > 0 && (
          <div className="flex flex-col  mt-10 bg-white p-5">
            <div className=" text-2xl font-semibold pl-4">Review FAQ</div>
            <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6  p-4">
              {faqs.map((faq, index) => {
                const { question, answer, id } = faq;
                return (
                  <div
                    className="col-span-1 bg-gray-100 shadow-md rounded-md p-5 space-y-2 relative group overflow-hidden cursor-pointer"
                    key={index}
                  >
                    <div className="font-semibold ">{question}</div>
                    <div className="text-sm text-gray-700">{answer}</div>
                    <div className="absolute bg-black w-full h-full -top-2 right-0 opacity-0 group-hover:opacity-30 transition-all duration-300 ease-in-out"></div>
                    <div className="absolute top-0 right-0 space-x-4  w-full h-full flex justify-center items-center ">
                      <button
                        onClick={() => {
                          setFaq({
                            ...faq,
                            errors: { answer: "", question: "" },
                          });
                          formContainer.current.scrollIntoView();
                        }}
                        className="bg-green-500 text-white px-3 py-1 rounded-md shadow-md opacity-0 group-hover:opacity-95 transition-all duration-300 ease-in-out hover:bg-green-600 "
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => {
                          setDeletePopup(index);
                          setId(id);
                        }}
                        className="bg-red-600 text-white px-3 py-1 rounded-md shadow-md opacity-0 group-hover:opacity-95 transition-all duration-300 ease-in-out hover:bg-red-700 "
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
