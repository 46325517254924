import React, { useState, useRef, useEffect } from "react";
import { baseUrl, headers } from "../../authentication/authorization";
import DeleteConfirmationPopup from "../../common/delete-confirmation";
import PopupMessage from "../../common/popup-message";
import Processing from "../../common/processing";

export default function Gallery() {
  const galleryImage = useRef();
  const [gallery, setGallery] = useState("");
  const [next, setNext] = useState("");
  const [state, setState] = useState({
    name: "",
    galleryImages: [],
    galleryError: "",
    nameError: "",
    saveLoader: false,
    deletePopup: false,
    alertPopup: false,
    alertMessage: "",
  });

  async function getGallery(message, url) {
    let { alertMessage, alertPopup, saveLoader, galleryImages } = state;

    if (url) {
      if (!saveLoader) {
        setState({
          ...state,
          saveLoader: true,
        });
      }
    }
    if (url && saveLoader) {
      return;
    }
    await fetch(url ? url : `${baseUrl}/site/gallery-list/`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((dta) => {
        const { results, next } = dta;
        if (Array.isArray(results)) {
          results.forEach((d) => {
            d.deleting = false;
          });
          if (message) {
            alertMessage = message;
            alertPopup = true;
            saveLoader = false;
          }
          setState((state) => ({
            ...state,
            galleryImages: url ? [...galleryImages, ...results] : results,
            alertMessage,
            alertPopup,
            saveLoader: false,
          }));
          setNext(next);
        }
      })
      .catch(() => null);
  }

  const submitForm = async (event) => {
    event.preventDefault();
    const { galleryError, saveLoader, name } = state;
    if (!saveLoader) {
      if (!name) {
        setState({
          ...state,
          nameError: "Name is required",
        });
      }
      if (gallery) {
        setState({
          ...state,
          saveLoader: true,
        });
        const formData = new FormData();
        formData.append("name", name);
        formData.append("img", gallery);
        await fetch(`${baseUrl}/site/gallery-add/`, {
          method: "POST",
          body: formData,
          headers: headers(),
        })
          .then((response) => {
            if (response.ok) {
              galleryImage.current.innerHTML = "Select Image";
              setGallery("");
              getGallery("Successfully added the image");
              setState({
                ...state,
                saveLoader: false,
              });
            } else {
              setState({
                ...state,
                alertMessage: "Something went wrong, please try again later",
                alertPopup: true,
                saveLoader: false,
              });
            }
          })
          .catch((error) =>
            setState((state) => ({
              ...state,
              alertMessage: "Something went wrong, please try again later",
              alertPopup: true,
              saveLoader: false,
            }))
          );
      } else if (!galleryError) {
        setState((state) => ({
          ...state,
          galleryError: "Image is required",
        }));
      }
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    async function getGallery(message) {
      await fetch(`${baseUrl}/site/gallery-list/`, {
        method: "GET",
        signal,
      })
        .then((response) => response.json())
        .then((dta) => {
          const { next, results } = dta;
          if (Array.isArray(results)) {
            results.forEach((d) => {
              d.deleting = false;
            });
            if (message) {
              setState((state) => ({
                ...state,
                galleryImages: results,
                alertMessage: message,
                alertPopup: true,
                saveLoader: false,
              }));
              setNext(next);
            } else {
              setState((state) => ({
                ...state,
                galleryImages: results,
              }));
              setNext(next);
            }
          }
        })
        .catch(() => null);
    }
    getGallery();

    return () => controller.abort();
  }, []);

  const handleGalleryImageDelete = async (action) => {
    if (action === "delete") {
      let {
        deletePopup: { id, index },
        galleryImages,
      } = state;
      galleryImages[index].deleting = true;
      setState((state) => ({
        ...state,
        deletePopup: false,
        galleryImages,
      }));

      await fetch(`${baseUrl}/site/gallery-edit/${id}`, {
        method: "DELETE",
        headers: headers(),
      })
        .then((response) => {
          if (response.ok) {
            galleryImages.splice(index, 1);
            setState({
              ...state,
              galleryImages,
            });
          }
        })
        .catch(() => null);
    }
    setState({
      ...state,
      deletePopup: false,
    });
  };

  const {
    name,
    galleryImages,
    galleryError,
    saveLoader,
    deletePopup,
    alertPopup,
    alertMessage,
    nameError,
  } = state;

  return (
    <>
      {deletePopup !== false && (
        <DeleteConfirmationPopup
          handleDelete={handleGalleryImageDelete}
          message="Are you sure that you want to delete this image ?"
        />
      )}
      {alertPopup !== false && (
        <PopupMessage
          message={alertMessage}
          handleClose={() =>
            setState({
              ...state,
              alertPopup: false,
            })
          }
        />
      )}
      <div className="px-5 py-10 overflow-auto">
        <form className="max-w-xl flex flex-col space-y-4 mx-auto bg-white p-5 shadow rounded">
          <div className="mx-auto text-xl font-semibold">
            Gallery Input Field
          </div>

          <div className="flex flex-col space-y-2">
            <label htmlFor="" className="text-gray-700 text-sm font-semibold">
              Name
            </label>
            <input
              type="text"
              value={name}
              onChange={(event) => {
                setState({ ...state, nameError: "", name: event.target.value });
              }}
              className="border w-full p-3 focus:outline-none focus:border-indigo-500"
            />
            {nameError && <div className="error">{nameError}</div>}
          </div>

          <div className="flex flex-col space-y-2 relative ">
            <label className="text-gray-700 text-sm font-semibold">Image</label>

            <label
              htmlFor="input-file"
              className="bg-gray-50  p-2.5 w-full text-gray-500 text-sm overflow-hidden"
              ref={galleryImage}
            >
              Select Image
            </label>
            <input
              type="file"
              id="input-file"
              className="border w-full hidden"
              onChange={(event) => {
                const file = event.target.files[0];
                const fileName = file.name;
                const image = new Image();
                image.src = URL.createObjectURL(file);
                image.onload = function () {
                  let arr = fileName.split(".");
                  let extension = arr[arr.length - 1];
                  const extensions = ["png", "jpg", "jpeg", "webp"];
                  let bool = false;
                  for (let i = 0; i < extensions.length; i++) {
                    if (extensions[i] === extension.toLowerCase()) {
                      bool = true;
                      i = extensions.length;
                    }
                  }
                  if (bool) {
                    setState({
                      ...state,
                      galleryError: "",
                    });
                    setGallery(file);
                  } else {
                    setState({
                      ...state,
                      galleryError: "Invalid file",
                    });
                    setGallery("");
                  }
                };

                image.onerror = function () {
                  setState({
                    ...state,
                    galleryError: "Invalid file",
                  });
                  setGallery("");
                };
                galleryImage.current.innerHTML = fileName;
              }}
            />
            <label
              htmlFor="input-file"
              className="bg-gray-200 py-2 px-4 absolute top-5 right-0 cursor-pointer text-gray-600"
            >
              Browse...
            </label>
            {galleryError && (
              <div className="text-red-500 text-sm">{galleryError}</div>
            )}
          </div>
          {/* button  */}
          <div className="">
            <button
              className={`flex space-x-2 items-center px-5 py-2 w-full sm:w-auto rounded text-white focus:outline-none ${
                saveLoader
                  ? "bg-indigo-400 hover:bg-none cursor-default"
                  : "cursor-pointer bg-indigo-500 hover:bg-indigo-600"
              }`}
              onClick={submitForm}
              disabled={saveLoader}
            >
              <div>{saveLoader ? "Saving" : "Save"}</div>
              {saveLoader && <Processing />}
            </button>
          </div>
        </form>
        {/* current aboutus  */}
        {Array.isArray(galleryImages) && galleryImages.length > 0 && (
          <div className="flex flex-col  mt-10 bg-white p-5">
            <div className=" text-2xl font-semibold pl-4">Review Images</div>
            <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6  p-4">
              {galleryImages.map((galleryImage, index) => {
                const { img, id, deleting } = galleryImage;
                return (
                  <div
                    className={`group col-span-1 relative  overflow-hidden cursor-pointer h-40 ${
                      !deleting && "gallery-item"
                    }`}
                    key={index}
                  >
                    <img
                      src={img}
                      alt=""
                      className="transition-all duration-500 w-full h-full object-cover"
                    />
                    {deleting && (
                      <div className="w-full top-0 h-full bg-black bg-opacity-40 absolute z-50 flex items-center justify-center text-white">
                        <div className="pb-1 pr-2">Deleting</div>
                        <Processing />
                      </div>
                    )}
                    {!deleting && (
                      <>
                        <div className="absolute bg-primary w-full h-full top-0 opacity-0 group-hover:opacity-60 transition-all duration-300 ease-in-out"></div>
                        <div className="absolute top-0 w-full h-full flex justify-center items-center ">
                          <button
                            className="bg-red-600 text-white px-3 py-1 rounded-md shadow-md opacity-0 group-hover:opacity-95 transition-all duration-300 ease-in-out hover:bg-red-700"
                            onClick={() =>
                              setState({
                                ...state,
                                deletePopup: {
                                  index,
                                  id,
                                },
                              })
                            }
                          >
                            Delete
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
            {next && (
              <div className="mt-4 ml-4">
                <button
                  className="flex items-center  space-x-2 bg-indigo-500 text-white px-5 py-2 rounded-md shadow-md transition-all duration-300 ease-in-out hover:bg-indigo-600"
                  onClick={() => getGallery(false, next)}
                >
                  <div>{saveLoader ? "Loading" : "Load More"}</div>
                  {saveLoader && <Processing />}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
