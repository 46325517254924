import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl, headers } from "../../authentication/authorization";
import PopupMessage from "../../common/popup-message";
import Processing from "../../common/processing";
import AllHolidayHour from "./AllHolidayHour";

export default function HolidayHour() {
  const [inputFields, setInputFields] = useState({
    holidayStartDate: "",
    holidayEndDate: "",
    remarks: "",
  });
  const [editId, setEditId] = useState();
  const { holidayStartDate, holidayEndDate, remarks } = inputFields;

  const [alert, setAlert] = useState("");
  const [loading, setLoading] = useState(true);
  const [saveLoader, setSaveLoader] = useState(false);
  const [error, setError] = useState({});
  const [dataCheck, setDataCheck] = useState(false);

  // getting the holiday hour period data
  const [getHolidayHour, setGetHolidayHour] = useState([]);

  // get holiday hours
  const getHolidayHourDetails = (signal) => {
    axios
      .get(`${baseUrl}/site/holiday/`, { signal })
      .then((res) => {
        setGetHolidayHour(res.data);
        setLoading(false);
      })
      .catch((err) => {
      });
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    getHolidayHourDetails(signal);
    return () => controller.abort();
  }, []);

  const holidayHourFields = [
    {
      label: "Holiday Starts",
      type: "date",
      value: holidayStartDate,
      name: "holidayStartDate",
      vali: error.holidayStartDate,
    },
    {
      label: "Holiday Ends",
      type: "date",
      value: holidayEndDate,
      name: "holidayEndDate",
      vali: error.holidayEndDate,
    },
    {
      label: "Remarks",
      type: "text",
      value: remarks,
      name: "remarks",
      placeholder: "Holiday Info",
      vali: error.remarks,
    },
  ];

  const handleTimeSubmit = (event) => {
    event.preventDefault();
    setError(validate(inputFields));
    setDataCheck(true);
  };

  // post the data when there is no error in the object
  useEffect(() => {
    if (dataCheck === true && Object.keys(error).length === 0) {
      const postData = {
        start_date: holidayStartDate,
        end_date: holidayEndDate,
        remarks: remarks,
      };
      setSaveLoader(true);
      editId
        ? axios
            .patch(`${baseUrl}/site/holiday-update/${editId}`, postData, {
              headers: headers(),
            })
            .then((res) => {
              setSaveLoader(false);
              getHolidayHourDetails();
              setEditId("");
              setInputFields({
                holidayStartDate: "",
                holidayEndDate: "",
                remarks: "",
              });
            })
            .catch((err) => {
              setSaveLoader(false);
            })
        : axios
            .post(`${baseUrl}/site/holiday/`, postData)
            .then((res) => {
              setSaveLoader(false);
              getHolidayHourDetails();
              setInputFields({
                holidayStartDate: "",
                holidayEndDate: "",
                remarks: "",
              });
            })
            .catch((err) => {
              setSaveLoader(false);
            });
    }
    // eslint-disable-next-line
  }, [error]);

  // validate the input fields
  const validate = (data) => {
    let errors = {};

    let todayDate = new Date()
      .toLocaleDateString()
      .toString()
      .split("/")
      .reverse();

    let exchangeItem = todayDate;
    [exchangeItem[2], exchangeItem[1]] = [exchangeItem[1], exchangeItem[2]];
    exchangeItem.join().replace(",", "-").replace(",", "-");
    let newDate = [...exchangeItem].join().replace(",", "-").replace(",", "-");

    // input date
    if (!data.holidayStartDate) {
      errors.holidayStartDate = "Required";
    } else if (data.holidayStartDate < newDate) {
      errors.holidayStartDate =
        "Starting date must be greater than today's date";
    }
    if (!data.holidayEndDate) {
      errors.holidayEndDate = "Required";
    } else if (data.holidayEndDate < data.holidayStartDate) {
      errors.holidayEndDate = "Ending date must be greater than starting date";
    }
    if (!data.remarks) {
      errors.remarks = "Required";
    }
    return errors;
  };

  // delete the holiday hour
  const deleteHandler = (id) => {
    axios
      .delete(`${baseUrl}/site/holiday-update/${id}`, { headers: headers() })
      .then((res) => {
        getHolidayHourDetails();
        setLoading(false);
      })
      .catch((err) => {
      });
  };

  const editHandler = (id) => {
    setEditId(id);
    axios
      .get(`${baseUrl}/site/holiday-update/${id}`, { headers: headers() })
      .then((res) => {
        setInputFields((prev) => {
          return {
            ...prev,
            holidayStartDate: res.data.start_date,
            holidayEndDate: res.data.end_date,
            remarks: res.data.remarks,
          };
        });
      })
      .catch((err) => {
        // console.log(err.message);
      });
  };

  const dateChangeHandler = (e) => {
    const { name, value } = e.target;
    setInputFields((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  return (
    <>
      {alert !== "" && (
        <PopupMessage message={alert} handleClose={() => setAlert("")} />
      )}
      <div className="px-5 py-10 overflow-auto">
        <form
          className="max-w-4xl flex flex-col space-y-4 mx-auto bg-white py-5 px-10 shadow rounded"
          onSubmit={handleTimeSubmit}
        >
          <div className="mx-auto text-xl font-semibold text-center">
            Holiday Hour Period
          </div>

          <div className="md:flex md:flex-row flex-col w-full items-center justify-center md:space-x-4 md:space-y-0 space-y-4">
            {holidayHourFields.map((holidayHour, index) => {
              const { name, value, type, label, placeholder, vali } =
                holidayHour;
              return (
                <div className="flex flex-col w-full" key={index}>
                  <label htmlFor="" className="text-gray-600 text-sm">
                    {label}
                  </label>
                  <input
                    type={type}
                    className="input-style2 py-0 w-full"
                    placeholder={placeholder}
                    name={name}
                    value={value}
                    onChange={dateChangeHandler}
                  />
                  {vali && <p className="text-red-500 text-sm mt-1">{vali}</p>}
                </div>
              );
            })}
          </div>
          {/* button  */}
          <div className="">
            <button className="bg-indigo-500 flex justify-center items-center space-x-2 px-5 py-2 w-full sm:w-auto rounded text-white hover:bg-indigo-600 focus:outline-none">
              <div>{saveLoader ? "Saving" : "Save"}</div>
              {saveLoader && <Processing />}
            </button>
          </div>
        </form>
        {/* all holiday hour period */}
        <div className="max-w-4xl flex flex-col space-y-4 mx-auto bg-white py-5 md:px-10 px-6 shadow rounded mt-10">
          <div className="mx-auto text-xl font-semibold text-center">
            All Holiday Hour Period
          </div>

          {/* holiday hour list */}
          {loading && (
            <div className="w-full flex justify-center">
              <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}
          {getHolidayHour.length === 0 && !loading && (
            <div className="w-full flex justify-center text-primary text-xl">
              No Data
            </div>
          )}

          {getHolidayHour.length > 0 && (
            <div className="w-full h-full flex flex-col ">
              <div className="flex flex-col border-gray-300 border divide-y divide-gray-300 rounded-md w-full">
                <div className="p-2 font-bold text-gray-800 md:flex md:flex-row  flex-col md:justify-between items-center md:block hidden">
                  <div className="w-48 text-center">Timestamp</div>
                  <div className="">Holiday Info</div>
                  <div className="w-32 text-center">Actions</div>
                </div>
                {getHolidayHour.map((holidayHour) => {
                  return (
                    <AllHolidayHour
                      {...holidayHour}
                      key={holidayHour.id}
                      deleteHandler={deleteHandler}
                      editHandler={editHandler}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
