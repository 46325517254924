import { useCallback, useEffect, useState, useMemo, useRef } from "react";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import CheckIcon from "@material-ui/icons/Check";
import { baseUrl } from "../authentication/authorization";
import FrontPage from "../common/FrontPage";
import leaf from "../../assets/images/homepage/leaf.png";
import deliveryMan from "../../assets/images/delivery-ban.png";
// menu item images
import bg from "../../assets/images/menu-items/resturant.jpg";
import React from "react";

export default function Menu(props) {
  const menusRef = useRef();
  const { setTotalItems, history } = props;
  const controller = useMemo(() => new AbortController(), []);
  const { signal } = controller;
  const { loggedIn } = props;
  const [activeItem, setActiveItem] = useState("");
  const [menuHeader, setMenuHeader] = useState([]);
  const [menuLists, setMenuLists] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleMenuCategoryChange = useCallback((menu) => {
    setActiveItem(menu);
    setLoading(true);
    setMenuLists([]);
    fetch(`${baseUrl}/menu-list/?search=${menu}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((dta) => {
        if (Array.isArray(dta)) {
          let cart = localStorage.getItem("cart");
          if (
            cart !== undefined &&
            typeof JSON.parse(cart) === "object" &&
            JSON.parse(cart) !== null
          ) {
            cart = JSON.parse(cart);
          } else {
            cart = {
              items: [],
              total: 0,
            };
          }
          const { items } = cart;
          let menuLists = [...dta];
          for (let i = 0; i < menuLists.length; i++) {
            let value = 0;
            if (Array.isArray(items) && items.length > 0) {
              for (let j = 0; j < items.length; j++) {
                if (items[j].id === menuLists[i].id) {
                  value = items[j].value;
                }
              }
            }

            menuLists[i].value = value;
            menuLists[i].added = false;
          }

          setMenuLists(menuLists);
          setLoading(false);
        }
      })
      .catch(() => null);
  }, []);

  let animation = [];
  for (let i = 0; i < 2; i++) {
    animation.push(
      <div className="animate-pulse flex space-x-4" key={i}>
        {/* image part */}
        <div className="bg-yellow-200 h-40 w-40"></div>
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-yellow-200 rounded w-3/4"></div>
          <div className="h-4 flex justify-between ">
            <div className="bg-yellow-200 w-20 rounded"></div>
            <div className="bg-yellow-200 w-10 rounded"></div>
          </div>
          <div className="space-y-2">
            <div className="h-16 bg-yellow-200 rounded"></div>
            <div className="h-4 flex justify-between ">
              <div className="bg-yellow-200 w-20 rounded"></div>
              <div className="bg-yellow-200 w-10 rounded"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  useEffect(() => {});

  useEffect(() => {
    history.location.state &&
      history.location.state.goToMenu &&
      menusRef.current.scrollIntoView();
    const getCategory = async () => {
      const url = `${baseUrl}/display-category/`;
      await fetch(url, {
        method: "GET",
        signal,
      })
        .then((response) => response.json())
        .then((dta) => {
          if (Array.isArray(dta) && dta.length > 0) {
            dta.sort((a, b) => a.order - b.order);
            const activeItem = dta[0] ? dta[0].slug : "";
            setMenuHeader(dta);
            setActiveItem(activeItem);
            handleMenuCategoryChange(activeItem);
          }
        })
        .catch(() => null);
    };
    getCategory();

    return () => controller.abort();
  }, [controller, handleMenuCategoryChange, signal, history.location.state]);

  const handleValueNumberChange = (id, event, operator) => {
    const newMenuLists = [...menuLists];
    newMenuLists.forEach((menu) => {
      if (menu.id === id) {
        if (event) {
          const value = +event.target.value;
          // eslint-disable-next-line
          if (+value === value && value > -1 && value < 100) {
            menu.value = value;
          }
        } else {
          const value = +menu.value;
          if (operator === "add" && value < 99) {
            menu.value = value + 1;
          } else if (operator === "subtract" && value > 0) {
            menu.value = value - 1;
          }
        }
      }
    });
    setMenuLists(newMenuLists);
  };

  const handleAddedOrRemovedMessage = (index) => {
    setTimeout(() => {
      const newMenuLists = [...menuLists];
      newMenuLists[index].added = false;
      setMenuLists(newMenuLists);
    }, 3000);
  };

  const checkTotalItems = (arr) =>
    arr.reduce((total, current) => total + current.value, 0);

  const addToCart = (id) => {
    if (loggedIn) {
      const newMenuLists = [...menuLists];
      let cart = localStorage.getItem("cart");
      if (
        cart !== undefined &&
        typeof JSON.parse(cart) === "object" &&
        JSON.parse(cart) !== null
      ) {
        cart = JSON.parse(cart);
      } else {
        cart = {
          items: [],
          total: 0,
        };
      }

      const { items } = cart;
      for (let i = 0; i < newMenuLists.length; i++) {
        const { id: menuId, value } = newMenuLists[i];
        let bool = false;
        if (id === menuId) {
          for (let j = 0; j < items.length; j++) {
            if (items[j].id === id) {
              bool = true;
              if (items[j].value === value) {
              } else if (value > 0) {
                items[j].value = value;
                newMenuLists[i].added = true;
                handleAddedOrRemovedMessage(i);
              } else if (value < 1) {
                items.splice(j, 1);
                newMenuLists[i].added = true;
                handleAddedOrRemovedMessage(i);
              }
              j = items.length;
            }
          }
          if (!bool && value > 0) {
            items.push({
              id,
              value,
            });

            newMenuLists[i].added = true;
            handleAddedOrRemovedMessage(i);
          }

          cart.total = checkTotalItems(items);
          setTotalItems(cart.total);
          localStorage.setItem("cart", JSON.stringify({ ...cart, items }));
          i = menuLists.length;
        }
      }
      setMenuLists(newMenuLists);
    } else {
      window.scrollTo(0, 0);
      props.history.push({
        pathname: "/login",
        state: { menu: true },
      });
    }
  };
  return (
    <div>
      <FrontPage id="menu" header="Taste the best MOMO's ever" />
      <div className="flex flex-col" ref={menusRef}>
        {/* header  */}
        <div className="flex-1 md:flex flex-col justify-center items-center px-5 mt-20 pb-10">
          <div className="text-xl md:text-2xl font-subHeader text-primary text-center">
            FOOD AT FIRST SIGHT
          </div>
          <div className=" text-3xl sm:text-4xl lg:text-6xl text-center font-header">
            Our Restaurant Menu
          </div>
          <div className="mt-4">
            <img src={leaf} alt="" className="mx-auto" />
          </div>
        </div>
        {/* menu header list  */}
        <div className="flex w-full justify-center" id="toMenu">
          <div
            className={`grid ${
              menuHeader.length > 5 && "sm:grid-cols-3 md:grid-cols-6"
            } ${menuHeader.length > 4 && " sm:grid-cols-5"}
          } ${menuHeader.length > 3 && " sm:grid-cols-4"}
        } ${menuHeader.length > 2 && " sm:grid-cols-3"}  ${
              menuHeader.length > 1 && " grid-cols-2 "
            } md:gap-x-0 md:gap-y-2`}
          >
            {menuHeader.map((header, index) => {
              const { slug, name } = header;
              if (activeItem === slug) {
                return (
                  <div
                    className="col-span-1 text-white px-3 py-2 lg:px-4 lg:py-4 bg-primary flex items-center justify-center  "
                    key={index}
                  >
                    {name}
                  </div>
                );
              }
              return (
                <div
                  className="col-span-1 bg-black text-white px-3 py-2 lg:px-4 lg:py-4 cursor-pointer transition-all duration-300 hover:bg-primary ease-in-out flex items-center justify-center"
                  key={index}
                  onClick={() => handleMenuCategoryChange(slug)}
                >
                  {name}
                </div>
              );
            })}
          </div>
        </div>
        {/* menu list  */}
        <div
          className="w-full px-5 md:px-20 py-10 bg-no-repeat bg-center bg-cover bg-fixed mt-5"
          style={{
            backgroundImage: `url(${bg})`,
          }}
        >
          <div className="w-full bg-white p-5">
            <div className="w-full border border-dashed border-primary p-5 ">
              {/* card  */}
              {menuLists.length > 0 && (
                <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6">
                  {menuLists.map((menu, index) => {
                    const {
                      id,
                      item,
                      description,
                      price,
                      img,
                      value,
                      added,
                      prepare_time,
                      is_deleverable,
                      out_of_stock,
                      is_displayed,
                    } = menu;

                    return (
                      <React.Fragment key={index}>
                        {is_displayed && (
                          <div key={index} className="col-span-1  md:p-5 ">
                            <div className="w-full justify-start lg:flex lg:space-x-4">
                              {/* for image  */}
                              <div className="lg:w-40">
                                <img
                                  src={img}
                                  className="w-full h-40 object-cover"
                                  alt=""
                                />
                              </div>
                              {/* info area  */}
                              <div className="flex-1 flex flex-col space-y-3 mt-2 lg:mt-0 pb-3 sm:pb-0">
                                {/* food name  */}
                                <div className=" text-lg sm:text-xl xl:text-2xl font-semibold font-header flex items-center space-x-3">
                                  <span> {item}</span>
                                  <div className="flex space-x-1 text-gray-600 text-sm items-center mt-1">
                                    <svg
                                      className="w-4 h-4 "
                                      fill="none"
                                      stroke="currentColor"
                                      viewBox="0 0 24 24"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                                      />
                                    </svg>
                                    {/* time  */}
                                    <span>{prepare_time} min</span>
                                  </div>
                                </div>

                                {price && (
                                  <div className="flex justify-between border-b border-dashed border-gray-300 sm:text-sm lg:text-base items-center">
                                    <div className="text-gray-600 text-sm xl:text-base">
                                      Price
                                    </div>
                                    <div className="text-primary text-sm xl:text-base">
                                      ${price}
                                    </div>
                                  </div>
                                )}
                                <div className="text-gray-600 text-sm menu-item relative cursor-pointer">
                                  {description && description.slice(0, 100)}
                                  {description &&
                                    description.length > 100 &&
                                    "..."}

                                  {description && description.length > 100 && (
                                    <span className=" hidden absolute  bg-primary h-32 overflow-y-auto text-white p-2 rounded -top-32 xl:-top-20 menu-description">
                                      {description}
                                    </span>
                                  )}
                                </div>
                                {out_of_stock ? (
                                  <div className="flex justify-end text-primary">
                                    Out of stock
                                  </div>
                                ) : (
                                  <div className="xl:flex justify-between space-y-2 xl:space-y-0">
                                    <div className="flex space-x-0.5">
                                      <button
                                        className="bg-gray-200 px-3 hover:bg-gray-300 focus:outline-none"
                                        onClick={() =>
                                          handleValueNumberChange(
                                            id,
                                            null,
                                            "subtract"
                                          )
                                        }
                                      >
                                        <RemoveIcon className="text-gray-700" />
                                      </button>
                                      <div>
                                        <input
                                          type="text"
                                          className="w-16 text-center border border-gray-300 py-0.5 text-sm"
                                          value={value}
                                          onChange={(event) =>
                                            handleValueNumberChange(id, event)
                                          }
                                        />
                                      </div>
                                      <button
                                        className="bg-gray-200 px-3 hover:bg-gray-300 focus:outline-none"
                                        onClick={() =>
                                          handleValueNumberChange(
                                            id,
                                            null,
                                            "add"
                                          )
                                        }
                                      >
                                        <AddIcon className="text-gray-700" />
                                      </button>
                                      {added && <CheckIcon />}
                                    </div>
                                    <button
                                      className="text-xs bg-primary py-1 hover:bg-secondary px-3 text-white"
                                      onClick={() => addToCart(id)}
                                    >
                                      ADD TO CART
                                    </button>
                                  </div>
                                )}

                                {!is_deleverable && (
                                  <div className="flex justify-end">
                                    <img
                                      src={deliveryMan}
                                      alt=""
                                      className="w-10"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              )}
              {/* loading animation  */}
              {loading && (
                <div className=" w-full grid grid-cols-1 md:grid-cols-2 gap-2 sm:gap-6">
                  {animation}
                </div>
              )}

              {menuLists.length === 0 && !loading && (
                <div className="text-center">No items found</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
