import { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router";
import Sidebar from "./static/sidebar";
import NavBar from "./static/navbar";
import MainDashboard from "./main-dashboard";
import About from "./about/about";
import Banner from "./offer/offer";
import Contact from "./contact/contact";
import FrequentlyAskedQuestions from "./frequently-asked-questions/frequently-asked-questions";
import Order from "../order/order";
import Category from "./category/Category";
import Testimonials from "./testimonials/addTestimonials";
import Time from "./time/Time";
import AddProduct from "./product/AddProduct";
import AllProducts from "./product/allproducts";
import SocialSite from "./social/SocialSite";
import Gallery from "./gallery/gallery";
import Settings from "./settings/settings";
import Services from "./services/services";
import Users from "./users/Users";
import ImageSlider from "./slider/ImageSlider";
import DeliveryRange from "./delivery-range/delivery-range";
import Spicy from "./Spicy/spicy";
import Sauce from "./sauce/sauce";
import HolidayHour from "./Holiday Hour/HolidayHour";

function DashboardManagement() {
  const [width, setWidth] = useState(window.innerWidth);
  const [toggled, setToggled] = useState(() => (width > 1023 ? true : false));

  const handleResize = () => {
    const innerWidth = window.innerWidth;
    if (innerWidth < 768 && width > 767 && width < 1024) {
      setWidth(innerWidth);
      setToggled(false);
    } else if (width < 768 && innerWidth > 767 && innerWidth < 1024) {
      setWidth(innerWidth);
    } else if (innerWidth < 1024 && width > 1023) {
      setWidth(innerWidth);
      setToggled(false);
    } else if (innerWidth > 1023 && width < 1024) {
      setWidth(innerWidth);
      setToggled(true);
    }
  };

  const handleSidebarLinkClick = () => {
    if (width < 1023) {
      setToggled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <div className="bg-gray-100 flex font-rubik">
      <Sidebar
        toggled={toggled}
        handleSidebarLinkClick={handleSidebarLinkClick}
      />
      <div className="flex-1 flex flex-col min-h-screen">
        <NavBar
          width={width}
          toggled={toggled}
          setToggled={() => setToggled(!toggled)}
        />
        <div className={`${toggled && width > 1023 ? "pl-64" : ""}`}>
          <div>
            <Switch>
              <Route exact path="/profile" component={MainDashboard} />
              <Route exact path="/profile/offer" component={Banner} />
              <Route
                exact
                path="/profile/delivery-range"
                component={DeliveryRange}
              />
              <Route exact path="/profile/time" component={Time} />
              <Route
                exact
                path="/profile/holiday-hour/"
                component={HolidayHour}
              />
              <Route exact path="/profile/about" component={About} />
              <Route exact path="/profile/allproduct" component={AllProducts} />
              <Route exact path="/profile/spicy-level" component={Spicy} />
              <Route exact path="/profile/sauce" component={Sauce} />
              <Route
                exact
                path="/profile/user-reviews"
                component={Testimonials}
              />
              <Route exact path="/profile/addcategory" component={Category} />
              <Route exact path="/profile/addproduct" component={AddProduct} />
              <Route
                exact
                path="/profile/addproduct/:id"
                component={AddProduct}
              />
              <Route
                exact
                path="/profile/contact"
                render={() => <Contact width={width} />}
              />
              <Route exact path="/profile/gallery" component={Gallery} />
              <Route
                exact
                path="/profile/image-slider"
                component={ImageSlider}
              />
              <Route exact path="/profile/services" component={Services} />
              <Route exact path="/profile/users" component={Users} />
              <Route
                exact
                path="/profile/frequently-asked-questions"
                component={FrequentlyAskedQuestions}
              />
              <Route
                exact
                path="/profile/order"
                render={() => <Order width={width} />}
              />
              <Route exact path="/profile/socialsite" component={SocialSite} />
              <Route exact path="/profile/settings" component={Settings} />
              <Redirect to="/page-not-found" />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardManagement;
