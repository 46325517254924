const OrderConfirmationPopup = ({ handleConfirmation, message }) => {
  return (
    <div className="min-h-screen w-screen bg-black bg-opacity-40 fixed left-0 top-0 z-50 grid place-items-center">
      <div className="bg-white p-4 mx-2 rounded">
        <div>{message}</div>
        <div className="flex justify-end space-x-4 text-white mt-2">
          <div
            className="inline-block cursor-pointer bg-red-600 h-10 leading-10 hover:bg-red-700  text-white px-3 rounded"
            onClick={() => handleConfirmation("no")}
          >
            No
          </div>
          <div
            className="inline-block bg-blue-700 h-10 leading-10 hover:bg-blue-800 cursor-pointer text-white px-3 rounded"
            onClick={() => handleConfirmation("yes")}
          >
            Yes
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderConfirmationPopup;
