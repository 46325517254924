import FrontPage from "../common/FrontPage";
import AllMenus from "./all-menus";

export default function Gallery() {
  return (
    <div>
      <FrontPage id="gallery" title="Best Meals Ever" header="Give it a try!" />
      <AllMenus />
    </div>
  );
}
