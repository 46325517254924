import { useState } from "react";
import { Link } from "react-router-dom";
import { baseUrl } from "./authorization";
import Processing from "../common/processing";
import backgroundImage from "../../assets/images/authentication/login-background.jpg";
import logo from "../../assets/images/dashboard/logo.jpeg";

export default function Login() {
  const [saveLoader, setSaveLoader] = useState(false);

  const [data, setData] = useState({
    email: "",
    password: "",
    errors: {
      email: "",
      password: "",
      login: "",
    },
  });

  const handleErrors = (property, value) => {
    const { errors } = data;
    value = value === undefined ? data[property] : value;
    errors.login = errors.login && "";
    let result;
    if (value.trim() === "" && property !== "password") {
      errors[property] = `${property[0].toUpperCase()}${property.slice(
        1,
        property.length
      )} cannot be left empty`;
      result = false;
    } else {
      if (property === "email") {
        if (!value.match(/^\w+([._]\w+)?@\w+\.\w+(\.\w+)?$/gi)) {
          errors.email = "Invalid email";
          result = false;
        } else {
          errors.email = "";
          result = true;
        }
      } else {
        if (value.length < 8) {
          errors.password = "Password must be atleast 8 characters long";
          result = false;
        } else {
          errors.password = "";
          result = true;
        }
      }
    }

    setData({
      ...data,
      errors,
    });
    return result;
  };

  const handleChange = ({ target: { value } }, property) => {
    handleErrors(property, value);
    setData({
      ...data,
      [property]: value,
    });
  };

  const handleLoginSubmit = (event) => {
    event.preventDefault();
    if (!saveLoader) {
      const url = `${baseUrl}/user/login/`;

      const { email, password, errors } = data;
      const credentials = ["email", "password"];
      let goAhead;
      for (let i = 0; i < credentials.length; i++) {
        const result = handleErrors(credentials[i]);
        if (goAhead !== false) {
          goAhead = result;
        }
      }

      if (goAhead) {
        setSaveLoader(true);
        // submitting the form if all input fields are validated
        const formData = new FormData();
        formData.append("email", email);
        formData.append("password", password);
        fetch(url, {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((dta) => {
            const { detail, access, refresh } = dta;
            if (detail) {
              errors.login = detail;
              setData({
                ...data,
                errors,
              });

              setSaveLoader(false);
            } else if (access && refresh) {
              localStorage.setItem("access", access);
              localStorage.setItem("refresh", refresh);
              window.location = "/menu-list";
            } else {
              errors.login = "Something went wrong, please try again later";
              setData({
                ...data,
                errors,
              });
              setSaveLoader(false);
            }
          })
          .catch((error) => {
            errors.login = "Something went wrong, please try again later";
            setData({
              ...data,
              errors,
            });
            setSaveLoader(false);
          });
      }
    }
  };

  const {
    email,
    password,
    errors: { email: emailError, password: passwordError, login: loginError },
  } = data;

  return (
    <div style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="w-full h-full grid place-items-center min-h-screen pt-16">
        <form
          onSubmit={handleLoginSubmit}
          className="bg-white px-10 py-8 mx-5 max-w-sm w-full authentication-form"
          autoComplete="off"
        >
          <div className="flex flex-col pb-2 space-y-2">
            {/* resturant logo  */}
            <Link to="/" className="w-20 h-20 mx-auto">
              <img
                src={logo}
                alt=""
                className="w-full h-full rounded-full object-center object-cover"
              />
            </Link>
            {/* momo world  */}
            <div className="text-xl font-semibold text-center">Login Form</div>
          </div>
          <div className="space-y-5">
            <div className="space-y-1">
              <label htmlFor="email">Email address</label>
              <input
                type="text"
                id="email"
                value={email}
                onChange={(event) => handleChange(event, "email")}
              />
              {emailError && <div className="error">{emailError}</div>}
            </div>
            <div className="space-y-1">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(event) => handleChange(event, "password")}
              />
              {passwordError && <div className="error">{passwordError}</div>}
              {loginError && <div className="error">{loginError}</div>}
            </div>

            <div className="button-animation" style={{ display: "block" }}>
              <button className="animation-text text-center px-5 py-3 w-full">
                <div className="flex justify-center space-x-2 items-center">
                  <div>Login</div>
                  {saveLoader && <Processing />}
                </div>
              </button>
              <div className="animation-bg"></div>
            </div>
          </div>
          <div className="text-xs mt-2 text-center">
            Dont have an account ?{" "}
            <Link
              to="/register"
              className="underline"
              onClick={() => window.scrollTo(0, 0)}
            >
              Register now
            </Link>
          </div>
          <div className="text-xs mt-2 text-center">
            <Link
              to="/forgot-password"
              className="underline"
              onClick={() => window.scrollTo(0, 0)}
            >
              Forgot Password ?
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
