import CloseIcon from "@material-ui/icons/Close";

export default function GalleryImage({ img, handleClose }) {
  return (
    <div
      className="fixed left-0 top-0 h-screen w-screen z-50 grid place-items-center overflow-y-auto"
      style={{ minHeight: "400px" }}
    >
      <div
        className="fixed bg-black bg-opacity-50 left-0 top-0 h-screen w-screen z-40"
        onClick={handleClose}
      ></div>
      <div className="z-50 relative my-10 overflow-y-auto">
        <div
          className="absolute right-0 top-0 p-2 bg-red-500 hover:bg-red-600 cursor-pointer"
          onClick={handleClose}
        >
          <CloseIcon />
        </div>
        <img src={img} alt="" className="object-cover gallery-popup-image" />
      </div>
    </div>
  );
}
