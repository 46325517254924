import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { baseUrl } from "../authentication/authorization";
import barCodeForMenuLists from "../../assets/images/bar-code-for-menu-lists.png";
import axios from "axios";

let arr = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export default function Footer({ ...all }) {
  // get holiday hours
  const [holidayHour, setHolidayHour] = useState([]);

  const [socialSites, setSocialSites] = useState({
    id: "",
    facebook: "",
    twitter: "",
    instagram: "",
    linkedin: "",
  });

  const [day, setDay] = useState({
    sunday: "",
    monday: "",
    tuesday: "",
    wednesday: "",
    thursday: "",
    friday: "",
    saturday: "",
  });
  var d = new Date();
  var n = d.getFullYear();

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    fetch(`${baseUrl}/site/social-list/`, { signal })
      .then((response) => response.json())
      .then((dta) => {
        if (dta[0]) {
          setSocialSites(dta[0]);
        }
      })
      .catch((e) => "");

    fetch(`${baseUrl}/site/time-list/`, { signal })
      .then((response) => response.json())
      .then((dta) => {
        if (Array.isArray(dta) && dta.length > 0) {
          setDay(dta[0]);
        }
      })
      .catch((e) => null);
    // eslint-disable-next-line
    return () => controller.abort();
  }, []);

  // get holiday hours
  const getHolidayHourDetails = (signal) => {
    axios
      .get(`${baseUrl}/site/holiday/`, { signal })
      .then((res) => {
        setHolidayHour(res.data);
      })
      .catch((err) => {
        // console.log(err.message);
      });
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    getHolidayHourDetails(signal);
    return () => controller.abort();
  }, []);


  const { facebook, twitter, instagram, linkedin } = socialSites;
  const { address, email, phone1, phone2 } = all;

  return (
    <div className="bg-black flex flex-col">
      <div className="w-full max-w-7xl mx-auto grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 py-10 md:py-16 lg:py-20 px-4 md:px-10 lg:px-16 text-center text-white gap-10">
        {/* About restaurant */}
        <div className="space-y-5">
          <div className="font-header text-2xl md:text-3xl text-primary">
            THE MOMO WORLD
          </div>
          <div className="font-bold">AUTHENTIC HIMALAYAN STREET FOOD</div>
          <div className="flex justify-center space-x-4 mt-2">
            {facebook && (
              <a
                href={facebook}
                target="_blank"
                rel="noreferrer"
                className="border-2 p-2 border-primary rounded-full cursor-pointer"
              >
                <FacebookIcon />
              </a>
            )}

            {twitter && (
              <a
                href={twitter}
                target="_blank"
                rel="noreferrer"
                className="border-2 p-2 border-primary rounded-full cursor-pointer"
              >
                <TwitterIcon />
              </a>
            )}

            {instagram && (
              <a
                href={instagram}
                target="_blank"
                rel="noreferrer"
                className="border-2 p-2 border-primary rounded-full cursor-pointer"
              >
                <InstagramIcon />
              </a>
            )}

            {linkedin && (
              <a
                href={linkedin}
                target="_blank"
                rel="noreferrer"
                className="border-2 p-2 border-primary rounded-full cursor-pointer"
              >
                <LinkedInIcon />
              </a>
            )}
          </div>
          <div>
            <img src={barCodeForMenuLists} alt="" className="w-28 mx-auto" />
          </div>
        </div>

        {/* Opening Hours */}
        <div className="space-y-5">
          <div className="text-2xl font-bold mt-8 lg:mt-0 text-primary">
            OPENING HOURS
          </div>
          <div className="flex flex-col space-y-2 text-sm ">
            {arr.map((d, index) => {
              let time = day[d].split("-");
              let closeTime = "",
                openTime = "",
                isClosed = false;
              if (time.length === 2) {
                openTime = time[0];
                closeTime = time[1];
              } else {
                isClosed = true;
              }

              if (openTime) {
                let start = +openTime.slice(0, 2);
                let period = "AM";
                if (start === 12) {
                  period = "PM";
                } else if (start === 24) {
                  start = "12";
                } else if (start > 11) {
                  start -= 12;
                  period = "PM";
                  if (String(start).length === 1) {
                    start = `0${start}`;
                  }
                }

                openTime = `${start}:${openTime.slice(3, 5)} ${period}`;
              }

              if (closeTime) {
                let start = +closeTime.slice(0, 2);
                let period = "AM";
                if (start === 12) {
                  period = "PM";
                } else if (start === 24) {
                  start = "12";
                } else if (start > 11) {
                  start -= 12;
                  period = "PM";
                  if (String(start).length === 1) {
                    start = `0${start}`;
                  }
                }
                closeTime = `${start}:${closeTime.slice(3, 5)} ${period}`;
              }

              return (
                <div
                  className="flex justify-center space-x-4  lg:pl-5"
                  key={index}
                >
                  <div className="w-24">
                    <div className="uppercase">{arr[index]}</div>
                  </div>

                  {/* If the restaurant is not closed */}
                  {!isClosed && (
                    <div className="w-36 flex-shrink-0 text-green-600 whitespace-pre font-bold">
                      {`${openTime}  -  ${closeTime}`}
                    </div>
                  )}

                  {/* If restaurant is closed */}
                  {isClosed && <div className="w-32 text-red-600">Closed</div>}
                </div>
              );
            })}
          </div>
          <div className="text-red-500">
          Note:  Kitchen closes 15 minutes prior to closing time
          </div>
        </div>

        {/* holiday Hours */}
        <div className="space-y-5">
          <div className="text-2xl font-bold mt-8 lg:mt-0 text-primary">
            HOLIDAY HOURS
          </div>
          <div className="flex flex-col space-y-2 text-sm ">
            {holidayHour.map((holidayHour) => {
              const { id, start_date, end_date, remarks } = holidayHour;
              return (
                <div className="flex justify-center space-x-2  " key={id}>
                  {/* timestamp */}
                  <div className="">
                    <div>{start_date}</div>
                  </div>
                  <div>To</div>
                  {/* If the restaurant is not closed */}
                  <div className=" flex-shrink-0whitespace-pred">
                    <div>{end_date}</div>
                  </div>
                  {/* holiday remarks */}
                  <div className="text-red-600 overflow-hidden">{remarks}</div>
                </div>
              );
            })}
          </div>
        </div>

        {/* Get in touch */}
        <div className="space-y-5 mt-8 lg:mt-0 ">
          <div className="text-2xl font-bold text-primary">GET IN TOUCH</div>
          <div className="space-y-2 text-sm">
            {address && <div>{address}</div>}
            {email && <div>{email}</div>}
            {phone1 && <div>{phone1}</div>}
            {phone2 && <div>{phone2}</div>}
          </div>
          <div className="button-animation">
            <Link
              onClick={() => window.scrollTo(0, 0)}
              to="/contact-us"
              className="animation-text px-4 py-2"
            >
              Contact Us
            </Link>
            <div className="animation-bg"></div>
          </div>
        </div>
      </div>
      <div className="flex  flex-col text-white mb-10">
        <div className="mx-auto font-semibold">
          Copyright &copy; {n} MOMO world. All Right Reserved.
        </div>
        <div className="mx-auto font-semibold">
          Powered by {'  '}
          <a
            href="http://mervixtech.com/"
            target="new tab"
            className="text-blue-500"
          >
            Mervix Technologies
          </a>
        </div>
      </div>
    </div>
  );
}
