import React, { useEffect, useState } from "react";
import { baseUrl, headers } from "../../authentication/authorization";
import PopupMessage from "../../common/popup-message";
import Processing from "../../common/processing";

let arr = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export default function Time() {
  const [day, setDay] = useState({
    sunday: "",
    monday: "",
    tuesday: "",
    wednesday: "",
    thursday: "",
    friday: "",
    saturday: "",
  });
  const [alert, setAlert] = useState("");
  const [saveLoader, setSaveLoader] = useState(false);
  const [id, setId] = useState("");

  const handleTimeSubmit = (event) => {
    event.preventDefault();
    if (!saveLoader) {
      setSaveLoader(true);
      const formData = new FormData();
      for (let i = 0; i < arr.length; i++) {
        let element = document.querySelector(`#${arr[i]}`);
        let isClosed = element.querySelector(".isClosed").checked;
        let openTime = element.querySelector(".openTime").value;
        let closeTime = element.querySelector(".closeTime").value;
        let value;
        if (isClosed) {
          value = "";
        } else {
          value = openTime + "-" + closeTime;
        }
        formData.append(arr[i], value);
      }
      fetch(`${baseUrl}/site/time-${id !== "" ? "edit/" + id : "add/"}`, {
        method: id !== "" ? "PUT" : "POST",
        body: formData,
        headers: headers(),
      })
        .then((response) => {
          const { status } = response;
          setSaveLoader(false);
          if (status > 199 && status < 300) {
            setAlert(`Successfully ${id !== "" ? "edited" : "added"} the time`);
          }
        })
        .catch((error) => setSaveLoader(false));
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const getTime = async () => {
      await fetch(`${baseUrl}/site/time-list`, {
        method: "GET",
        headers: headers(),
        signal,
      })
        .then((response) => response.json())
        .then((dta) => {
          const { code } = dta;
          if (code === "token_not_valid") {
            localStorage.clear();
            window.location = "/login";
          } else if (Array.isArray(dta) && dta.length > 0) {
            const {
              id,
              sunday,
              monday,
              tuesday,
              wednesday,
              thursday,
              friday,
              saturday,
            } = dta[0];
            setDay({
              sunday,
              monday,
              tuesday,
              wednesday,
              thursday,
              friday,
              saturday,
            });
            setId(id);
          }
        })
        .catch(() => null);
    };
    getTime();
    return () => controller.abort();
  }, []);

  return (
    <>
      {alert !== "" && (
        <PopupMessage message={alert} handleClose={() => setAlert("")} />
      )}
      <div className="px-5 py-10 overflow-auto">
        <form
          className="max-w-4xl flex flex-col space-y-4 mx-auto bg-white py-5 px-10 shadow rounded"
          onSubmit={handleTimeSubmit}
        >
          <div className="mx-auto text-xl font-semibold text-center">
            Opening And Closing Time Input Field
          </div>
          {arr.map((d, index) => {
            let time = day[d].split("-");
            let closeTime = "",
              openTime = "",
              isClosed = false;
            if (time.length === 2) {
              openTime = time[0];
              closeTime = time[1];
            } else {
              isClosed = true;
            }

            return (
              <div
                className="sm:flex items-center sm:space-x-4"
                key={index}
                id={arr[index]}
              >
                <span className="mt-5 font-semibold tracking-wide w-28">
                  {arr[index].toUpperCase()}
                </span>
                <div className="sm:flex sm:space-x-2 items-center">
                  <div className="flex flex-col">
                    <label htmlFor="" className="text-gray-600 text-sm">
                      Opening Time
                    </label>
                    <input
                      type="time"
                      className="input-style2 py-0 md:w-40 openTime"
                      defaultValue={openTime || ""}
                      required={!isClosed}
                    />
                  </div>
                  <div className="flex flex-col">
                    <label htmlFor="" className="text-gray-600 text-sm">
                      Closing Time
                    </label>
                    <input
                      type="time"
                      className="input-style2 md:w-40 closeTime"
                      defaultValue={closeTime || ""}
                      required={!isClosed}
                    />
                  </div>
                  <div className="flex flex-col">
                    <label htmlFor="" className="text-gray-600 text-sm">
                      is_closed
                    </label>
                    <input
                      type="checkbox"
                      className="h-6 w-6 isClosed"
                      checked={isClosed}
                      onChange={() => {
                        setDay({
                          ...day,
                          [arr[index]]: day[arr[index]] === "" ? " - " : "",
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })}

          {/* button  */}
          <div className="">
            <button className="bg-indigo-500 flex justify-center items-center space-x-2 px-5 py-2 w-full sm:w-auto rounded text-white hover:bg-indigo-600 focus:outline-none">
              <div>{saveLoader ? "Saving" : "Save"}</div>
              {saveLoader && <Processing />}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
