import { React, useState, useRef, useEffect } from "react";
import Aboutus from "../../about-us/aboutComponent";
import { baseUrl, headers } from "../../authentication/authorization";
import Processing from "../../common/processing";

function AboutUs() {
  const [saveLoader, setSaveLoader] = useState(false);
  const aboutUsImage = useRef();
  const form = useRef();
  const [aboutError, setAboutError] = useState("");
  const [aboutSuccess, setAboutSuccess] = useState("");
  const [edit, setEdit] = useState(false);
  const [errors, setErrors] = useState({
    title: "",
    description: "",
    image: "",
  });

  const [defaultAboutUs, setDefaultAboutUs] = useState({
    title: "",
    description: "",
    img: "",
    id: "",
  });

  const [about, setAbout] = useState({
    title: "",
    description: "",
    image: "",
  });

  const handleErrors = (property, value) => {
    let result;
    value = value === undefined ? about[property] : value;
    if (value.trim() === "") {
      errors[property] = `${property[0].toUpperCase()}${property.slice(
        1,
        property.length
      )} cannot be left empty`;
      result = false;
    } else {
      if (property === "title") {
        errors.title = "";
        result = true;
      } else if (property === "description") {
        errors.description = "";
        result = true;
      }
    }

    setErrors(errors);
    return result;
  };

  const handleChange = ({ target: { value } }, property) => {
    handleErrors(property, value);
    setAbout({
      ...about,
      [property]: value,
    });
  };

  const getDefaultAboutUs = async () => {
    await fetch(`${baseUrl}/site/aboutus-list/`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((dta) => {
        if (dta && Array.isArray(dta) && dta[0]) {
          setDefaultAboutUs({
            title: dta[0].title,
            description: dta[0].story,
            img: dta[0].img,
            id: dta[0].id,
          });
        }
      })
      .catch(() => null);
  };

  const submitForm = (event) => {
    event.preventDefault();
    const { title, description, image } = about;

    setAboutSuccess("");
    if (!saveLoader) {
      const credentials = ["title", "description"];
      let goAhead;
      for (let i = 0; i < credentials.length; i++) {
        const result = handleErrors(credentials[i]);
        if (goAhead !== false) {
          goAhead = result;
        }
      }

      if (!image && !edit) {
        setAboutError("Add image");
        goAhead = false;
      }

      if (goAhead) {
        setSaveLoader(true);
        const formData = new FormData();
        formData.append("title", title);
        formData.append("story", description);
        image && formData.append("img", image);
        const { id } = defaultAboutUs;
        if (edit) {
          fetch(`${baseUrl}/site/aboutus-edit/${id}`, {
            method: "PUT",
            body: formData,
            headers: headers(),
          })
            .then((response) => {
              if (response.ok) {
                setEdit(false);
                getDefaultAboutUs();
                setAboutSuccess("Successfully edited the about us content");
                aboutUsImage.current.innerHTML = "Select Image";
                setAbout({
                  title: "",
                  description: "",
                  image: "",
                });
              }
              setSaveLoader(false);
            })
            .catch(() => null);
        } else if (id && id !== 0) {
          fetch(`${baseUrl}/site/aboutus-edit/${id}`, {
            method: "PUT",
            body: formData,
            headers: headers(),
          })
            .then((response) => {
              if (response.ok) {
                setAboutSuccess("Successfully edited the about us contents");
                setAboutError("");
                getDefaultAboutUs();
                setAbout({
                  title: "",
                  description: "",
                  image: "",
                });
                aboutUsImage.current.innerHTML = "Select Image";
              } else {
                setAboutError("Something went wrong");
              }

              setSaveLoader(false);
            })
            .catch(() => setSaveLoader(false));
        } else {
          fetch(`${baseUrl}/site/aboutus-add/`, {
            method: "POST",
            body: formData,
            headers: headers(),
          })
            .then((response) => {
              if (response.ok) {
                setAboutSuccess("Successfully added the about us contents");
                getDefaultAboutUs();
              } else {
                setAboutError("Something went wrong");
              }

              setSaveLoader(false);
            })
            .catch(() => setSaveLoader(false));
        }
      }
    }
  };

  const handleAboutEdit = () => {
    setEdit(true);
    form.current.scrollIntoView();
    const { title, description, img } = defaultAboutUs;
    aboutUsImage.current.innerHTML = img;
    setAbout({
      title,
      description,
    });
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const getDefaultAboutUs = async () => {
      await fetch(`${baseUrl}/site/aboutus-list/`, {
        method: "GET",
        signal,
      })
        .then((response) => response.json())
        .then((dta) => {
          if (dta && Array.isArray(dta) && dta[0]) {
            setDefaultAboutUs({
              title: dta[0].title,
              description: dta[0].story,
              img: dta[0].img,
              id: dta[0].id,
            });
          }
        })
        .catch(() => null);
    };
    getDefaultAboutUs();

    return () => controller.abort();
  }, []);

  const { description, title } = about;
  const {
    title: title1,
    description: description1,
    img: img1,
    id: id1,
  } = defaultAboutUs;

  const { title: titleErr, description: descriptionErr } = errors;

  return (
    <div className="px-5 py-10 overflow-auto">
      <form
        className="max-w-xl flex flex-col space-y-4 mx-auto bg-white p-5 shadow rounded"
        ref={form}
      >
        <div className="mx-auto text-xl font-semibold">
          About Us Input Field
        </div>
        <div className="flex flex-col space-y-2">
          <label htmlFor="" className="text-gray-700 text-sm font-semibold">
            Title
          </label>
          <input
            type="text"
            value={title}
            className="border w-full p-3 focus:outline-none focus:border-indigo-500"
            onChange={(event) => {
              setAboutSuccess("");
              setAboutError("");
              handleChange(event, "title");
            }}
          />
          {titleErr && <div className="error">{titleErr}</div>}
        </div>
        <div className="flex flex-col space-y-2">
          <label htmlFor="" className="text-gray-700 text-sm font-semibold">
            Description
          </label>
          <textarea
            className="border w-full h-24 p-3 focus:outline-none focus:border-indigo-500"
            value={description}
            onChange={(event) => {
              setAboutSuccess("");
              setAboutError("");
              handleChange(event, "description");
            }}
          ></textarea>
          {descriptionErr && <div className="error">{descriptionErr}</div>}
        </div>
        <div className="flex flex-col space-y-2 relative ">
          <label className="text-gray-700 text-sm font-semibold">
            About Us Image
          </label>

          <label
            htmlFor="input-file"
            className="bg-gray-50  p-2.5 w-full text-gray-500 text-sm overflow-hidden"
            ref={aboutUsImage}
          >
            Select Image
          </label>
          <input
            type="file"
            id="input-file"
            className="border w-full hidden"
            onChange={(event) => {
              const file = event.target.files[0];
              const fileName = file.name;
              const image = new Image();
              image.src = URL.createObjectURL(file);
              image.onload = function () {
                let arr = fileName.split(".");
                let extension = arr[arr.length - 1];
                const extensions = ["png", "jpg", "jpeg", "webp"];
                let bool = false;
                for (let i = 0; i < extensions.length; i++) {
                  if (extensions[i] === extension.toLowerCase()) {
                    bool = true;
                    i = extensions.length;
                  }
                }
                if (bool) {
                  setAbout({
                    ...about,
                    image: file,
                  });
                  setAboutError("");
                } else {
                  setAbout({
                    ...about,
                    image: "",
                  });
                  setAboutError("Invalid file");
                }
              };

              image.onerror = function () {
                setAbout({
                  ...about,
                  image: "",
                });
                setAboutError("Invalid file");
              };
              aboutUsImage.current.innerHTML = fileName;
            }}
          />
          <label
            htmlFor="input-file"
            className="bg-gray-200 py-2 px-4 absolute top-5 right-0 cursor-pointer"
          >
            Browse...
          </label>
          {aboutError && (
            <div className="text-red-500 text-sm">{aboutError}</div>
          )}
          {aboutSuccess && (
            <div className="text-green-500 text-sm">{aboutSuccess}</div>
          )}
        </div>
        {/* button  */}
        <div className="">
          <button
            className="bg-indigo-500 flex justify-center items-center space-x-2 px-5 py-2 w-full sm:w-auto rounded text-white hover:bg-indigo-600 focus:outline-none"
            onClick={submitForm}
          >
            <div>{saveLoader ? "Saving" : "Save"}</div>
            {saveLoader && <Processing />}
          </button>
        </div>
      </form>

      {/* current aboutus  */}
      {defaultAboutUs.id && (
        <div className="flex flex-col  mt-10 bg-white p-5">
          <div className="sm:flex justify-between p-5 space-y-3 sm:space-y-0">
            <div className=" text-2xl font-semibold">Review About Us</div>
            <div className="flex space-x-4">
              <button
                onClick={handleAboutEdit}
                className="bg-green-500 px-5 py-2 text-white hover:bg-green-600 rounded-md shadow-md"
              >
                Edit
              </button>
            </div>
          </div>
          <Aboutus
            title1={title1}
            description1={description1}
            img1={img1}
            id1={id1}
          />
        </div>
      )}
    </div>
  );
}

export default AboutUs;
