import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { baseUrl, headers } from "../../authentication/authorization";
import DeleteConfirmationPopup from "../../common/delete-confirmation";
import PopupMessage from "../../common/popup-message";

function ImageSlider() {
  const bannerImage = useRef();
  const isAvailable = useRef();
  const [banner, setBanner] = useState("");
  const [bannerId, setBannerId] = useState("");
  const [state, setState] = useState({
    bannerImages: [],
    bannerError: "",
    saveLoader: false,
    deletePopup: false,
    alertPopup: false,
    alertMessage: "",
  });
  async function getBanners(message, loaders) {
    await fetch(`${baseUrl}/site/slider-list`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((dta) => {
        dta.forEach((d) => {
          d.deleting = false;
        });
        let { alertMessage, alertPopup, saveLoader } = state;
        if (message) {
          alertMessage = message;
          alertPopup = true;
          saveLoader = false;
        }
        setState({
          ...state,
          bannerImages: dta,
          alertMessage,
          alertPopup,
          saveLoader,
        });
      })
      .catch(() => null);
  }
  const submitForm = async (event) => {
    event.preventDefault();
    const { bannerError, saveLoader } = state;
    if (!saveLoader) {
      if (bannerId) {
        setState((state) => ({
          ...state,
          saveLoader: true,
        }));
        const formData = new FormData();
        banner && formData.append("img", banner);
        formData.append("status", isAvailable.current.checked);
        await fetch(`${baseUrl}/site/slider-delete/${bannerId}/`, {
          method: "PATCH",
          body: formData,
          headers: headers(),
        })
          .then((response) => {
            if (response.ok) {
              bannerImage.current.innerHTML = "Select Image";
              setBanner("");
              getBanners("Successfully added the image", false);
            }
            window.location.reload();
          })
          .catch(() =>
            setState((state) => ({
              ...state,
              saveLoader: false,
            }))
          );
      } else if (banner) {
        setState({
          ...state,
          saveLoader: true,
        });
        const formData = new FormData();
        formData.append("img", banner);
        formData.append("status", isAvailable.current.checked);
        await fetch(`${baseUrl}/site/slider-add/`, {
          method: "POST",
          body: formData,
          headers: headers(),
        })
          .then((response) => {
            if (response.ok) {
              bannerImage.current.innerHTML = "Select Image";
              setBanner("");
              getBanners("Successfully added the image", false);
            }
            window.location.reload();
          })
          .catch(() =>
            setState((state) => ({
              ...state,
              saveLoader: false,
            }))
          );
      } else if (!bannerError) {
        setState((state) => ({
          ...state,
          bannerError: "slider image is required",
        }));
      }
    }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    async function getBanners(message) {
      await fetch(`${baseUrl}/site/slider-list`, {
        method: "GET",
        signal,
      })
        .then((response) => response.json())
        .then((dta) => {
          dta.forEach((d) => {
            d.deleting = false;
          });
          if (message) {
            setState((state) => ({
              ...state,
              bannerImages: dta,
              alertMessage: message,
              alertPopup: true,
              saveLoader: false,
            }));
          } else {
            setState((state) => ({
              ...state,
              bannerImages: dta,
            }));
          }
        })
        .catch(() => null);
    }
    getBanners();

    return () => controller.abort();
  }, []);
  const handleBannerDelete = async (action) => {
    if (action === "delete") {
      let {
        deletePopup: { id, index },
        bannerImages,
      } = state;
      bannerImages[index].deleting = true;
      setState((state) => ({
        ...state,
        deletePopup: false,
        bannerImages,
      }));

      await fetch(`${baseUrl}/site/slider-delete/${id}`, {
        method: "DELETE",
        headers: headers(),
      })
        .then((response) => {
          if (response.ok) {
            bannerImages.splice(index, 1);
            setState({
              ...state,
              bannerImages,
            });
          }
        })
        .catch(() => null);
    }
    setState({
      ...state,
      deletePopup: false,
    });
  };
  const handleEdit = (id) => {
    axios
      .get(`${baseUrl}/site/slider-delete/${id}`, {
        headers: headers(),
      })
      .then((res) => {
        setBannerId(res.data.id);
        isAvailable.current.checked = res.data.status;
        bannerImage.current.innerHTML = res.data.img;
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const {
    bannerImages,
    bannerError,
    saveLoader,
    deletePopup,
    alertPopup,
    alertMessage,
  } = state;

  return (
    <>
      {deletePopup !== false && (
        <DeleteConfirmationPopup
          handleDelete={handleBannerDelete}
          message="Are you sure that you want to delete this banner ?"
        />
      )}
      {alertPopup !== false && (
        <PopupMessage
          message={alertMessage}
          handleClose={() =>
            setState({
              ...state,
              alertPopup: false,
            })
          }
        />
      )}
      <div className="px-5 py-10 overflow-auto">
        <form className="max-w-xl flex flex-col space-y-4 mx-auto bg-white p-5 shadow rounded">
          <div className="mx-auto text-xl font-semibold">
            Image Slider Input Field
          </div>

          <div className="flex flex-col space-y-2 relative ">
            <label className="text-gray-700 text-sm font-semibold">
              Slider Image
            </label>

            <label
              htmlFor="input-file"
              className="bg-gray-50  p-2.5 w-full text-gray-500 text-sm overflow-hidden"
              ref={bannerImage}
            >
              {/* {banner ? banner : "Select Image"} */}
              Select Image
            </label>
            <input
              type="file"
              id="input-file"
              className="border w-full hidden"
              onChange={(event) => {
                const file = event.target.files[0];
                const fileName = file.name;
                const image = new Image();
                image.src = URL.createObjectURL(file);
                image.onload = function () {
                  let arr = fileName.split(".");
                  let extension = arr[arr.length - 1];
                  const extensions = ["png", "jpg", "jpeg", "webp"];
                  let bool = false;
                  for (let i = 0; i < extensions.length; i++) {
                    if (extensions[i] === extension.toLowerCase()) {
                      bool = true;
                      i = extensions.length;
                    }
                  }
                  if (bool) {
                    setState({
                      ...state,
                      bannerError: "",
                    });
                    setBanner(file);
                  } else {
                    setBanner("");
                    setState({
                      ...state,
                      bannerError: "Invalid file",
                    });
                  }
                };

                image.onerror = function () {
                  setBanner("");
                  setState({
                    ...state,
                    bannerError: "Invalid file",
                  });
                };
                bannerImage.current.innerHTML = fileName;
              }}
            />
            <label
              htmlFor="input-file"
              className="bg-gray-200 py-2 px-4 absolute top-5 right-0 cursor-pointer text-gray-600"
            >
              Browse...
            </label>
            {bannerError && (
              <div className="text-red-500 text-sm">{bannerError}</div>
            )}
          </div>
          <div className="text-gray-700 space-x-1">
            <input type="checkbox" ref={isAvailable} id="is_available" />{" "}
            <label htmlFor="is_available">Display</label>
          </div>
          {/* button  */}
          <div className="">
            <button
              className={`flex space-x-2 items-center px-5 py-2 w-full sm:w-auto rounded text-white focus:outline-none ${
                saveLoader
                  ? "bg-indigo-400 hover:bg-none cursor-default"
                  : "cursor-pointer bg-indigo-500 hover:bg-indigo-600"
              }`}
              onClick={submitForm}
              disabled={saveLoader}
            >
              {bannerId ? (
                <div>{saveLoader ? "Updating" : "Update"}</div>
              ) : (
                <div>{saveLoader ? "Saving" : "Save"}</div>
              )}

              {saveLoader && (
                <div className="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              )}
            </button>
          </div>
        </form>
        {/* current aboutus  */}
        {Array.isArray(bannerImages) && bannerImages.length > 0 && (
          <div className="flex flex-col  mt-10 bg-white p-5">
            <div className=" text-2xl font-semibold pl-4">
              Review slider images
            </div>
            <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6  p-4">
              {bannerImages.map((bannerImage, index) => {
                const { img, id, deleting } = bannerImage;
                return (
                  <div
                    className={`group col-span-1 relative  overflow-hidden cursor-pointer h-40 ${
                      !deleting && "gallery-item"
                    }`}
                    key={index}
                  >
                    <img
                      src={img}
                      alt=""
                      className="transition-all duration-500 w-full h-full object-cover"
                    />
                    {deleting && (
                      <div className="w-full top-0 h-full bg-black bg-opacity-40 absolute z-50 flex items-center justify-center text-white">
                        <div className="pb-1 pr-2">Deleting</div>
                        <div className="lds-ring">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    )}
                    {!deleting && (
                      <>
                        <div className="absolute bg-primary w-full h-full top-0 opacity-0 group-hover:opacity-60 transition-all duration-300 ease-in-out"></div>
                        <div className="absolute top-0 w-full h-full flex justify-center items-center space-x-2">
                          <button
                            className="bg-green-500 text-white px-3 py-1 rounded-md shadow-md opacity-0 group-hover:opacity-95 transition-all duration-300 ease-in-out hover:bg-green-600"
                            onClick={() => handleEdit(id)}
                          >
                            Edit
                          </button>
                          <button
                            className="bg-red-600 text-white px-3 py-1 rounded-md shadow-md opacity-0 group-hover:opacity-95 transition-all duration-300 ease-in-out hover:bg-red-700"
                            onClick={() =>
                              setState({
                                ...state,
                                deletePopup: {
                                  index,
                                  id,
                                },
                              })
                            }
                          >
                            Delete
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ImageSlider;
