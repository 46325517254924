import { useState } from "react";
import { baseUrl } from "../authentication/authorization";
import Processing from "../common/processing";

const ContactComponent = () => {
  const [saveLoader, setSaveLoader] = useState(false);
  const [contact, setContact] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    errors: {
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    },
  });

  const [success, setSuccess] = useState("");

  const handleErrors = (property, value) => {
    const { errors } = contact;
    value = value === undefined ? contact[property] : value;
    let result;
    if (value.trim() === "") {
      errors[property] = `${property[0].toUpperCase()}${property.slice(
        1,
        property.length
      )} cannot be left empty`;
      result = false;
    } else {
      if (property === "name") {
        errors.name = "";
        result = true;
      } else if (property === "email") {
        if (!value.match(/^\w+@\w+\.\w+(\.\w+)?$/gi)) {
          errors.email = "Invalid email";
          result = false;
        } else {
          errors.email = "";
          result = true;
        }
      } else if (property === "phone") {
        errors.phone = "";
        result = true;
      } else if (property === "subject") {
        errors.subject = "";
        result = true;
      } else if (property === "message") {
        errors.message = "";
        result = true;
      }
    }

    setContact({
      ...contact,
      errors,
    });
    return result;
  };

  const handleChange = ({ target: { value } }, property) => {
    handleErrors(property, value);
    setSuccess("");
    setContact({
      ...contact,
      [property]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!saveLoader) {
      setSuccess("");
      const url = `${baseUrl}/site/contactus-add/`;
      const { name, email, phone, subject, message } = contact;
      const credentials = ["name", "email", "phone", "subject", "message"];
      let goAhead;
      for (let i = 0; i < credentials.length; i++) {
        const result = handleErrors(credentials[i]);
        if (goAhead !== false) {
          goAhead = result;
        }
      }

      if (goAhead) {
        setSaveLoader(true);
        // submitting the form if all input fields are validated
        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("phone", phone);
        formData.append("subject", subject);
        formData.append("message", message);
        fetch(url, {
          method: "POST",
          body: formData,
        })
          .then((response) => {
            setSaveLoader(false);
            if (response.ok) {
              setSuccess("Successfully sent your message");
              setContact({
                name: "",
                email: "",
                phone: "",
                subject: "",
                message: "",
                errors: {
                  name: "",
                  email: "",
                  phone: "",
                  subject: "",
                  message: "",
                },
              });
            }
          })
          .catch(() => setSaveLoader(false));
      }
    }
  };

  const {
    name,
    email,
    phone,
    subject,
    message,
    errors: {
      name: nameErr,
      email: emailErr,
      phone: phoneErr,
      subject: subjectErr,
      message: messageErr,
    },
  } = contact;

  return (
    <div className="py-10 px-5 lg:px-20 my-20 lg:flex lg:space-x-4 space-y-10 lg:space-y-0">
      {/* contact side  */}
      <form
        onSubmit={handleSubmit}
        className="flex flex-col lg:px-10 md:space-y-5 authentication-form lg:w-1/2"
      >
        <div className="w-full text-2xl md:text-4xl text-center pb-10 font-header">
          Contact Form
        </div>
        <div className="md:flex md:space-x-5 space-y-5 md:space-y-0">
          <div className="w-full space-y-3">
            <label htmlFor="" className="text-gray-600">
              Name*
            </label>
            <input
              type="text"
              className="input-style"
              value={name}
              onChange={(event) => handleChange(event, "name")}
            />
            {nameErr && <div className="error">{nameErr}</div>}
          </div>

          <div className="w-full space-y-3">
            <label htmlFor="" className="text-gray-600">
              Email*
            </label>
            <input
              type="text"
              className=" input-style"
              value={email}
              onChange={(event) => handleChange(event, "email")}
            />
            {emailErr && <div className="error">{emailErr}</div>}
          </div>
        </div>
        <div className="md:flex md:space-x-5 space-y-5 md:space-y-0">
          <div className="w-full space-y-3">
            <label htmlFor="" className="text-gray-600">
              Phone*
            </label>
            <input
              type="text"
              className=" input-style"
              value={phone}
              onChange={(event) => handleChange(event, "phone")}
            />
            {phoneErr && <div className="error">{phoneErr}</div>}
          </div>
          <div className="w-full space-y-3">
            <label htmlFor="" className="text-gray-600">
              Subject
            </label>
            <input
              type="text"
              className=" input-style"
              value={subject}
              onChange={(event) => handleChange(event, "subject")}
            />
            {subjectErr && <div className="error">{subjectErr}</div>}
          </div>
        </div>
        <div className="w-full space-y-3">
          <label htmlFor="" className="text-gray-600">
            Write Message
          </label>
          <textarea
            name=""
            className="w-full py-2 px-3 border-2 border-gray-300 focus:border-gray-400 focus:outline-none"
            id=""
            cols="30"
            rows="8"
            value={message}
            onChange={(event) => handleChange(event, "message")}
          ></textarea>
          {messageErr && <div className="error">{messageErr}</div>}
        </div>
        {success && <div className="text-green-500 text-sm">{success}</div>}
        <div>
          <button className="button-animation">
            <div className="animation-text px-8 py-2">
              <div className="flex justify-center space-x-2 items-center">
                <div>{saveLoader ? "Sending" : "Send"}</div>
                {saveLoader && <Processing />}
              </div>
            </div>
            <div className="animation-bg"></div>
          </button>
        </div>
      </form>
      <div className="flex-1">
        <iframe
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2971.30984926955!2d-87.6482994850565!3d41.86467957423377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e2cf049fcb1db%3A0x15a52817f9ad38fd!2s727%20W%20Maxwell%20St%2C%20Chicago%2C%20IL%2060607%2C%20USA!5e0!3m2!1sen!2snp!4v1624864253494!5m2!1sen!2snp"
          title="727 West Maxwell St ,Chicago,IL"
          className="w-full h-96 lg:h-full"
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactComponent;
