import { useState, useEffect } from "react";
import { baseUrl } from "../authentication/authorization";
import leaf from "../../assets/images/homepage/leaf.png";
import Processing from "../common/processing";
import GalleryImage from "./gallery-image";

export default function AllMenus() {
  const [galleryImages, setGalleryImages] = useState([]);
  const [saveLoader, setSaveLoader] = useState(false);
  const [next, setNext] = useState("");
  const [popupImage, setPopupImage] = useState("");

  const getGallery = async (url) => {
    if (!saveLoader) {
      setSaveLoader(true);
      fetch(url, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((dta) => {
          const { results, next } = dta;
          if (Array.isArray(results)) {
            setGalleryImages((g) => [...g, ...results]);
            setNext(next);
          }
          setSaveLoader(false);
        })
        .catch(() => setSaveLoader(false));
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const getGallery = async () => {
      await fetch(`${baseUrl}/site/gallery-list/`, {
        method: "GET",
        signal,
      })
        .then((response) => response.json())
        .then((dta) => {
          const { results, next } = dta;
          if (Array.isArray(results)) {
            setGalleryImages(results);
            setNext(next);
          }
        })
        .catch(() => setSaveLoader(false));
    };

    getGallery();

    return () => controller.abort();
  }, []);

  return (
    <>
      {popupImage !== "" && (
        <GalleryImage img={popupImage} handleClose={() => setPopupImage("")} />
      )}
      <div className="max-w-7xl mx-auto py-10 md:py-16 lg:py-20 px-4 md:px-10 lg:px-16">
        <div>
          <div className=" text-3xl md:text-4xl lg:text-6xl text-center font-header text-gray-800">
            Our Gallery
          </div>
          <div className="mt-4">
            <img src={leaf} alt="" className="mx-auto" />
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-10 lg:gap-16 pt-10 lg:pt-16">
          {galleryImages.map((galleryImage, index) => {
            const { name, img } = galleryImage;
            return (
              <div
                key={index}
                className="relative gallery-item cursor-pointer overflow-hidden"
                onClick={() => setPopupImage(img)}
              >
                <div>
                  <img
                    src={img}
                    alt=""
                    className="w-full h-72 object-cover transition-all duration-500"
                  />
                </div>
                <div className="gallery-item-name opacity-0 text-3xl  absolute w-full h-full top-0 bg-primary bg-opacity-50 text-white transition-all duration-500 grid place-items-center">
                  {name}
                </div>
              </div>
            );
          })}
        </div>
        {next && (
          <div className="mt-4 md:mt-8 lg:mt-12 w-full">
            <button
              className="mx-auto flex items-center justify-center space-x-2 bg-primary text-white px-5 py-3 rounded-md shadow-md transition-all duration-300 ease-in-out hover:bg-"
              onClick={() => getGallery(next)}
            >
              <div>{saveLoader ? "Loading" : "Load More"}</div>
              {saveLoader && <Processing />}
            </button>
          </div>
        )}
      </div>
    </>
  );
}
