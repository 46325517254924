export default function PopupMessage({ message, handleClose }) {
  return (
    <div className="fixed left-0 top-0 min-h-screen w-screen z-50 grid place-items-center">
      <div
        className="fixed bg-black bg-opacity-50 left-0 top-0 h-screen w-screen z-40"
        onClick={handleClose}
      ></div>
      <div className="bg-white p-4 rounded z-50">
        <div>{message}</div>
        <div className="mt-2 text-right">
          <div
            className="inline-block bg-blue-700 h-10 leading-10 hover:bg-blue-800 cursor-pointer text-white px-3 rounded"
            onClick={handleClose}
          >
            OK
          </div>
        </div>
      </div>
    </div>
  );
}
