import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { baseUrl } from "./authorization";
import Processing from "../common/processing";
import backgroundImage from "../../assets/images/authentication/login-background.jpg";
import logo from "../../assets/images/dashboard/logo.jpeg";

export default function EmailVerify(props) {
  const [saveLoader, setSaveLoader] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  function verifyEmail() {
    fetch(`${baseUrl}/user/email-verify/?${props.match.params.url}`, {
      method: "GET",
    })
      .then((response) => {
        if (response.ok) {
          setSuccess("true");
          setError("");
        }
        setSaveLoader(false);
      })
      .catch(() => {
        setError("We could not connect to the server");
        setSuccess("");
        setSaveLoader(false);
      });
  }

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    function verifyEmail() {
      fetch(`${baseUrl}/user/email-verify/?${props.match.params.url}`, {
        method: "GET",
        signal,
      })
        .then((response) => {
          if (response.ok) {
            setSuccess("true");
            setError("");
          }
          setSaveLoader(false);
        })
        .catch((error) => {
          setError("We could not connect to the server");
          setSuccess("");
          setSaveLoader(false);
        });
    }
    verifyEmail();
    return () => controller.abort();
  }, [props.match.params.url]);

  return (
    <div style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="w-full h-full grid place-items-center min-h-screen py-16">
        <div className="bg-white px-10 py-8 mx-5 max-w-sm w-full authentication-form">
          <div className="flex flex-col pb-2 space-y-2">
            {/* resturant logo  */}
            <div className="w-20 h-20 mx-auto">
              <img
                src={logo}
                alt=""
                className="w-full h-full rounded-full object-center object-cover"
              />
            </div>
            <div
              className={`${error && "bg-red-500"} ${
                success && "bg-green-600"
              } ${
                !(error || success) && "bg-indigo-500"
              } flex justify-center items-center space-x-2 px-5 py-2 w-full sm:w-auto rounded text-white`}
            >
              <div>
                {error && error}

                {!error &&
                  (success ? "Your email is verified" : "Verifying email")}
              </div>
              {saveLoader && <Processing />}
            </div>
            {!error && !success && (
              <div className="text-center">Please wait few seconds</div>
            )}
            {success && (
              <Link to="/login" className="underline text-sm text-center">
                Go to login page
              </Link>
            )}
            {error && (
              <div
                className="text-sm underline text-center cursor-pointer"
                onClick={() => {
                  setError("");
                  setSaveLoader(true);
                  setSuccess("");
                  verifyEmail();
                }}
              >
                Retry
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
