import React, { useState, useEffect } from "react";
import axios from "axios";
import DeleteConfirmationPopup from "../../common/delete-confirmation";
import PopupMessage from "../../common/popup-message";
import { baseUrl, headers } from "../../authentication/authorization";
import Processing from "../../common/processing";

function Spicy() {
  const [loading, setLoading] = useState(true);
  const [spicy, setSpicy] = useState("");
  const [error, setError] = useState("");
  const [spicyLevels, setSpicyLevels] = useState([]);
  const [saveLoader, setSaveLoader] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [slug, setSlug] = useState("");
  const [alert, setAlert] = useState("");

  async function getSpicies() {
    await axios
      .get(`${baseUrl}/spicylevel/`)
      .then((res) => {
        if (Array.isArray(res.data)) {
          setSpicyLevels(res.data);
        }
      })
      .catch(() => null);
  }

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    async function getSpicies() {
      await fetch(`${baseUrl}/spicylevel/`, {
        method: "GET",
        signal,
      })
        .then((response) => response.json())
        .then((data) => {
          if (Array.isArray(data)) {
            setSpicyLevels(data);
          }
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
    getSpicies();
    return () => controller.abort();
  }, []);

  const submitForm = (e) => {
    e.preventDefault();
    if (spicy.trim() === "") {
      setError("You must add spicy level before adding");
    } else if (!saveLoader) {
      setSaveLoader(true);
      const formData = new FormData();
      formData.append("name", spicy);
      if (slug) {
        fetch(`${baseUrl}/spicylevel-edit/${slug}/`, {
          method: "PUT",
          body: formData,
          headers: headers(),
        })
          .then((res) => {
            if (res.status === 401) {
              localStorage.clear();
              window.location = "/login";
            } else if (res.status < 300) {
              getSpicies();
              setSpicy("");
            }
            setSaveLoader(false);
            return res.json();
          })
          .then((dta) => {
            const { name } = dta;
            setSaveLoader(false);
            if (
              Array.isArray(name) &&
              name[0] === "spicy level with this name already exists."
            ) {
              setAlert("Spicy Level already exists");
            }
          })
          .catch(() => {
            setSaveLoader(false);
            setAlert("could not edit the spicy level");
          });
        setSlug("");
      } else {
        setSaveLoader(true);
        fetch(`${baseUrl}/spicylevel-add/`, {
          method: "POST",
          body: formData,
          headers: headers(),
        })
          .then((response) => response.json())
          .then((data) => {
            const { code, slug, detail, name } = data;
            setSaveLoader(false);
            if (
              code === "token_not_valid" ||
              detail === "Authentication credentials were not provided."
            ) {
              localStorage.clear();
              window.location = "/login";
            } else if (
              Array.isArray(name) &&
              name[0] === "spicy level with this name already exists."
            ) {
              setAlert("Spicy level with this name already exists");
            } else if (slug) {
              getSpicies();
              setSpicy("");
            }
          })
          .catch(() => {
            setSaveLoader(false);
            setAlert("could not add the spicy level");
          });
      }
    }
  };

  const handleDeleteSpicyLevel = (action) => {
    if (action === "delete") {
      fetch(`${baseUrl}/spicylevel-edit/${slug}`, {
        method: "DELETE",
        headers: headers(),
      })
        .then((res) => {
          if (res.statusText === "OK") {
            getSpicies();
          }
        })
        .catch(() => null);
    }
    setSlug("");
    setDeletePopup(false);
  };

  const handleEdit = (slug, name) => {
    setSpicy(name);
    setSlug(slug);
  };

  return (
    <>
      {deletePopup !== false && (
        <DeleteConfirmationPopup
          handleDelete={handleDeleteSpicyLevel}
          message="Are you sure that you want to delete this spicy level ?"
        />
      )}
      {alert !== "" && (
        <PopupMessage message={alert} handleClose={() => setAlert("")} />
      )}
      <div className="flex p-8">
        <div className="w-full md:flex md:space-x-6 space-y-6 md:space-y-0">
          {/* right side  */}
          <div className=" w-full md:w-2/5 ">
            <div className="bg-white rounded-md shadow-md flex flex-col py-5 h-auto w-full">
              {/* header  */}
              <div className="px-10 border-b border-gray-300 pb-4 text-lg font-semibold uppercase  tracking-wide">
                SPICY LEVEL FORM
              </div>
              {/* form  */}
              <form className="flex flex-col px-10 py-4 space-y-4">
                {/* spicy level name  */}
                <div className="flex flex-col space-y-1">
                  <label className="text-sm font-medium text-gray-700">
                    Spicy Level
                  </label>
                  <div>
                    <input
                      type="text"
                      className="border w-full px-5 border-gray-300 focus:border-indigo-500 py-1.5 text-gray-700 text-sm"
                      value={spicy}
                      required
                      onChange={(event) => {
                        setError("");
                        setSpicy(event.target.value);
                      }}
                    />
                  </div>
                  {error && <div className="error">{error}</div>}
                </div>
                {/* submit  */}
                <div className="">
                  <button
                    className="flex items-center  space-x-2 bg-indigo-500 text-white px-5 py-2 rounded-md shadow-md transition-all duration-300 ease-in-out hover:bg-indigo-600"
                    type="submit"
                    onClick={submitForm}
                  >
                    <div>{saveLoader ? "Saving" : "Save"}</div>
                    {saveLoader && <Processing />}
                  </button>
                </div>
              </form>
            </div>
          </div>
          {/* left side  */}
          <div className="flex-1">
            <div className="flex flex-col">
              <div className="bg-white rounded-md shadow-md flex flex-col py-5 h-auto w-full">
                {/* header  */}
                <div className="px-10 border-b border-gray-300 pb-4 text-lg font-semibold uppercase  tracking-wide">
                  All SPICY LEVELS
                </div>
                {loading && (
                  <div className="w-full flex justify-center">
                    <div className="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                )}
                {spicyLevels.length === 0 && !loading && (
                  <div className="w-full flex justify-center text-primary text-xl">
                    No Data
                  </div>
                )}
                {spicyLevels.length > 0 && (
                  <div className="grid grid-cols-1 sm:grid-cols-2  lg:grid-cols-2 xl:grid-cols-3 gap-x-2 gap-y-4 py-10">
                    {spicyLevels.map((product, index) => {
                      const { name, slug } = product;
                      return (
                        <div className="col-span-1 px-3" key={index}>
                          <div className="bg-gray-200 p-2 flex justify-between text-sm items-center">
                            <span>{name}</span>
                            <div className="flex items-center space-x-1">
                              <button onClick={() => handleEdit(slug, name)}>
                                <svg
                                  className="w-5 h-5 text-green-600"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                  ></path>
                                </svg>
                              </button>
                              <button
                                onClick={() => {
                                  setSlug(slug);
                                  setDeletePopup(true);
                                }}
                              >
                                <svg
                                  className="w-5 h-5 text-red-600"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Spicy;
