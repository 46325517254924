import React, { useState, useEffect } from "react";
import { baseUrl } from "../authentication/authorization";
import leaf from "../../assets/images/homepage/leaf.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "../../assets/css/our-speciality-swiper.css";

import FoodMenuItem from "../common/food-menu-item";

// import Swiper core and required modules
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/core";
// install Swiper modules

function OurMomo() {
  const [specialMomos, setSpecialMomos] = useState([]);

  SwiperCore.use([Autoplay, Pagination, Navigation]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const getSpecialMomos = async () => {
      await fetch(`${baseUrl}/menu-list?search=true`, {
        method: "GET",
        signal,
      })
        .then((response) => response.json())
        .then((dta) => {
          if (Array.isArray(dta)) {
            setSpecialMomos(dta);
          }
        })
        .catch(() => null);
    };

    getSpecialMomos();

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <div className="flex flex-col max-w-7xl mx-auto py-10 md:py-16 lg:py-20 px-4 md:px-10 lg:px-16">
      {/* header  */}
      <div className="flex-1 md:flex flex-col justify-center items-center px-5">
        <div className=" text-3xl sm:text-4xl lg:text-6xl text-center font-header">
          Our Specialities
        </div>
        <div className="mt-4">
          <img src={leaf} alt="" className="mx-auto" />
        </div>
      </div>
      <div
        className="our-specialities flex justify-center h-64 overflow-hidden relative mt-5 md:mt-10 lg:mt-16"
        style={{ height: "240px" }}
      >
        <Swiper
          autoplay={{ delay: 5000 }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          spaceBetween={10}
          className="mySwiper"
          breakpoints={{
            360: {
              width: 200,
              slidesPerView: 1,
            },
            // when window width is >= 640px
            640: {
              width: 400,
              slidesPerView: 2,
            },
            // when window width is >= 768px
            768: {
              width: 600,
              slidesPerView: 3,
            },
            1024: {
              width: 800,
              slidesPerView: 4,
            },
            1280: {
              width: 1000,
              slidesPerView: 5,
            },
          }}
          // slidesPerView={4}
        >
          {specialMomos.map((specialMomo, index) => (
            <SwiperSlide key={index}>
              <FoodMenuItem
                key={index}
                name={specialMomo.item}
                image={specialMomo.img}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default OurMomo;
