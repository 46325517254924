import { useState, useEffect } from "react";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { baseUrl } from "../authentication/authorization";

export default function Slider() {
  const [active, setActive] = useState(0);
  const [images, setImages] = useState([]);
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    fetch(`${baseUrl}/site/front-slider-list`, {
      method: "GET",
      signal,
    })
      .then((response) => response.json())
      .then((dta) => {
        setImages(dta);
      })
      .catch(() => null);

    return () => controller.abort();
  }, []);
  return (
    <div className="bg-fixed bg-no-repeat bg-center lg:min-height bg-cover font-text relative">
      <div className="w-full lg:h-full overflow-hidden">
        <div
          className="flex h-full relative transition-all duration-700 mt-28 lg:mt-0"
          style={{ left: `-${active * 100}%` }}
        >
          {images.map((image, index) => (
            <img
              src={image.img}
              key={index}
              alt=""
              className="w-full h-full flex-shrink-0"
            />
          ))}
        </div>

        {images.length > 1 && (
          <div className="flex justify-center pt-10 absolute bottom-5 w-full z-20">
            {images.map((r, index) => {
              if (active === index) {
                return (
                  <FiberManualRecordIcon key={index} className="text-primary" />
                );
              } else {
                return (
                  <FiberManualRecordIcon
                    key={index}
                    className="cursor-pointer text-white"
                    onClick={() => setActive(index)}
                  />
                );
              }
            })}
          </div>
        )}
      </div>
    </div>
  );
}
