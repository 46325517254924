import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl, headers } from "../../authentication/authorization";
import FoodItemDetails from "../../common/FoodItemDetails";
import DeleteConfirmationPopup from "../../common/delete-confirmation";

export default function AllProducts() {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [productType, setProductType] = useState([]);
  const [slug, setSlug] = useState("");
  const [deletePopup, setDeletePopup] = useState(false);
  // get all product
  async function getProducts() {
    await axios
      .get(`${baseUrl}/menu-list`)
      .then((res) => {
        if (Array.isArray(res.data)) {
          setProducts(res.data);
        }
      })
      .catch(() => null);
  }

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    // get all product
    async function getProducts() {
      await fetch(`${baseUrl}/menu-list`, {
        method: "GET",
        signal,
      })
        .then((response) => response.json())
        .then((data) => {
          if (Array.isArray(data)) {
            setProducts(data);
          }
          setLoading(true);
        })
        .catch(() => setLoading(true));
    }
    async function getProductType() {
      await fetch(`${baseUrl}/category`, {
        method: "GET",
        signal,
      })
        .then((response) => response.json())
        .then((data) => {
          if (Array.isArray(data)) {
            setProductType(data);
          }
        })
        .catch(() => null);
    }

    getProductType();
    getProducts();

    return () => controller.abort();
  }, []);

  const handleProductTypeChange = async ({ target: { value } }) => {
    setLoading(false);
    const url =
      value === "all"
        ? `${baseUrl}/menu-list`
        : `${baseUrl}/menu-list/?search=${value}`;
    await axios
      .get(url)
      .then((res) => {
        if (Array.isArray(res.data)) {
          setProducts(res.data);

          setLoading(true);
        }
      })
      .catch(() => setLoading(true));
  };

  const handleDelete = (action) => {
    if (action === "delete") {
      axios
        .delete(`${baseUrl}/menu-delete/${slug}`, { headers: headers() })
        .then((res) => {
          getProducts();
        })
        .catch(() => null);
    }
    setSlug("");
    setDeletePopup(false);
  };

  return (
    <>
      {deletePopup !== false && (
        <DeleteConfirmationPopup
          handleDelete={handleDelete}
          message="Are you sure that you want to delete this product ?"
        />
      )}
      <div className="flex  p-5 md:p-10 relative">
        {/* Roles */}
        <div className="absolute">
          <select
            name="product-types"
            defaultValue="all"
            onChange={handleProductTypeChange}
            className="appearance-none bg-white border text-gray-700 w-full pl-3 pr-6 py-2 border-gray-300 focus:border-indigo-500 focus:outline-none text-sm"
          >
            <option value="all">All</option>
            {productType.map((type, index) => {
              const { name, slug } = type;
              return (
                <option value={slug} key={index}>
                  {name}
                </option>
              );
            })}
          </select>
          <svg
            className="w-4 h-4  top-3 right-2 absolute text-gray-600"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </div>
        <div className="flex flex-col bg-white w-full  rounded-md shadow-md py-5 mt-12">
          <div className="flex-1">
            <div className="flex flex-col">
              <div className="rounded-md flex flex-col py-5 h-auto w-full">
                {/* caretgory items list  */}

                {!loading && (
                  <div className="w-full flex justify-center">
                    <div className="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                )}
                {loading && (
                  <div>
                    {products.length > 0 && (
                      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 gap-6 py-10 px-5">
                        {products.map((product, index) => {
                          return (
                            <div
                              className="col-span-1 bg-gray-100 py-5 cursor-pointer relative group"
                              key={index}
                            >
                              <div className="bg-black opacity-0 group-hover:opacity-30 absolute top-0 right-0 w-full h-full transition-all duration-300 ease-in-out"></div>
                              <div className="absolute top-0 right-0 space-x-4  w-full h-full flex justify-center items-center ">
                                <Link
                                  to={`/profile/addproduct/${product.slug}`}
                                  className="bg-green-500 text-white px-3 py-1 rounded-md shadow-md opacity-0 group-hover:opacity-95 transition-all duration-300 ease-in-out hover:bg-green-600 "
                                >
                                  Edit
                                </Link>
                                <button
                                  className="bg-red-600 text-white px-3 py-1 rounded-md shadow-md opacity-0 group-hover:opacity-95 transition-all duration-300 ease-in-out hover:bg-red-700 "
                                  onClick={() => {
                                    setSlug(product.slug);
                                    setDeletePopup(true);
                                  }}
                                >
                                  Delete
                                </button>
                              </div>
                              <FoodItemDetails
                                image={product.img}
                                item={product.item}
                                price={product.price}
                                description={product.description}
                              />
                            </div>
                          );
                        })}
                      </div>
                    )}

                    {products.length === 0 && (
                      <div className="text-center">There are no products</div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
