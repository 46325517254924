import { useState, useEffect } from "react";
import { baseUrl, headers } from "../authentication/authorization";
import BarChart from "./home-dashboard/BarChart";
import LineChart from "./home-dashboard/LineChart";
import DonutChart from "./home-dashboard/DonutChart";
// import GroupBarChart from "./home-dashboard/GroupBarChart";

export default function MainDashboard() {
  const [linegraph, setLinegraph] = useState({
    data: [],
    categories: [],
  });
  const [barchart, setBarchart] = useState({
    data: [],
    categories: [],
  });

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    function getBarGraphData() {
      fetch(`${baseUrl}/bar-graph/`, {
        method: "GET",
        headers: headers(),
        signal,
      })
        .then((response) => response.json())
        .then((dta) => {
          const { data: success } = dta;
          if (success) {
            let categories = [],
              data = [];
            let arr = Object.entries(success);
            if (arr.length > 8) {
              arr = arr.splice(0, 7);
            }
            for (let i = 0; i < arr.length; i++) {
              categories.push(arr[i][0]);
              data.push(arr[i][1]);
            }

            setBarchart({
              data,
              categories,
            });
          }
        })
        .catch(() => null);
    }

    function getLineGraphData() {
      fetch(`${baseUrl}/line-graph/`, {
        method: "GET",
        headers: headers(),
        signal,
      })
        .then((response) => response.json())
        .then((dta) => {
          const { success } = dta;
          if (success) {
            let categories = [],
              data = [];
            let arr = Object.entries(success);
            if (arr.length > 15) {
              arr = arr.splice(arr.length - 15, 15);
            }
            for (let i = 0; i < arr.length; i++) {
              categories.push(arr[i][0]);
              data.push(arr[i][1]);
            }

            setLinegraph({
              data,
              categories,
            });
          }
        })
        .catch(() => null);
    }

    getLineGraphData();
    getBarGraphData();

    return () => controller.abort();
  }, []);

  return (
    <div className="p-5 overflow-auto space-y-5">
      <div className="flex w-full ">
        <div className="bg-white p-3 w-1/2 shadow-md ">
          <DonutChart />
        </div>
        <div className="bg-white p-3 w-1/2 shadow-md ml-3">
          <LineChart data={linegraph.data} categories={linegraph.categories} />
        </div>
      </div>
      <div className="flex w-full ">
        {/* <div className="bg-white p-3 w-1/2 shadow-md"></div> */}
        <div className="bg-white p-3 flex-1 ml-3 shadow-md">
          <BarChart data={barchart.data} categories={barchart.categories} />
        </div>
      </div>
    </div>
  );
}
