import React from "react";

const AllHolidayHour = ({
  deleteHandler,
  editHandler,
  id,
  start_date,
  end_date,
  remarks,
}) => {
  return (
    <div>
      <div className="md:p-2 md:flex md:flex-row  flex-col justify-between items-center md:space-x-2">
        {/* timestamp */}

        <div className="flex space-x-2 justify-between p-2 md:p-0">
          <div className="text-gray-800 font-bold md:hidden block">
            Timestamp
          </div>
          <div className="flex space-x-1">
            <span>{start_date}</span>
            <span>To</span>
            <span>{end_date}</span>
          </div>
        </div>
        {/* holiday info */}
        <div className="flex space-x-2 justify-between p-2 md:p-0">
          <div className="text-gray-800 font-bold md:hidden block">
            Holiday Info
          </div>
          <div>{remarks}</div>
        </div>
        {/* Actions */}
        <div className="md:w-32 flex space-x-2 md:justify-center justify-between p-2 md:p-0">
          <div className="text-gray-800 font-bold md:hidden block">Actions</div>
          <div>
            <div className="flex justify-center items-center space-x-1">
              <button onClick={() => editHandler(id)}>
                <svg
                  className="w-5 h-5 text-green-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                  ></path>
                </svg>
              </button>
              <button onClick={() => deleteHandler(id)}>
                <svg
                  className="w-5 h-5 text-red-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllHolidayHour;
