export default function FoodMenuItem({ name, image }) {
  return (
    <div className="flex flex-col text-center absolute left-0 food-menu-item right-0">
      <div className="flex justify-center pb-2">
        <img
          src={image}
          alt=""
          className="h-40 w-40 object-center object-cover rounded-full"
        />
      </div>
      <div className="font-header font-bold py-1 text-xl text-black relative z-50">
        {name}
      </div>
    </div>
  );
}
