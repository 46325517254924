export default function OrderDetailPopup({ data, handleClose }) {
  const {
    order_items,
    additional_note,
    customer: { f_name, l_name, username, contact },
    address,
    total_amount,
    delivery_range,
    pick_up,
    spicy,
    sauce,
  } = data;

  let user;

  if (f_name) {
    user =
      f_name[0].toUpperCase() +
      f_name.slice(1, f_name.length) +
      " " +
      l_name[0].toUpperCase() +
      l_name.slice(1, l_name.length);
  } else {
    user = username[0].toUpperCase() + username.slice(1, username.length);
  }

  return (
    <div className="fixed left-0 top-0 min-h-screen w-screen z-50 grid place-items-center">
      <div
        className="z-50 fixed bg-black bg-opacity-50 left-0 top-0 min-h-screen w-screen"
        onClick={handleClose}
      ></div>
      <div className="bg-white p-4 rounded text-sm my-10 overflow-y-auto space-y-2 order-detail-popup">
        <div className="grid md:grid-cols-2 md:gap-x-8">
          <div className="flex space-x-6 justify-between">
            <div>Name:</div>
            <div className="font-semibold">{user}</div>
          </div>
          <div className="flex space-x-6 justify-between">
            <div>Contact:</div>
            <div className="font-semibold">{contact}</div>
          </div>

          <div className="flex space-x-2 justify-between">
            <div>Billing Address:</div>
            <div className="font-semibold">{address.billingAddress}</div>
          </div>

          <div className="flex space-x-2 justify-between">
            <div>City:</div>
            <div className="font-semibold">{address.city}</div>
          </div>

          <div className="flex space-x-2 justify-between">
            <div>State:</div>
            <div className="font-semibold">{address.state}</div>
          </div>

          <div className="flex space-x-2 justify-between">
            <div>Zip Code:</div>
            <div className="font-semibold">{address.zip}</div>
          </div>

          {!pick_up && (
            <div className="flex space-x-2 justify-between">
              <div>Delivery Range:</div>
              <div className="font-semibold">{delivery_range.ranges} mile</div>
            </div>
          )}

          {pick_up && (
            <div className="flex space-x-2 justify-between">
              <div>Pick up:</div>
              <div className="font-semibold">True</div>
            </div>
          )}

          <div className="flex space-x-2 justify-between">
            <div>Spicy:</div>
            <div className="font-semibold">
              {spicy[0].toUpperCase() + spicy.slice(1, spicy.length)}
            </div>
          </div>
        </div>

        <div className="flex space-x-2 justify-between">
          <div>Sauce:</div>
          <div className="font-semibold">
            {Array.isArray(sauce) && sauce.join(", ")}
          </div>
        </div>

        <div>
          <div className="flex space-x-4 border-b pb-2 pr-4">
            <div className="w-6 flex-shrink-0">S.N.</div>
            <div className="flex-1">Order Item</div>
            <div className="w-10 flex-shrink-0">Price</div>
            <div className="w-10 flex-shrink-0">Quantity</div>
          </div>
          {order_items.map((d, index) => {
            return (
              <div key={index} className="flex justify-between space-x-4 pt-2">
                <div className="w-6 flex-shrink-0">{index + 1}</div>
                <div className="flex-1">{d.item}</div>
                <div className="w-10 flex-shrink-0">$ {d.price}</div>
                <div className="w-10 flex-shrink-0">{d.quantity}</div>
              </div>
            );
          })}

          <div className="flex space-x-2 justify-between py-4 md:w-2/3 ml-auto">
            <div>
              Total Amount{" "}
              <span className="text-xs">
                (including tax{!pick_up && " and delivery charge"}
              </span>
              ):
            </div>
            <div className="font-semibold">$ {total_amount / 100}</div>
          </div>
        </div>
        <div className="flex space-x-5">
          <div className="font-semibold">Note:</div>
          <div className="text-gray-600">{additional_note}</div>
        </div>
        <div className="mt-2 text-right mb-4">
          <div
            className="inline-block bg-blue-700 h-10 leading-10 hover:bg-blue-800 cursor-pointer text-white px-3 rounded"
            onClick={handleClose}
          >
            OK
          </div>
        </div>
      </div>
    </div>
  );
}
