import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "../../assets/images/dashboard/logo.jpeg";

const navbarItems = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "About Us",
    url: "/about-us",
  },
  {
    title: "Menu List",
    url: "/menu-list",
  },
  {
    title: "Services",
    url: "/services",
  },
  {
    title: "Gallery",
    url: "/gallery",
  },
  {
    title: "Contact Us",
    url: "/contact-us",
  },
];

const NavAuthenticationButton = ({
  loggedIn,
  role,
  small,
  toggleMobileSidebar,
}) => {
  let link;
  if (loggedIn && role === "admin") {
    link = "Dashboard";
  } else if (loggedIn) {
    link = "Profile";
  } else {
    link = "Login/Register";
  }
  return (
    <div className="button-animation">
      <Link
        to={loggedIn ? "/profile" : "/login"}
        onClick={() => {
          if (toggleMobileSidebar) {
            toggleMobileSidebar();
          }
          window.scrollTo(0, 0);
        }}
        className="animation-text px-4 py-2"
      >
        {link}
      </Link>
      <div className="animation-bg"></div>
    </div>
  );
};

export default function Navbar({ loggedIn = false, role, total = 0 }) {
  const [width, setWidth] = useState(window.innerWidth);
  const [position, setPosition] = useState(0);
  const mobileSidebar = useRef();
  const coverAll = useRef();

  // toggle in mobile
  const toggleMobileSidebar = () => {
    const sidebar = mobileSidebar.current;
    const coverall = coverAll.current;
    if (sidebar.clientWidth > 0) {
      sidebar.style.width = "0";
      coverall.style.display = "none";
    } else {
      sidebar.style.width = "288px";
      coverall.style.display = "block";
    }
  };

  // if user is using screen width of laptop and changes to lower than 1023px width, call the function
  // if user is using screen width of ipad or mobile and changes to higher than 1024px width, call the function
  const handleWidth = () => {
    const innerWidth = window.innerWidth;
    if (innerWidth > 1023 && width < 1024) {
      setWidth(innerWidth);
    } else if (innerWidth < 1024 && width > 1023) {
      setWidth(innerWidth);
    }
  };
  const scrollToTop = () => window.scrollTo(0, 0);
  const handleScroll = () => {
    if (window.pageYOffset > 0 && position === 0) {
      setPosition(1);
    } else if (window.pageYOffset === 0 && position > 0) {
      setPosition(0);
    }
  };
  useEffect(() => {
    handleScroll();
    window.addEventListener("resize", handleWidth);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("resize", handleWidth);
      window.removeEventListener("scroll", handleScroll);
    };
  });
  return (
    <div className="fixed z-30 w-full font-header">
      <div
        className={`flex justify-between bg-black items-center px-10 lg:px-16 xl:px-24 text-white bg-opacity-40 transition-all duration-300 font-bold relative ${
          position ? "h-20" : "h-28"
        }`}
      >
        <Link
          to="/"
          className="text-2xl md:text-3xl font-bold text-primary flex space-x-2 items-center"
        >
          <div className="">
            <img
              src={logo}
              alt="Momo World"
              className={`${
                position ? "w-12 h-12" : "w-20 h-20"
              } rounded-full transition-all duration-300`}
            />
          </div>
          {/* <div>THE MOMO WORLD</div> */}
        </Link>

        {/* for laptops */}
        {width > 1023 && (
          <div className="space-x-4 flex items-center font-text">
            {navbarItems.map((item, index) => {
              const { url, title } = item;
              return (
                <Link
                  to={url}
                  key={index}
                  onClick={scrollToTop}
                  className="hover:text-secondary"
                >
                  {title}
                </Link>
              );
            })}
            {loggedIn && (
              <Link
                to="/cart"
                onClick={scrollToTop}
                className="hover:text-primary relative"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
                <div className="absolute -top-4 -right-2">
                  <span className="bg-primary rounded-full w-6 h-6 flex justify-center items-center text-sm text-white">
                    {total}
                  </span>
                </div>
              </Link>
            )}
            <NavAuthenticationButton loggedIn={loggedIn} role={role} />
          </div>
        )}
        {width < 1024 && (
          <MenuIcon className="cursor-pointer" onClick={toggleMobileSidebar} />
        )}
      </div>
      {/* for ipads and mobiles */}
      {width < 1024 && (
        <>
          <div
            className="h-screen w-screen fixed left-0 top-0 bg-black bg-opacity-20 hidden"
            ref={coverAll}
            onClick={toggleMobileSidebar}
          ></div>
          <div
            className="fixed top-0 left-0 h-screen w-0 z-20 bg-white text-black transition-all duration-300 overflow-hidden"
            ref={mobileSidebar}
          >
            <div className="w-72 flex flex-col px-8">
              <div className="text-2xl md:text-3xl font-header py-5 text-center">
                THE MOMO WORLD
              </div>
              {navbarItems.map((item, index) => {
                const { url, title } = item;
                return (
                  <Link
                    to={url}
                    key={index}
                    onClick={() => {
                      toggleMobileSidebar();
                      scrollToTop();
                    }}
                    className="py-2"
                  >
                    {title}
                  </Link>
                );
              })}
              {loggedIn && (
                <Link
                  to="/cart"
                  onClick={() => {
                    toggleMobileSidebar();
                    scrollToTop();
                  }}
                  className="hover:text-primary relative pb-2"
                >
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  </svg>
                  <div className="absolute -top-2 left-5">
                    <span className="bg-primary rounded-full w-6 h-6 flex justify-center items-center text-sm text-white">
                      {total}
                    </span>
                  </div>
                </Link>
              )}
              <NavAuthenticationButton
                loggedIn={loggedIn}
                toggleMobileSidebar={toggleMobileSidebar}
                role={role}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
