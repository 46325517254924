import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router";
// import ChangePassword from "../authentication/change-password";
import Order from "../order/order";
import Aside from "./static/aside";
import NavBar from "./static/navbar";

export default function NormalUser() {
  const [width, setWidth] = useState(window.innerWidth);

  const handleResize = () => {
    const innerWidth = window.innerWidth;
    if (innerWidth > 767 && width < 768) {
      setWidth(innerWidth);
    } else if (innerWidth < 768 && width > 767) {
      setWidth(innerWidth);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <div className="bg-gray-100 h-screen flex font-rubik">
      <Aside />
      <div className="flex-1 flex flex-col overflow-x-hidden">
        <NavBar />
        <Switch>
          <Route
            exact
            path="/profile"
            render={() => <Order normal={true} width={width} />}
          />
          {/* <Route path="/profile/change-password" component={ChangePassword} /> */}
        </Switch>
      </div>
    </div>
  );
}
