import { useState, useEffect } from "react";
import FrontPage from "../common/FrontPage";
// import OurMission from "./our-mission";
// import ServiceProcess from "./service-process";
// import HomeDelivery from "./home-delivery";
import { baseUrl } from "../authentication/authorization";
import ServiceComponent from "./service-component";

export default function Services() {
  const [services, setServices] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    fetch(`${baseUrl}/site/services-list/`, {
      method: "GET",
      signal,
    })
      .then((response) => response.json())
      .then((dta) => {
        if (Array.isArray(dta)) {
          setServices(dta);
        }
      })
      .catch(() => null);

    return () => controller.abort();
  }, []);
  return (
    <div>
      <FrontPage
        id="service"
        title="We provide you"
        header="Best Customer Service"
        description="A lot of Restaurants serve good food, but they do not have very good service"
      />
      {services.map((service, index) => (
        <ServiceComponent key={index} index={index} {...service} />
      ))}
      {services.length === 0 && <div>No services available</div>}
      {/* <OurMission /> */}
      {/* <ServiceProcess />
      <HomeDelivery /> */}
    </div>
  );
}
