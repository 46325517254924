import leaf from "../../assets/images/homepage/leaf.png";

export default function ServiceComponent({ index, ...service }) {
  const { title, description, img } = service;
  return (
    <div
      className={`flex flex-col w-full space-y-4 md:space-y-0 md:space-x-4 max-w-7xl mx-auto py-10 md:py-16 lg:py-20 px-4 md:px-10 lg:px-16 ${
        index % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
      }`}
    >
      {/* about  */}
      <div className="flex-1 md:flex flex-col justify-center items-center px-5">
        <div className=" text-2xl md:text-3xl lg:text-4xl text-center font-header text-gray-800 uppercase">
          {title}
        </div>
        <div className="mt-4">
          <img src={leaf} alt="" className="mx-auto" />
        </div>
        <div className="text-center text-sm text-gray-500 md:text-lg mt-5">
          {description}
        </div>
      </div>
      {/* image */}
      <div className="flex-1 relative">
        <img src={img} className="w-full h-full object-cover" alt="" />
      </div>
    </div>
  );
}
