import { useState, useRef } from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CloseIcon from "@material-ui/icons/Close";

export default function Slider({ images, setIsclicked }) {
  const [active, setActive] = useState(1);
  const [animation, setAnimation] = useState(false);
  const sliderContainer = useRef();

  const timeoutHandler = (value) => {
    setActive(value);
    setAnimation(true);
    setTimeout(() => setAnimation(false), 700);
  };

  const sliderHandler = (action) => {
    if (!animation) {
      if (action === "decrease") {
        if (active === 1) {
          setActive(0);
          setAnimation(true);
          setTimeout(() => {
            sliderContainer.current.style.transition = "none";
            setActive(images.length);
            setTimeout(
              () => (sliderContainer.current.style.transition = "all 0.7s"),
              40
            );
            setAnimation(false);
          }, 700);
        } else if (active > 0) {
          timeoutHandler(active - 1);
        } else {
          timeoutHandler(images.length - 1);
        }
      } else {
        if (active === images.length) {
          setActive(images.length + 1);
          setAnimation(true);
          setTimeout(() => {
            sliderContainer.current.style.transition = "none";
            setActive(1);
            setTimeout(
              () =>
                (sliderContainer.current.style.transition = "all 0.7s ease"),
              40
            );
            setAnimation(false);
          }, 700);
        } else if (active < images.length + 1) {
          timeoutHandler(active + 1);
        } else {
          timeoutHandler(0);
        }
      }
    }
  };

  return (
    <div className="relative">
      <div className="w-4/5 md:w-3/5 max-w-6xl mx-auto  overflow-hidden relative">
        <div
          className="absolute right-0 top-0 p-2 bg-red-500 hover:bg-red-600 cursor-pointer"
          style={{ zIndex: 51 }}
          onClick={() => setIsclicked(false)}
        >
          <CloseIcon />
        </div>
        {images.length > 1 && (
          <div className="absolute z-50 h-full w-full flex justify-between items-center text-white">
            <div
              className="bg-primary bg-opacity-60 px-4 py-6 cursor-pointer hover:bg-opacity-90 relative z-10"
              onClick={() => sliderHandler("decrease")}
            >
              <ArrowBackIosIcon />
            </div>
            <div
              className="bg-primary bg-opacity-60 px-4 py-6 cursor-pointer hover:bg-opacity-90 relative z-10"
              onClick={() => sliderHandler("increase")}
            >
              <ArrowForwardIosIcon />
            </div>
          </div>
        )}

        <div
          className="flex relative"
          ref={sliderContainer}
          style={{
            transition: "all 0.7s",
            left: `-${active * 100}%`,
            height: "500px",
          }}
        >
          <img
            src={images[images.length - 1].img}
            alt=""
            className="w-full h-full flex-shrink-0 object-cover"
          />
          {images.map((image, index) => (
            <img
              src={image.img}
              key={index}
              alt=""
              className="w-full h-full flex-shrink-0 object-cover"
            />
          ))}
          <img
            src={images[0].img}
            alt=""
            className="w-full h-full flex-shrink-0 object-cover"
          />
        </div>
      </div>
    </div>
  );
}
