import { useEffect, useState } from "react";
import { baseUrl } from "../authentication/authorization";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

export default function Faq() {
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    fetch(`${baseUrl}/site/faq-list`, {
      method: "GET",
      signal,
    })
      .then((response) => response.json())
      .then((dta) => {
        if (Array.isArray(dta)) {
          let faqs = [];
          for (let i = 0; i < dta.length; i++) {
            faqs[i] = {
              ...dta[i],
              show: false,
            };
          }
          setFaqs(faqs);
        }
      })
      .catch(() => null);

    return () => controller.abort();
  }, []);

  const handleFaqExpand = (index) => {
    const faqs1 = [...faqs];
    faqs1[index].show = !faqs1[index].show;
    setFaqs(faqs1);
  };

  return (
    <div className="py-10 md:py-16 lg:py-20 px-4 md:px-10 lg:px-16 max-w-7xl mx-auto">
      <div className="text-xl md:text-2xl lg:text-3xl font-semibold text-center">
        FREQUENTLY ASKED QUESTIONS
      </div>
      {faqs.length > 0 && (
        <div className="text-gray-700 border-r border-l border-b border-gray-400 mt-4 md:mt-10 lg:mt-16">
          {faqs.map((faq, index) => {
            const { answer, question, show } = faq;
            return (
              <div
                key={index}
                className="duration-500 text-sm hover:bg-gray-50 cursor-pointer p-2 border-t border-gray-400 flex justify-between space-x-4 lg:space-x-10"
                onClick={() => handleFaqExpand(index)}
              >
                <div>
                  <div className="font-semibold p-2">{question}</div>
                  <div
                    className={`text-justify overflow-hidden ${
                      !show && "h-0"
                    } ${show && "h-full"}`}
                  >
                    <div className="p-2">{answer}</div>
                  </div>
                </div>
                <div className="p-2">
                  {show && <RemoveIcon />}
                  {!show && <AddIcon />}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
