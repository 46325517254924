import React from "react";
import about from "../../assets/images/homepage/about.jpg";
import leaf from "../../assets/images/homepage/leaf.png";

function HelloNamastey() {
  return (
    <div className="flex flex-col md:flex-row w-full space-y-4 md:space-y-0 md:space-x-4 max-w-7xl mx-auto py-10 md:py-16 lg:py-20 px-4 md:px-10 lg:px-16">
      {/* about  */}
      <div className="flex-1 md:flex flex-col justify-center items-center px-5">
        <div className="text-xl md:text-2xl font-subHeader text-primary text-center">
          Hello
        </div>
        <div className=" text-3xl md:text-4xl lg:text-6xl text-center font-header text-gray-700">
          Welcome and Namaste
        </div>
        <div className="mt-4">
          <img src={leaf} alt="" className="mx-auto" />
        </div>
        <div className="text-center text-sm text-gray-500 md:text-lg mt-5">
          <strong className="text-primary">The Momo World</strong> is pleased to
          introduce you to perfect and{" "}
          <strong className="text-primary">
            delicious Himalayan street food
          </strong>
          . With influence from the Nepali culture and taste from the Himalayas.
          You will find a variety of food from Nepal, with the main dish being{" "}
          <strong className="text-primary">Momo (Dumplings)</strong>. Our menu
          is prepared with an array of vegetables, meats, seafoods, and flavored
          with seasoning born in the mountains of the Himalayas. We welcome you
          in to our restaurant,{" "}
          <strong className="text-primary">The Momo World</strong>, and we trust
          you will enjoy the authentic taste of our beautiful country!
        </div>
      </div>
      {/* image */}
      <div className="flex-1 relative">
        <img src={about} className="w-full h-full object-cover" alt="" />
        <div className="absolute w-full h-full top-0"></div>
      </div>
    </div>
  );
}

export default HelloNamastey;
