import React, { useEffect, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "../../assets/css/testimonials-swiper.css";

import { baseUrl } from "../authentication/authorization";
import leaf from "../../assets/images/homepage/leaf.png";

// import Swiper core and required modules
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/core";
// install Swiper modules

function Testimonials() {
  SwiperCore.use([Autoplay, Pagination, Navigation]);

  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const getTestimonials = async () => {
      await fetch(`${baseUrl}/site/testimonial-list/`, {
        method: "GET",
        signal,
      })
        .then((response) => response.json())
        .then((dta) => {
          if (Array.isArray(dta)) {
            setTestimonials(dta);
          }
        })
        .catch(() => null);
    };

    getTestimonials();

    return () => controller.abort();
  }, []);

  return (
    <div className="flex flex-col py-10 md:py-16 lg:py-20 px-4 md:px-10 lg:px-16 max-w-7xl mx-auto">
      {/* header  */}
      <div className="flex-1 md:flex flex-col justify-center items-center px-5">
        <div className=" text-3xl sm:text-4xl lg:text-6xl text-center font-header">
          User Reviews
        </div>
        <div className="mt-4">
          <img src={leaf} alt="" className="mx-auto" />
        </div>
      </div>
      <div
        className="w-full flex overflow-hidden relative pt-10"
        id="testimonial-slider"
      >
        <Swiper
          autoplay={{ delay: 5000 }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          className="this-is-dynamic-class"
          spaceBetween={20}
          slidesPerView={1}
          breakpoints={{
            360: {
              width: 340,
            },
            768: {
              width: 700,
            },
            1024: {
              width: 950,
            },
          }}
        >
          {testimonials.map((test, index) => {
            const { img, description, name, location = "Chicago" } = test;
            return (
              <SwiperSlide key={index}>
                <div
                  className="individual-testimonial pt-10 rounded-md overflow-hidden"
                  key={index}
                >
                  <div className="bg-gray-100 rounded-mg shadow-md relative w-full flex flex-col">
                    <div className="bg-white absolute -top-10 left-1/2 -ml-10 p-2 rounded-full">
                      <img
                        src={img}
                        className="w-16 h-16 object-cover rounded-full"
                        alt=""
                      />
                    </div>
                    <div className="py-5">
                      <div className="testimonial-description px-5">
                        <div className="mt-8 text-center pb-5 text-gray-700">
                          {description}
                        </div>
                        <div className="flex space-x-2 justify-center items-center">
                          <div className="text-lg font-bold font-header text-primary">
                            {name}
                          </div>
                          {location && location !== "null" && (
                            <div className="text-gray-700 text-sm">
                              {location}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}

export default Testimonials;
