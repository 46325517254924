import FreshFood from "./FreshFood";
import Slider from "./slider";
import HelloNamastey from "./HelloNamastey";
import Testimonials from "./Testimonials";
import OurMomo from "./OurMomo";
import OurProgress from "../common/our-progress";

export default function Home({ ...all }) {
  return (
    <div>
      <Slider />
      <HelloNamastey />
      <FreshFood />
      <OurMomo />
      <OurProgress {...all} />
      <Testimonials />
    </div>
  );
}
