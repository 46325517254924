import { useEffect, useState } from "react";
import { baseUrl, headers } from "../../authentication/authorization";
import PopupMessage from "../../common/popup-message";
import Processing from "../../common/processing";

// delevery_range  = {"range":"2-5","price":2}

export default function Settings() {
  const [editId, setEditId] = useState("");
  const [alert, setAlert] = useState("");
  const [saveLoader, setSaveLoader] = useState(false);
  const [data, setData] = useState({
    tax: "",
    address: "",
    phoneNumber1: "",
    phoneNumber2: "",
    email: "",
    numberOfChefs: "",
    happyClients: "",
    totalClients: "",
    errors: {
      tax: "",
      address: "",
      phoneNumber: "",
      email: "",
    },
  });

  const handleErrors = (property, value) => {
    const { errors } = data;
    let result;
    value = value === undefined ? data[property] : value;

    if (value.trim().length > 0) {
      if (property === "tax") {
        value = +value;
        // eslint-disable-next-line
        if (value !== value) {
          errors.tax = "Tax must be valid number (do not need to include %)";
          result = false;
        } else if (value > 100 || value < 0) {
          errors.tax = "Tax % be between 0 and 100";
          result = false;
        } else {
          errors.tax = "";
          result = true;
        }
      }
      if (property === "email") {
        if (!value.match(/^\w+@\w+\.\w+(\.\w+)?$/gi)) {
          errors.email = "Invalid email";
          result = false;
        } else {
          errors.email = "";
          result = true;
        }
      } else if (property === "address") {
        errors.address = "";
        result = true;
      }
    } else {
      errors[property] = "";
      result = true;
    }

    setData({
      ...data,
      errors,
    });
    return result;
  };

  const handleChange = ({ target: { value } }, property) => {
    handleErrors(property, value);
    setData({
      ...data,
      [property]: value,
    });
  };

  const handleSettingsChange = (event) => {
    event.preventDefault();
    const { address, phoneNumber1, phoneNumber2, email, errors, tax } = data;
    const {
      address: addressErr,
      email: emailErr,
      phoneNumber: phoneNumberErr,
      tax: taxErr,
    } = errors;
    if (!(addressErr || emailErr || phoneNumberErr || taxErr)) {
      setSaveLoader(true);

      const formData = new FormData();
      formData.append("address", address);
      email && formData.append("email", email);
      formData.append("tax_per", tax);
      formData.append(
        "number_of_chefs",
        numberOfChefs > -1 ? numberOfChefs : 0
      );
      formData.append("happy_clients", happyClients > -1 ? happyClients : 0);
      formData.append("total_clients", totalClients > -1 ? totalClients : 0);
      phoneNumber1 && formData.append("phone1", phoneNumber1);
      phoneNumber2 && formData.append("phone2", phoneNumber2);

      if (editId) {
        fetch(`${baseUrl}/site/settings-update/${editId}`, {
          method: "PATCH",
          body: formData,
          headers: headers(),
        })
          .then((response) => {
            if (response.ok) {
              setAlert("Successfully saved the company data");
            } else {
              setAlert("Something, went wrong. Please try again");
            }
            setSaveLoader(false);
            return response.json();
          })
          .catch(() => {
            setSaveLoader(false);
            setAlert("Something, went wrong. Please try again");
          });
      } else {
        fetch(`${baseUrl}/site/settings-add/`, {
          method: "POST",
          body: formData,
          headers: headers(),
        })
          .then((response) => {
            if (response.ok) {
              setAlert("Successfully saved the company data");
            } else {
              setAlert("Something, went wrong. Please try again");
            }
            setSaveLoader(false);

            return response.json();
          })
          .catch(() => {
            setSaveLoader(false);
            setAlert("Something, went wrong. Please try again");
          });
      }
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    fetch(`${baseUrl}/site/settings/`, {
      method: "GET",
      signal,
    })
      .then((response) => response.json())
      .then((dta) => {
        if (Array.isArray(dta) && dta.length > 0) {
          const {
            id,
            tax_per,
            address,
            email,
            happy_clients,
            total_clients,
            number_of_chefs,
            phone1,
            phone2,
          } = dta[0];
          setData((data) => ({
            ...data,
            tax: tax_per,
            address,
            phoneNumber1: phone1,
            phoneNumber2: phone2,
            happyClients: happy_clients,
            totalClients: total_clients,
            numberOfChefs: number_of_chefs,
            email,
          }));
          setEditId(id);
        }
      })
      .catch(() => null);

    return () => controller.abort();
  }, []);

  const {
    tax,
    address,
    email,
    phoneNumber1,
    phoneNumber2,
    numberOfChefs,
    totalClients,
    happyClients,
    errors,
  } = data;

  const {
    tax: taxErr,
    address: addressErr,
    email: emailErr,
    phoneNumber: phoneNumberErr,
  } = errors;

  return (
    <>
      {alert !== "" && (
        <PopupMessage message={alert} handleClose={() => setAlert("")} />
      )}
      <div className="flex justify-center p-8">
        <div className=" w-full md:w-2/5 ">
          <div className="bg-white rounded-md shadow-md flex flex-col py-5 h-auto w-full">
            <div className="px-10 border-b border-gray-300 pb-4 text-lg font-semibold uppercase  tracking-wide">
              Info
            </div>
            <form
              className="px-10 py-4 space-y-4"
              onSubmit={handleSettingsChange}
            >
              <div className="space-y-1">
                <div className="text-sm font-semibold text-gray-700">Tax</div>
                <input
                  type="taxt"
                  className="border w-full px-5 border-gray-300 focus:border-indigo-500 py-1.5 text-gray-700 text-sm"
                  value={tax}
                  onChange={(event) => handleChange(event, "tax")}
                  required
                />
                {taxErr && <div className="error">{taxErr}</div>}
              </div>
              <div className="space-y-1">
                <div className="text-sm font-semibold text-gray-700">
                  Address
                </div>
                <input
                  type="text"
                  className="border w-full px-5 border-gray-300 focus:border-indigo-500 py-1.5 text-gray-700 text-sm"
                  value={address}
                  onChange={(event) => handleChange(event, "address")}
                />
                {addressErr && <div className="error">{addressErr}</div>}
              </div>
              <div className="space-y-1">
                <div className="text-sm font-semibold text-gray-700">
                  Phone Number 1
                </div>
                <input
                  type="text"
                  className="border w-full px-5 border-gray-300 focus:border-indigo-500 py-1.5 text-gray-700 text-sm"
                  value={phoneNumber1}
                  onChange={(event) => handleChange(event, "phoneNumber1")}
                />
                {phoneNumberErr && (
                  <div className="error">{phoneNumberErr}</div>
                )}
              </div>
              <div className="space-y-1">
                <div className="text-sm font-semibold text-gray-700">
                  Phone Number 2
                </div>
                <input
                  type="text"
                  className="border w-full px-5 border-gray-300 focus:border-indigo-500 py-1.5 text-gray-700 text-sm"
                  value={phoneNumber2}
                  onChange={(event) => handleChange(event, "phoneNumber2")}
                />
                {phoneNumberErr && (
                  <div className="error">{phoneNumberErr}</div>
                )}
              </div>
              <div className="space-y-1">
                <div className="text-sm font-semibold text-gray-700">
                  Email Address
                </div>
                <input
                  type="text"
                  className="border w-full px-5 border-gray-300 focus:border-indigo-500 py-1.5 text-gray-700 text-sm"
                  value={email}
                  onChange={(event) => handleChange(event, "email")}
                />
                {emailErr && <div className="error">{emailErr}</div>}
              </div>
              <div className="space-y-1">
                <div className="text-sm font-semibold text-gray-700">
                  Total Clients
                </div>
                <input
                  type="number"
                  className="border w-full px-5 border-gray-300 focus:border-indigo-500 py-1.5 text-gray-700 text-sm"
                  value={totalClients}
                  onChange={(event) =>
                    setData({
                      ...data,
                      totalClients: event.target.value,
                    })
                  }
                />
              </div>
              <div className="space-y-1">
                <div className="text-sm font-semibold text-gray-700">
                  Happy Clients
                </div>
                <input
                  type="number"
                  className="border w-full px-5 border-gray-300 focus:border-indigo-500 py-1.5 text-gray-700 text-sm"
                  value={happyClients}
                  onChange={(event) =>
                    setData({
                      ...data,
                      happyClients: event.target.value,
                    })
                  }
                />
              </div>
              <div className="space-y-1">
                <div className="text-sm font-semibold text-gray-700">
                  Number of Chefs
                </div>
                <input
                  type="number"
                  value={numberOfChefs}
                  className="border w-full px-5 border-gray-300 focus:border-indigo-500 py-1.5 text-gray-700 text-sm"
                  onChange={(event) =>
                    setData({
                      ...data,
                      numberOfChefs: event.target.value,
                    })
                  }
                />
              </div>

              <div className="pt-5">
                <button className="flex items-center  space-x-2 bg-indigo-500 text-white px-5 py-2 rounded-md shadow-md transition-all duration-300 ease-in-out hover:bg-indigo-600">
                  <div>{saveLoader ? "Saving" : "Save"}</div>
                  {saveLoader && <Processing />}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
