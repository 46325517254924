import { React, useState, useEffect } from "react";
import { baseUrl, headers } from "../../authentication/authorization";
import Processing from "../../common/processing";

export default function Contact({ width }) {
  const [next, setNext] = useState("");
  const [saveLoader, setSaveLoader] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    setLoading(true);
    fetch(`${baseUrl}/site/contactus-list/`, {
      method: "GET",
      headers: headers(),
      signal,
    })
      .then((response) => response.json())
      .then((dta) => {
        const { detail, next, results, code } = dta;
        if (
          code === "token_not_valid" ||
          detail === "Authentication credentials were not provided."
        ) {
          localStorage.clear();
          window.location = "/login";
        } else if (Array.isArray(results)) {
          results.forEach((r) => {
            r.del = false;
          });
          setContactList(results);
          setNext(next);
          setLoading(false);
        }
      })
      .catch(() => null);

    return () => controller.abort();
  }, []);
  const loadMoreContacts = () => {
    if (!saveLoader) {
      setSaveLoader(true);
      fetch(next, {
        method: "GET",
        headers: headers(),
      })
        .then((response) => response.json())
        .then((dta) => {
          const { next, results } = dta;
          if (Array.isArray(results)) {
            setContactList([...contactList, ...results]);
            setNext(next);
          }

          setSaveLoader(false);
        })
        .catch(() => setSaveLoader(false));
    }
  };

  const handleDelete = (id) => {
    const result = contactList.filter((r) => !r.del);
    result.forEach((r) => {
      if (r.id === id) {
        r.del = true;
      }
    });
    setContactList(result);
    fetch(`${baseUrl}/site/contactus-delete/${id}`, {
      method: "DELETE",
      headers: headers(),
    })
      .then((response) => {
        if (response.status === 204) {
          setContactList(result.filter((r) => r.id !== id));
        }
      })
      .catch(() => null);
  };

  return (
    <div className="px-4">
      {/* table  */}
      {/* for labtop  */}
      {width > 767 && (
        <div className="w-full mt-4 bg-white p-5">
          {loading && (
            <div className="w-full flex justify-center">
              <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}

          {contactList.length === 0 && !loading && (
            <div className="w-full flex justify-center text-primary text-xl">
              No data
            </div>
          )}
          {!loading && contactList.length > 0 && (
            <table className="table-auto w-full ">
              <thead className="">
                <tr className=" font-semibold text-gray-700 text-xs uppercase">
                  <th className="text-left border-b py-2">Name</th>
                  <th className="text-left border-b py-2">Email</th>
                  <th className="text-left border-b py-2">Phone</th>
                  <th className="text-left border-b py-2">Subject</th>
                  <th className="text-left border-b py-2">Message</th>
                  <th className="text-left border-b py-2">Action</th>
                </tr>
              </thead>
              <tbody>
                {contactList.map((contact, index) => {
                  const { id, name, email, subject, message, phone, del } =
                    contact;
                  return (
                    <tr
                      key={index}
                      className="cursor-pointer hover:bg-gray-100 text-xs lg:text-sm text-gray-600  border-b transition-all duration-300"
                    >
                      <td className="py-3">{name}</td>
                      <td className="py-3">{email}</td>
                      <td className="py-3">{phone}</td>
                      <td className="py-3">{subject}</td>
                      <td className="py-3">{message}</td>
                      <td>
                        <button
                          className="bg-red-600 text-white px-4 py-1 hover:bg-red-700"
                          onClick={() => handleDelete(id)}
                        >
                          {del ? "Deleting..." : "Delete"}
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              {next && (
                <div className="mt-4">
                  <button
                    className="flex items-center  space-x-2 bg-indigo-500 text-white px-5 py-2 rounded-md shadow-md transition-all duration-300 ease-in-out hover:bg-indigo-600"
                    onClick={loadMoreContacts}
                  >
                    <div>{saveLoader ? "Loading" : "Load More"}</div>
                    {saveLoader && <Processing />}
                  </button>
                </div>
              )}
            </table>
          )}
        </div>
      )}
      {width < 768 && (
        <div className="w-full flex flex-col mt-4">
          {loading && (
            <div className="w-full flex justify-center">
              <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}

          {contactList.length === 0 && !loading && (
            <div className="w-full flex justify-center text-primary text-xl">
              No data
            </div>
          )}
          {!loading && (
            <div className="w-full flex flex-col mt-4">
              {contactList.map((contact, index) => {
                const { id, name, email, subject, message, phone, del } =
                  contact;
                return (
                  <div
                    className="flex flex-col bg-gray-50 shadow mt-2 py-3 rounded px-5 text-sm space-y-1"
                    key={index}
                  >
                    {/* name  */}
                    <div className="flex space-x-4 items-center">
                      <div className="w-28 flex-shrink-0 text-xs font-semibold uppercase">
                        name
                      </div>
                      <div className="text-gray-600">{name}</div>
                    </div>
                    {/* email  */}
                    <div className="flex space-x-4 items-center">
                      <div className="w-28 flex-shrink-0 text-xs font-semibold uppercase">
                        email
                      </div>
                      <div className="text-gray-600">{email}</div>
                    </div>
                    {/* phone  */}
                    <div className="flex space-x-4 items-center">
                      <div className="w-28 flex-shrink-0 text-xs font-semibold uppercase">
                        phone
                      </div>
                      <div className="text-gray-600">{phone}</div>
                    </div>
                    {/* subject  */}
                    <div className="flex space-x-4 items-center">
                      <div className="w-28 flex-shrink-0 text-xs font-semibold uppercase">
                        subject
                      </div>
                      <div className="text-gray-600">{subject}</div>
                    </div>
                    {/* message  */}
                    <div className="flex space-x-4 items-center">
                      <div className="w-28 flex-shrink-0 text-xs font-semibold uppercase">
                        message
                      </div>
                      <div className="text-gray-600">{message}</div>
                    </div>
                    {/* delete  */}
                    <div className="flex space-x-4 items-center">
                      <div className="w-28 flex-shrink-0 text-xs font-semibold uppercase">
                        delete
                      </div>
                      <div className="text-gray-600">
                        <button
                          className="bg-red-600 text-white px-4 py-1 hover:bg-red-700"
                          onClick={() => handleDelete(id)}
                        >
                          {del ? "Deleting..." : "Delete"}
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {next && (
            <div className="mt-4">
              <button
                className="flex items-center  space-x-2 bg-indigo-500 text-white px-5 py-2 rounded-md shadow-md transition-all duration-300 ease-in-out hover:bg-indigo-600"
                onClick={loadMoreContacts}
              >
                <div>{saveLoader ? "Loading" : "Load More"}</div>
                {saveLoader && <Processing />}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
